<div style="margin-top: 32">
  <div class="container" *ngIf="isData">
    <div *ngIf="currentUser && currentUser?.userType === 'Super user'" class="card">
      <div>
        <form action="" [formGroup]="subscriptionForm">
          <div class="align-field">
            <label for="amount">Platform subscription fee<span>*</span></label>
            <input type="number" formControlName="subscriptionFee" placeholder="5000" readonly />
          </div>
          <div class="align-field">
            <label for="amount">Top-up amount (optional)</label>
            <input type="number" placeholder="Enter amount" formControlName="topUpAmount"
              (keyup)="somethingChanged($event)" />
            <div style="color: red; font-size: 13px; margin-top: -17px" *ngIf="error">
              Invalid amount format. Only two decimal points allowed
            </div>
          </div>
          <div class="align-field">
            <label for="currency">Currency</label>
            <input type="text" formControlName="currency" placeholder="USD" readonly />
          </div>
        </form>
        <div class="left-align">
          <div class="row-align">
            <span class="option">Select option : </span>
            <mat-radio-group [(ngModel)]="selectedPaymentOption" (change)="select()">
              <mat-radio-button value="PayPal" style="padding-right: 10px" [disabled]="isInIframe">
                <img width="73px" src="../../../assets/images/paypal-logo.png" alt="" style="cursor: default" />
              </mat-radio-button>
              <mat-radio-button value="Stripe" [disabled]="isInIframe">
                <img width="45px" src="../../../assets/images/stripe-logo.png" alt="" style="cursor: default" />
              </mat-radio-button>
              &nbsp;&nbsp;
              <mat-radio-button value="Bank Transfer" class="text">
                Bank Transfer
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="btns">
        <button class="proceed-button" mat-flat-button (click)="deposit()" [disabled]="methodSelected || error">
          Proceed
        </button>
        <button class="custom-button" mat-flat-button (click)="onNoClick()" type="reset">
          Clear
        </button>
      </div>
    </div>

    <div *ngIf="currentUser && currentUser?.userType != 'Super user'" class="tasks-head show-message"
    style="text-align: center;">
      <h4>Please Note</h4>
      <p style="font-size: 18px;">Your organisation does not subscribe to the platform any longer. If you
        would like to access the platform, please contact <b style="color: #1059a9" class="emails">{{currentUser?.superUserName}}</b> at <b style="color: #1059a9" class="emails">{{currentUser?.superUserEmail}}</b>.
      </p>
    </div>
  </div>
</div>