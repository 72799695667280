import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OPERATORS } from 'src/app/services/constants';

@Component({
  selector: 'app-toi-warning-popup',
  templateUrl: './toi-warning-popup.component.html',
  styleUrls: ['./toi-warning-popup.component.scss']
})
export class ToiWarningPopupComponent implements OnInit {
  checkedCount: any = 0;
  operatorKeys: any;
  uniqueNameAndSensorList: unknown[];
  umbra: any;

  constructor(private dialogRef: MatDialogRef<ToiWarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit(): void {
      this.uniqueNameAndSensorList = [];
      this.umbra = null;
      const checkedSensors = this.data.row.filter(sensor => sensor.checked === true);
      this.checkedCount = checkedSensors.length;
      if (this.data?.type === 'cart') this.checkedCount =this.data.row?.length;
      
      this.operatorKeys = checkedSensors.map(sensor => sensor.operatorKey);
      this.uniqueNameAndSensorList = [
        ...new Set(checkedSensors.map(sensor => `${(this.data?.user?.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor.name} ${sensor.sensor}`))
      ];
      const umbraSensors = this.data.row.filter(sensor => sensor.operatorKey === OPERATORS.UMBRA);
      this.umbra = `${(this.data?.user?.obfuscated && umbraSensors[0].obfuscateName) ? umbraSensors[0].obfuscateName : umbraSensors[0].name} ${umbraSensors[0].sensor}`
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
