<div class="setion">

  <div class="task-head">
    <h4>Success!</h4>
  </div>
  <div id="wrapper">
    <div style="text-align: center;">
      <p>
        Your invoice will be emailed to <b style="color: #1059A9;" class="emails">{{currentUser?.email}}</b> for the order
        amount. <br/> You will receive an email when your order is ready.
      </p>
    </div>

    <div class="btns">
      <button mat-flat-button routerLink="/orders" (click)="onNoClickor()" style="background-color: transparent;
        border: 1px solid #e5e5e5ee;  padding: 11px 30px; margin: 0 5px 0 0; border-radius: 3px;
        font-weight: 500;" class="dark-text">Go to Orders</button>


      <button mat-flat-button routerLink="/cart" (click)="onNoClick()" style=" margin: 0 5px 0 0;
      background-color: #1059a9;
      border-radius: 3px;
      padding: 2px 10px;
      margin-left: 16px;
      height: 44px;
      border: 0;
      color: #fff;
      font-weight: 500;"
    >Pay Another Task</button>
    </div>
  </div>
</div>

<style>
  :focus {
    outline: none;
  }

  .card {
    border: 0;
    text-align: center;
  }

  .task-head {
    padding: 0 0 10px 0;
    border-bottom: 1px solid #eee;
    margin: 0 0 20px 0;
    text-align: center;
  }

  .btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 7px 0;
    padding: 0 20px 0 0;
    font-size: 14px;
  }

  .btns button {
    margin: 0 5px 0 0;
    padding: 5px 30px;
  }

  .btns button.mat-flat-button {
    background-color: #1059A9;
    color: #fff;
    border-radius: 3px;
    font-family: 'Barlow' !important;
    width: 130px;
  }

  .buttons {
    background-color: transparent !important;
    color: black !important;
    border: 1px solid lightgrey;
  }


  button.mat-focus-indicator.mat-stroked-button.mat-button-base {
    background-color: transparent;
  }
</style>