<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

<div class="container">
  <img *ngIf="!isInIframe" src="../../../assets/images/logo.png" alt="logo" style="width: 40%" />
  <h4>Update Password</h4>
  <p>Enter your new password below.</p>

  <!------------------------------ Form-section-Start -------------------------------------------------->

  <form class="form-vertical" [formGroup]="pswrdForm">
    <!-------------------------------- email ---------------------------------------------------->

    <div class="form-group">
      <mat-label>Set New Password<span>*</span></mat-label>
      <mat-form-field appearance="outline">
        <input matInput [type]="showPassword ? 'password' : 'text'" placeholder="Your new password"
          formControlName="password" required />
        <mat-icon matSuffix (click)="togglePasswordVisibility()" style="height: 30px;">
          {{ showPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
      </mat-form-field>
      <div *ngIf="sign['password'].touched && sign['password'].invalid" class="alert alert-danger">
        <div *ngIf="sign['password'].errors?.['required']">
          Password is required.
        </div>

        <div class="passerror" *ngIf="sign['password'].errors?.['minlength'] || sign['password'].errors?.['pattern']">
          Must contain <span *ngIf="sign['password'].errors?.['minlength']"> mimimum of 8 characters </span>

          <span *ngIf="sign['password'].errors?.['pattern']"> with at least one capital letter and a special character
          </span>
        </div>

      </div>

    </div>

    <div class="form-group">
      <mat-label>Confirm New Password<span>*</span></mat-label>
      <mat-form-field appearance="outline">
        <input matInput [type]="confirmshowPassword ? 'password' : 'text'" placeholder="Confirm your new password"
          formControlName="confirmpassword" (keyup)="areEqual($event)" />
        <mat-icon matSuffix (click)="toggleconfirmPasswordVisibility()" style="height: 30px;">
          {{ confirmshowPassword ? "visibility_off" : "visibility" }}
        </mat-icon>
        <mat-hint *ngIf="!pswrdMatched" class="warn">The passwords must be the same.
        </mat-hint>
      </mat-form-field>
    </div>

    <!-------------------------------- button---------------------------------------------------->

    <div>
      <button mat-raised-button color="primary" type="submit" (click)="resetPswrd()" (keydown)="submitForm($event)"
        [disabled]="pswrdForm.invalid || !pswrdMatched || clicked">
        CHANGE PASSWORD
      </button>
    </div>

    <!-------------------------------- button---------------------------------------------------->
  </form>
  <!------------------------------ Form-section-end -------------------------------------------------->
</div>