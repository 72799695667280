import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { OPERATORS } from 'src/app/services/constants';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss']
})
export class OpportunityComponent implements OnInit {

  inputValue: number | string = '';
  @ViewChild('preferenceInput') preferenceInput: any;
  dataSource: any = new MatTableDataSource<any>();
  enteredPreferences: { [key: string]: number } = {};
  displayedColumns: string[] = ['sensorName', 'captureStart', 'captureEnd'];
  sensorDetails: any;
  message: any;
  disable: boolean = true;
  SAR: boolean = true;
  load: boolean = true;
  OPERATORS: any;
  feasibilityId: any;
  cancel: boolean = false;
  enabled: boolean;
  loadMessage: string;
  singleInput: boolean = true;
  metaDataTooltip: any;
  darkTheme: boolean;
  tooltipImagePath: string;
  preferenceEntered: boolean[] = [];
  isData: number = 0;

  constructor(private matdialog: MatDialog, public dialogRef: MatDialogRef<any>,
    private apiService: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localStorageService: DataService) {
      this.OPERATORS = OPERATORS;
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;

        this.tooltipImagePath = this.darkTheme ? '../../../assets/images/union-dark.svg' : '../../../assets/images/union.svg';
      });
  }

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    // Replace non-numeric characters with an empty string
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    // Limit to a maximum of 2 digits
    input.value = numericValue.slice(0, 2);
  }

  ngOnInit(): void {
    this.sensorDetails = this.data.taskList.request.filter(row => (row.checked));
    this.load = true;
    this.dataSource = [];
    for (const [index, data] of this.sensorDetails.entries()) {
      let feasibilityId = data?.feasibilityId;
      this.getFeasibility(feasibilityId,data,index);
      
      if (data?.operatorKey === this.OPERATORS.CAPELLA || data?.operatorKey === this.OPERATORS.SATELLOGIC ||
        data?.operatorKey === this.OPERATORS.UMBRA || data?.operatorKey === OPERATORS.BLACKSKY) {
        data.singleInput = true;
      } else {
        data.singleInput = false;
      }

      if (data?.operatorKey === this.OPERATORS.CAPELLA) {
        data.SAR = true;
        this.displayedColumns = ['sensorName', 'captureStart', 'captureEnd', 'preference',];
      } else {
        data.SAR = false;
        this.displayedColumns = ['sensorName', 'captureStart', 'captureEnd', 'preference'];
      }
    }
  }

  getFeasibility(opportunityId: any, sensorDetails : any, index: any) {
    let feasibilityId: any;
    let shouldRetry = true;
    this.loadMessage = 'Identifying feasibilities...';
    this.apiService.getFeasibilities(this.data.user, opportunityId, this.data.taskid, sensorDetails).subscribe(
      (result: any) => {                
        if (result?.msg === 'Success') {
          shouldRetry = false;
          if (result.result?.length > 0) {
            this.load = false;
            for (const [idx, data] of result.result.entries()) {
              if (idx === 0) {
               data.sensorName = ((this.data?.currentUser?.obfuscated && this.sensorDetails.obfuscateName) ? this.sensorDetails.obfuscateName : sensorDetails.name) + ' ' + sensorDetails.sensor;
               data.sensorid = data?.sensorId;
               data.lookAngles = JSON.stringify(data?.lookAngle);
              }
              data.opportunityid = data?.opportunityID;
              data.preference = null;
              data.orbital = data?.orbitalDirection;
              data.operatorKey = sensorDetails.operatorKey;
             }
             this.dataSource[index] = new MatTableDataSource<any>(result.result);
             this.isData += 1;
          } else {
            this.load = false;
            sensorDetails.loadMessage = 'Sorry, no opportunities are available for the selected time of interest (TOI). Please change the TOI and try again.';
          }              
        } else {
          feasibilityId = result?.result[0]?.feasibilityId;          
          setTimeout(() => {
            if (shouldRetry) {
              this.getFeasibility(feasibilityId,sensorDetails,index);
            }
          }, 60000);
        }
      },
      (error) => {
        // Handle the error or retry if needed
      }
    );
  }

  getTooltipText(element): string {
    let tooltipText = '';    
    if (element?.satelliteName) {
      tooltipText += 'Satellite Name: ' + element.satelliteName + '\n';
    }
    tooltipText += 'Metadata: ' + JSON.stringify(element.metadata);
    return tooltipText;
  }

  onNoClick(): void {
    const allCapella = this.sensorDetails.every(detail => detail.operatorKey === this.OPERATORS.CAPELLA);

    if (allCapella) {
      const opportunities = this.sensorDetails.map((sensorDetail, index) => ({
        sensorDetail: sensorDetail,
        enteredPreferences: this.dataSource[index]?.data
      }));

      this.dialogRef.close(opportunities);
    } else
    this.dialogRef.close('close');
  }

  // Function to handle input click and set the value to '1' while clearing the previous input
  handleInputClick(input: any, element: any, tableIndex: number) {
    if (!this.preferenceInput) {
      this.preferenceInput = {};
    }

    if (!this.preferenceInput[tableIndex]) {
      this.preferenceInput[tableIndex] = null;
    }

    if (this.preferenceInput[tableIndex] !== input) {
      // Clear the previous input for the current table
      if (this.preferenceInput[tableIndex]) {
        this.preferenceInput[tableIndex].value = '';
      }

      // Set the current input as active for the current table
      this.preferenceInput[tableIndex] = input;
    }

    this.dataSource[tableIndex].data.forEach((data: any) => {
      data.preference = '';
    });

    // Set the input value to '1'
    input.value = '1';
    // Update the element's preference value
    element.preference = 1;
    this.preferenceEntered[tableIndex] = true;
    this.updateSaveButtonState();
  }

  // Function to allow only '1' to be entered in the input
  handleKeyDown(event: any) {
    const allowedKeyCodes = ['Numpad1', 'Digit1', 'NumpadArrowUp', 'NumpadArrowDown']; // Add other key codes as needed
    const inputValue = event.target.value;
    if (!allowedKeyCodes.includes(event.code) || inputValue !== '1') {
      event.preventDefault(); // Prevent input of any other key
    }
  }

  assignPreferences(tableIndex: number) {
    this.dataSource[tableIndex].data.sort((a, b) => new Date(a.capture).getTime() - new Date(b.capture).getTime());
    const existingPreferences = new Set();
    let previousPreference = null;
    
    for (const row of this.dataSource[tableIndex].data) {
      let validOrder = false;
      this.message = '';
      if (row.preference !== null && row.preference !== '') {
        // Remove leading zeros using a regular expression
        // row.preference = row.preference.replace(/^0+/, '');
        // Ensure it's a single-digit integer (0-9)
        const parsedValue = parseInt(row.preference, 10);
        if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 9) {
          // Update the preference value with the trimmed, single-digit value
          row.preference = parsedValue.toString();
        }
      }
      if (row.preference !== null && row.preference > 0) {        
        if (existingPreferences.has(row.preference)) {          
          validOrder = true;
          this.message = 'This preference has already been entered.';
        } else if (row.preference > this.dataSource[tableIndex].data.length) {          
          validOrder = true;
          this.message = 'Max ' + this.dataSource[tableIndex].data.length + ' opportunities are available';
        } else if (previousPreference !== null && parseInt(row.preference) <= parseInt(previousPreference)) {
          validOrder = true;
          this.message = 'Enter preferences in ascending order';
        } else {
          validOrder = false;
          this.message = '';
        }
        existingPreferences.add(row.preference);
        previousPreference = row.preference;
      }
      else if (row.preference !== null && row.preference === '0') {
        validOrder = true;
        this.message = ' 0 preference not allowed';
      } else {
        validOrder = false;
        this.message = '';
      }
      row.validPreferenceOrder = validOrder;
      row.error = this.message;
  }

    const hasInvalidPreference = this.dataSource[tableIndex].data.some(row => row.validPreferenceOrder);
    const enteredRows = this.dataSource[tableIndex].data.filter(row => (row.preference > 0));

    if (hasInvalidPreference && enteredRows?.length > 0) {
      this.disable = true;
    } else if (enteredRows?.length === 0) {
      this.disable = true;
    } else this.disable = false;

  }

  save() {
    const enteredRows = this.dataSource.map((tableDataSource, index) => {      
      const enteredPreferences = tableDataSource.data.filter(row => row.preference > 0);
      const selectedPreferences = tableDataSource.data.filter(row => row.operatorKey === OPERATORS.CAPELLA);
      
      return {
        sensorDetail: this.sensorDetails[index],
        enteredPreferences: selectedPreferences?.length > 0 ? selectedPreferences : enteredPreferences
      };
    }).filter(item => item.selectedPreferences > 0 || item.enteredPreferences.length > 0);
    
    this.dialogRef.close(enteredRows);
  }

  onCheck() {
    this.enabled = false;
    const enteredRows = this.dataSource.data.filter(row => (row.cancel));
    if (enteredRows?.length > 0) {
     this.enabled = true;
    } else this.enabled = false;
  }

  cancelOpportunity() { 
    this.enabled = false;   
    const enteredRows = this.dataSource.data.filter(row => (row.cancel));
    this.apiService.cancelFeasibility(enteredRows, this.data.taskBody).subscribe(
      (result: any) => {
        this.dialogRef.close(result);
      });
  }

  getMetadata(metadata: any) {    
    this.metaDataTooltip = metadata;
  }

  updateSaveButtonState() {
    const allPreferencesEntered = this.checkPreferencesEntered();    
    this.disable = !allPreferencesEntered;
  }

  checkPreferencesEntered(): boolean {
    for (const dataSource of this.dataSource) {
      if (!dataSource?.data.some((element: any) => element.preference)
        && dataSource?.data.some((element: any) => element.operatorKey != OPERATORS.CAPELLA)) {
        return false;
      }
    }
    return true;
  }
}

