import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { UserloginComponent } from './components/userlogin/userlogin.component';
import { UserregisterComponent } from './components/userregister/userregister.component';
import { OrdersComponent } from './components/orders/orders.component';
import { ProfileComponent } from './components/profile/profile.component';
import { MyTaskComponent } from './components/my-task/my-task.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { SignupComponent } from './components/signup/signup.component';
import { OtpComponent } from './components/otp/otp.component';
import { NewpassComponent } from './components/newpass/newpass.component';
import { SliderComponent } from './components/slider/slider.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { NewtaskComponent } from './components/newtask/newtask.component';
import { PaymentComponent } from './components/payment/payment.component';
import { CartComponent } from './components/cart/cart.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HowDoesItWorkComponent } from './components/shared/footer/how-does-it-work/how-does-it-work.component';
import { ContactsComponent } from './components/shared/footer/contacts/contacts.component';
import { ResetpasswordComponent } from './components/forgot/resetpassword/resetpassword.component';
import { GlobeComponent } from './globe/globe.component';
import { DisclaimerComponent } from './components/shared/footer/disclaimer/disclaimer.component';
import { PrivacypolicyComponent } from './components/shared/footer/privacypolicy/privacypolicy.component';
import { PaypalsuccessComponent } from './components/paypalsuccess/paypalsuccess.component';
import { ErrorComponent } from './components/error/error.component';
import { RequestDemoComponent } from './components/request-demo/request-demo.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { DepositsComponent } from './components/deposits/deposits.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SubscribeGuard } from './subscribe.guard';
import { FundAllocationComponent } from './components/fund-allocation/fund-allocation.component';
import { PaymentsuccessComponent } from './components/paypalsuccess/paymentsuccess/paymentsuccess.component';
import { BudgetsComponent } from './components/budgets/budgets.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { WhatsNewComponent } from './components/shared/footer/whats-new/whats-new.component';
import { UserManagementComponent } from './components/user-management/user-management.component';


const routes: Routes = [
  // basic routes
  {path: '', redirectTo: 'task',  pathMatch: 'full'},
  {
    path: '',
    component: AppLayoutComponent,
    
    children: [
      { path: 'task', component: MyTaskComponent, canActivate: [SubscribeGuard]},
      { path: 'topbar', component: TopbarComponent },
      { path: 'collections', component: CollectionsComponent, canActivate: [SubscribeGuard] },
      { path: 'cart', component: CartComponent, canActivate: [SubscribeGuard] },
      { path: 'profile', component: ProfileComponent, canActivate: [SubscribeGuard] },
      { path: 'newtask', component: NewtaskComponent, canActivate: [SubscribeGuard] }, 
      { path: 'payment', component: PaymentComponent, canActivate: [SubscribeGuard] },
      { path: 'orders', component: OrdersComponent, canActivate: [SubscribeGuard] },
      { path: 'notification', component: NotificationComponent, canActivate: [SubscribeGuard] },
      { path: 'howitworks', component: HowDoesItWorkComponent},
      { path: 'disclaimer', component: DisclaimerComponent},
      { path: 'privacypolicy', component: PrivacypolicyComponent},
      { path: 'requestdemo', component: RequestDemoComponent},
      { path: 'support', component: ContactsComponent},
      { path: 'payment-success', component: PaypalsuccessComponent},
      { path: 'error', component: ErrorComponent},
      { path: 'deposits', component: DepositsComponent},
      { path: 'retask', component: NewtaskComponent, canActivate: [SubscribeGuard] },
      { path: 'subscription', component: SubscriptionComponent},
      { path: 'allocation', component: FundAllocationComponent},
      { path: 'success', component: PaymentsuccessComponent},
      { path: 'budget', component: BudgetsComponent, canActivate: [SubscribeGuard]},
      { path: 'newbudget', component: NewtaskComponent, canActivate: [SubscribeGuard] },
      { path: 'quotes', component: QuotesComponent, canActivate: [SubscribeGuard]},
      { path: 'whats-new', component: WhatsNewComponent, canActivate: [SubscribeGuard]},
      { path: 'user-management', component: UserManagementComponent, canActivate: [SubscribeGuard]},
    ],
  },
  // login route
  {
    path: '',
    component: AuthLayoutComponent,

    children: [
      { path: 'login', component: UserloginComponent},
      { path: 'register', component: UserregisterComponent},
      { path: 'forgot', component: ForgotComponent},
      { path: 'resetpassword', component: ResetpasswordComponent},
      { path: 'otp', component: OtpComponent},
      { path: 'newpass', component: SignupComponent}
    ],
  },
  {
    path:'not-found',
    component: NotFoundComponent,
  },

  {
    path: 'slider',
    component: SliderComponent,
  
  },
  {
    path: 'globe',
    component: GlobeComponent,
  
  },


  // redirect to home
  { path: '**', redirectTo: '' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
