<div class="popup-container">
    <h4 class="main-head">Rate your tasking experience</h4>
    <form fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup">
        <div class="formDetails" style="margin: 0 0 20px 0; width: 100%;">
            <div fxLayout="column" fxLayoutGap="15px" style="align-items: center;">
                <div class="star-rating-animation" fxLayout="row" fxLayoutAlign="start center">
                    <span *ngFor="let star of stars;index as i" class="star" [ngClass]="{'star-hover' : !this.data.task?.feedback}"
                    [class.full]="star <= rating" (click)="!this.data.task?.feedback ? rate(star) : ''">
                        <mat-icon style="font-size: 33px;"> {{showIcon(i)}}</mat-icon>
                    </span>
                </div>
            </div>
            <div>
                <div fxLayout="column" fxLayoutGap="10px">
                    <label style="color: #6f6d6d;font-weight: 500;">Share your feedback</label>
                    <mat-form-field appearance="outline">
                        <textarea matInput class="area-width" placeholder="Your Feedback" rows="5" cols="20"
                            formControlName="feedback" [readonly]="this.data.task?.feedback"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="11px" class="character">
                    <div>
                        <span class="light-text">Max 500 characters </span>
                    </div>
                    <div>
                        <span class="light-text">{{feedback.value?.length}}/500</span>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="feedback.touched && !this.data.task?.feedback">
                    <mat-error *ngIf="feedback.invalid && feedback.touched">{{
                        getErrorMessage(feedback)
                        }}</mat-error>
                </div>
            </div>

            <div class="btns">
                <button class="custom-button" (click)="onClose()">Close</button>
                <button mat-stroked-button style="margin-left: 16px !important;" class="submit" (click)="onSave()"
                  [disabled]="formGroup.invalid || this.data.task?.feedback">Submit</button>
              </div>
        </div>
    </form>
</div>