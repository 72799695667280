import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe, Location } from '@angular/common';
import { ThemePalette } from '@angular/material/core';
import * as Cesium from 'cesium';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
// import { TasksService } from './tasks.service';
import { BehaviorSubject, Subscription} from 'rxjs';
import { ThemeService } from './theme.service';
// import { toUnicode } from 'punycode';
var parse = require('wellknown');

export interface Sensor {
  name: string;
  type: string;
  selected: boolean;
  color: ThemePalette;
  sensor?: Sensor[];
  available: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  currentUser: any;
  title: any = ''; //"Intelli MultiSatTasking";
  contactMail: any = 'no-reply@eartheye.space';
  server: any = environment.eeTaskServer; //+ '/satellogic';
  mailServer: any = environment.mailServer;
  // eePGUrl = environment.eePGUrl;

  taskitems: any = [];
  public taskparams = new BehaviorSubject<any>(this.taskitems);
  Tasks: any = this.taskparams.asObservable();
  items: any = [];
  private itemSource: any = new BehaviorSubject<any>(this.items);
  selectedSensors: any = this.itemSource.asObservable();

  private cart: any = [];
  cartBS: any = new BehaviorSubject<any>(this.cart);
  castCartData: any = this.cartBS.asObservable();
  config: any;
  private order: any = [];
  orderBS: any = new BehaviorSubject<any>(this.order);
  castOrderData: any = this.orderBS.asObservable();

  readonly usersImgUrl: any = environment.usersServer + '/data/';
  readonly collectionUrl: any = environment.eeTaskServer;
  isInIframe: boolean;
  constructor(
    private http: HttpClient,
    private location: Location,
    private authService: AuthService,
    private userService: UserService,
    public datepipe: DatePipe,
    private themeService: ThemeService
  ) {
    this.authService.user.subscribe(user => this.currentUser = user);
    this.isInIframe = this.themeService.isInIframe();    
  }

  async apiUrls(): Promise<string> {
    let conf = await this.getConfig();
    // console.log(conf);
    return Promise.resolve(conf);
  }

  private async getConfig(): Promise<any> {
    if (!this.config) {
      this.config = await this.http
        .get(this.location.prepareExternalUrl('/assets/config.json'))
        .toPromise();
    }
    return Promise.resolve(this.config);
  }

  dollarUS = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
  euroGerman = Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });

  BASELAYER = new Cesium.TileMapServiceImageryProvider({
    url: Cesium.buildModuleUrl('Assets/Textures/NaturalEarthII'),
  });

  BASELAYERS = [
    {
      name: 'Sentinel-2',
      url: new Cesium.ProviderViewModel({
        name: 'Sentinel-2',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/sentinel-2.png'
        ),
        tooltip: 'Sentinel-2 cloudless',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3954 });
        },
      }),
    },
    {
      name: 'Natural Earth',
      url: new Cesium.ProviderViewModel({
        name: 'Natural Earth',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/naturalEarthII.png'
        ),
        tooltip: 'Blue Marble Next Generation July, 2004 imagery from NASA.',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 3813 });
        },
      }),
    },
    {
      name: 'Bing Maps Road',
      url: new Cesium.ProviderViewModel({
        name: 'Bing Maps Road',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/bingRoads.png'
        ),
        tooltip: 'Bing Maps Road',
        creationFunction: function () {
          return new Cesium.IonImageryProvider({ assetId: 4 });
        },
      }),
    },
    {
      name: 'OSM',
      url: new Cesium.ProviderViewModel({
        name: 'OpenStreetMap',
        iconUrl: Cesium.buildModuleUrl(
          'Widgets/Images/ImageryProviders/openStreetMap.png'
        ),
        tooltip: 'Open Street Map.',
        creationFunction: function () {
          return new Cesium.OpenStreetMapImageryProvider({
            url: 'https://a.tile.openstreetmap.org/',
          });
        },
      }),
    },
  ];
  // BASELAYER.name = 'Natural Earth II';

  // GRAYGLOBE = new Cesium.TileMapServiceImageryProvider({
  //   url: `http://localhost:8080/GlobeGrey`,
  //   maximumLevel: 7,
  //   credit: "<a style='padding-left: 100px' class='credit' target='_blank' href='https://rk.freegyanhub.com'>R K Singh</a>",
  // });
  // grayglobe.name = 'Gray Globe';

  NAVLIST = ([] = [
    { name: 'Home', url: 'home', active: true, focus: true },
    { name: 'Api Sample', url: 'apisample', active: false, focus: false },
    { name: 'My Tasks', url: 'tasks', active: false, focus: false },
    { name: 'My Collections', url: 'collections', active: false, focus: false },
  ]);
  NAVLISTRT = ([] = [
    // {name: 'Create new task', url:'tasks/newtask', active:false, focus: false},
    { name: 'Tasks', url: 'tasks/mytasks', active: true, focus: true },
    {
      name: 'Collections',
      url: 'tasks/collections',
      active: false,
      focus: false,
    },
    { name: 'Orders', url: 'tasks/orders', active: false, focus: false },
    // {name: 'About', url:'aboutus', active:false},
    // {name: 'Support', url:'support', active:false}
  ]);
  MYSUBMENU = ([] = [
    { name: 'Login', url: 'login', action: 'login()', active: false },
    { name: 'Cart', url: '/cart', active: false },
    { name: 'Orders', url: '/orders', active: false },
    { name: 'Sensors', url: '/admin/sensors', active: false },
    { name: 'Profile', url: '/profile', active: false },
    { name: 'Register', url: 'register', active: false },
    { name: 'Logout', url: 'logout', active: false },
  ]);
  SATELLOGICTASKS = ([] = [
    { name: 'Authorise', value: 'auth' },
    { name: 'Scence', value: 'scenes' },
    { name: 'Get product list', value: 'productlist' },
    { name: 'Get task list', value: 'tasklist' },
    { name: 'Submit new task(Pt)', value: 'newtaskPt' },
    { name: 'Submit new task(Poly)', value: 'newtaskPoly' },
    { name: 'Query task status', value: 'taskstatus' },
    { name: 'Get capture list', value: 'capturelist' },
    { name: 'Cancel a Task', value: 'canceltask' },
  ]);
  AT21TASKS = ([] = [
    { name: 'Catalog retrieval', value: 'catalog retrieval' },
    { name: 'Catalog detailed Information', value: 'Catalog detailed' },
    { name: 'Archive product ordering', value: 'Archive product ordering' },
    { name: 'Programming order', value: 'Programming order' },
    { name: 'Order retrieval', value: 'Order retrieval' },
    { name: 'Order acquisition details', value: 'Order acquisition details' },
    { name: 'Order management', value: 'Order management' },
    { name: 'Order data acquisition', value: 'Order data acquisition' },
    { name: 'Order acquisition feedback', value: 'Order acquisition feedback' },
  ]);
  
  SENSORSTYPE = [
    {
      name: 'PAN',
      value: 'PAN',
      color: 'primary',
      selected: false,
      available: true,
      description: "Panchromatic Imaging",
      feasibility: false
    },
    {
      name: 'MSI',
      value: 'MSI',
      color: 'primary',
      selected: false,
      available: true,
      description: "Multi Spectral Imaging",
      feasibility: true
    },
    {
      name: 'HSI',
      value: 'HSI',
      color: 'primary',
      selected: false,
      available: true,
      description: "Hyper Spectral Imaging",
      feasibility: true
    },
    {
      name: 'SAR',
      value: 'SAR',
      color: 'primary',
      selected: false,
      available: true,
      description: "Synthetic Aperture Radar",
      feasibility: true
    },
    {
      name: 'InSAR',
      value: 'InSAR',
      color: 'primary',
      selected: false,
      available: true,
      description: "Interferometric Synthetic Aperture Radar",
      feasibility: false
    },
    {
      name: 'TIR',
      value: 'TIR',
      color: 'primary',
      selected: false,
      available: true,
      description: "Thermal Infrared",
      feasibility: false
    },
    {
      name: 'GHG',
      value: 'GHG',
      color: 'primary',
      selected: false,
      available: true,
      description: "Greenhouse Gas",
      feasibility: false
    },
    {
      name: 'Video',
      value: 'Video',
      color: 'primary',
      selected: false,
      available: true,
      description: "Full Motion Video",
      feasibility: true
    },
    {
      name: 'Stereo',
      value: 'Stereo',
      color: 'primary',
      selected: false,
      available: true,
      description: "Stereo-2D/3D",
      feasibility: true
    },
    {
      name: 'AIS',
      value: 'AIS',
      color: 'primary',
      selected: false,
      available: true,
      description: "Automatic Identification System",
      feasibility: true
    },
    {
      name: 'RF- comms',
      value: 'RF',
      color: 'primary',
      selected: false,
      available: true,
      description: "Radio Frequency Communications",
      feasibility: false
    }
  ];

  TASKFREQUENCY = [
    {
      name: 'Sub-daily',
      value: 4,
      color: 'primary',
      selected: false,
      available: true,
    },
    {
      name: 'Daily',
      value: 1,
      color: 'primary',
      selected: true,
      available: true,
    },
    {
      name: 'Weekly',
      value: 2,
      color: 'primary',
      selected: false,
      available: true,
    },
    {
      name: 'Monthly',
      value: 3,
      color: 'primary',
      selected: false,
      available: true,
    }
  ];

  dailyCount = [
    {
      label: 1,
      value: 1
    },
    {
      label: 2,
      value: 2
    },
    {
      label: 3,
      value: 3
    },
    {
      label: 4,
      value: 4
    },
    {
      label: 5,
      value: 5
    },
    {
      label: 6,
      value: 6
    },
    {
      label: 7,
      value: 7
    },
    {
      label: 8,
      value: 8
    },
  ];

  purposeOption = [
    {
      code: "0",
      name: 'Agriculture',
    },
    {
      code: "8",
      name: 'Climate Change & Sustainability',
    },
    {
      code: "6",
      name: 'Defence & Intelligence'
    },
    {
      code: "8",
      name: 'Disaster Management & Emergency Services',
    },
	 {
      code: "8",
      name: 'Energy & Utilities',
    },
	 {
      code: "5",
      name: 'Engineering & Construction'
    },
    {
      code: "8",
      name: 'Environmental Monitoring',
    },
    {
      code: "8",
      name: 'Finance & Banking',
    },
    {
      code: "8",
      name: 'Fisheries',
    },
    {
      code: "2",
      name: 'Forestry',
    },
    {
      code: "8",
      name: 'Insurance',
    },
    {
      code: "1",
      name: 'Mapping',
    },
    {
      code: "8",
      name: 'Marine & Oceanography',
    },
    {
      code: "8",
      name: 'Maritime Safety',
    },
    {
      code: "4",
      name: 'Maritime Security',
    },
    {
      code: "8",
      name: 'Mining & Resources',
    },
    {
      code: "8",
      name: 'Oil & Gas',
    },
    {
      code: "8",
      name: 'Telecommunication',
    },
    {
      code: "3",
      name: 'Urban & Industrial Management',
    },
    {
      code: "8",
      name: 'Water & Coastal Management',
    },
    {
      code: "8",
      name: 'Others',
    },
  ];

  organisationTypes = [
    {
      value: 0,
      name: 'Academia',
      key: 'academia'
    },
    {
      value: 1,
      name: 'Defence/Intelligence Agency',
      key: 'defenceAgency'
    },
    {
      value: 3,
      name: 'Enterprise',
      key: 'enterprise'
    },
    {
      value: 4,
      name: 'Govt Dept/Agency',
      key: 'govAgency'
    },
    {
      value: 5,
      name: 'Media',
      key: 'media'
    },
    {
      value: 6,
      name: 'Non-Govt Organisation (NGO)',
      key: 'nonGov'
    },
  ];



  observationSensorType = [
    { name: 'Cueing', value: 'Cueing', description: 'First sensor'},
    { name: 'Tipping', value: 'Tipping', description: 'Second sensor'},
  ];

  PAYOPTIONS = [
    { name: 'Pay now', value: '0', selected: true },
    { name: 'Debit account', value: '1', selected: false },
    { name: 'Pay later', value: '2', selected: false },
  ];

  orbitalPlane = [
    {
      name: 'Mid-latitude:45',
      value: 45
    },
    {
      name: 'Mid-latitude:53',
      value: 53
    },
    {
      name: 'SSO:97',
      value: 97
    }
  ];

  orbitalState = [
    {
      name: 'Ascending',
      value: 'ascending',
    },
    {
      name: 'Descending',
      value: 'descending',
    }
  ];

  observationDirections = [
    {
      name: 'Left',
      value: 'left',
    },
    {
      name: 'Right',
      value: 'right',
    }
  ];

  polarization = [
    {
      name: 'HH',
      value: 'HH',
    },
    {
      name: 'VV',
      value: 'VV',
    }
  ];

  intentOption = [
    {
      name: 'Internal Use',
      value: 0,
      description: 'Data can only be used within the registered organisation',
      key: 'internalUse'
    },
    {
      name: 'Product For Distribution',
      value: 1,
      description: 'The registered organisation may create derived product(s) from the tasked data for distribution outside the registered organisation',
      key: 'productForDistribution'
    },
    {
      name: 'Publish Publicly',
      value: 2,
      description: 'The registered organisation may publish the tasked data publicly',
      key: 'publishPublicly'
    },
    {
      name: 'Internal Use + Product For Distribution ',
      value: 3,
      key: 'internalAndDistribution'
    }
  ];

  strategyOptions = [
    {
      name: 'User Initiated',
      value: 'User Initiated',
      description: 'Select the sensor(s) of choice and choose either automatic tasking or a specific opportunity'
    },
    {
      name: 'Outcome By Platform',
      value: 'Outcome By Platform',
      description: 'Submit requirements for the best available option'
    },
    {
      name: 'Help Select Sensor(s)',
      value: 'Help Select Sensor(s)',
      description: 'Identify your use case, and the platform will help select appropriate sensors'
    },
    {
      name: 'Tip-and-Cue (User-Platform Augmented)',
      value: 'Tip-and-Cue',
      description: 'Configure multi-sensor workflows with either observations or business rules'
    },
    {
      name: 'AIS Included',
      value: 'AIS Included',
      description: 'This sensor needs to be tasked with one other sensor on the platform.'
    },
    {
      name: 'First Available By Platform',
      value: 'First Available By Platform',
      description: 'The first available based on requirements amongst all sensors will be chosen'
    }
  ];

  updateFrequencies = [
    {
      key: 'Every 2 minutes',
      value: 'Every 2 minutes',
      operatorKey: 'Spire'
    },
    {
      key: 'Every hour',
      value: 'Every hour',
      operatorKey: 'ClydeSpace'
    }
  ];

  getImageResolution(inputValue: number): number | null {
    if (inputValue > 20 && inputValue <= 50) {
      return 1.1; // 21km-50km -> 10%
    } else if (inputValue >= 51 && inputValue <= 100) {
      return 1.25; // 51km-100km -> 25%
    } else if (inputValue >= 101 && inputValue <= 200) {
      return 1.4; // 101km-200km -> 40%
    } else {
      return 1;
    }
  }

  sleep(waitingtime: any) {
    setTimeout(function () { }, waitingtime);
  }
  selectSensors(items: any) {
    // console.log(items);
    this.itemSource.next(items);
  }
  updateTaskParams(items: any) {
    //  console.log(items);
    this.taskparams.next(items);
  }
  // getSubscription(subscription: string) {
  //   //   console.log(params);
  //   const res = this
  //       .http
  //       .get(`${this.eePaypalUrl}paynow/${subscription}`);
  //   return res;
  // }

  // genOrder(orderBody: any) {
  //   // console.log(option, orderBody); return
  //   let url = '';
  //   const key = 'EarthEye';
  //   if (option === 0) {
  //     url = `${this.server}/order/paynow/${key}`;
  //   } else if (option === 1) {
  //     url = `${this.server}/order/payfromac/${key}`;
  //   } else if (option === 2) {
  //     url = `${this.server}/order/paylater/${key}`;
  //   }
  //   const res = this
  //     .http
  //     .post(`${url}`, {orderBody});
  //   return res;
  //   // res.subscribe(result => {
  //   //     console.log(result)
  //   // })
  // }

  orderdata(userid: string, params: any) {
    params = JSON.stringify(params);
    const res = this.http.get(
      `${this.server}/order/getorder/${userid}/${params}`);
    return res;
  }
  addOrder(order: any, option: number) {
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id, accountBalance: this.currentUser?.acBalance },
      order,
    };
    let url = '';
    const key = 'EarthEye';
    if (option === 0) {
      url = `${this.server}/order/paynow/${key}`;
    } else {
      const taskBody = {
        user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
        order,
      };
      let route = 'tasks';
      url = `${this.server}/order/addorder/${key}`;
    }
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  debitOrder(order: any, option: number) {
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id, accountBalance: this.currentUser?.acBalance },
      order,
    };
    let url = '';
    const key = 'EarthEye';
    url = `${this.server}/order/addorder/${key}`;
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  debitAccount(order: any, option: number) {
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      order,
    };
    let url = `${this.server}/order/debitnow`;
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  /**
   * updating payment status in task collection when pay now payment successfull
   * @param transactionId
   * @returns
   */
  updatePaymentStatus(transactionId: any) {
    const res = this.http.post(`${this.server}/order/success`, {
      taskId: localStorage.getItem('taskId'),
      orderId: localStorage.getItem('orderID'),
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
    });
    return res;
  }

  /**
   * creating payment intent for paypal when user add money to wallet
   * @param amount
   * @returns
   */
  addMoneyToWallet(amount: any) {
    const key = 'EarthEye';
    let url = `${this.server}/order/addmoney/${key}`;
    const orderBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      amount: amount,
    };
    const res = this.http.post(`${url}`, { orderBody });
    return res;
  }

  delOrderdata(orderid: string) {
    const res = this.http.get(`${this.server}/order/delete/${orderid}/`);
    return res;
  }

  getCart(userid: string) {
    const res = this.http.get(`${this.server}/cart/getcart/${userid}/`);
    return res;
  }

  addToCart(userid: string, cartbody: any) {
    const cartBody = {
      customerid: cartbody.customerid,
      taskid: cartbody.taskid,
      project: cartbody.project,
      taskname: cartbody.taskname,
      openWindow: cartbody.openWindow,
      closeWindow: cartbody.closeWindow,
      target: cartbody.target,
      cartdata: JSON.stringify(cartbody.cartdata),
      checked: cartbody.checked,
      taskFrequency: cartbody.taskFrequency,
      taskcost: cartbody.taskcost,
      totalVaoCost: cartbody?.totalVaoCost ?? 0,
      totalSRPCost: cartbody?.totalSRPCost ?? 0,
      feasibilityTask: cartbody.feasibilityTask,
      mlTasks: cartbody.mlTasks,
      taskStrategy: cartbody?.taskStrategy,
      purpose: cartbody?.purpose,
      intent: cartbody?.intent,
      repeatFrequency: cartbody?.repeatFrequency,
      noOfRepeats: cartbody?.noOfRepeats,
      subDailyCount: cartbody?.subDailyCount,
      projectid: cartbody?.projectid,
      openWater: cartbody?.openWater
    };

    const res = this.http.post(`${this.server}/cart/add2cart/${userid}`, { cartBody });
    return res;
  }

  cartdata(userid: string) {
    const rest = this.http
      .get(`${this.server}/cart/getcart/${userid}/`)
      .subscribe((res: any) => {
        const cart: any = [];
        res.result.forEach((row: any) => {
          const oprtrs = JSON.parse(row.cartdata);
          let taskcost = 0;
          if (oprtrs.length > 0) {
            oprtrs.forEach((oprtr) => {
              taskcost += oprtr.cost;
            });
            row.taskcost = taskcost;
            row.cartdata = oprtrs;
            cart.push(row);
          }

        });
        this.cartBS.next(cart);
        rest.unsubscribe();
      });
  }

  updateCartDB(userid: string, cartbody: any) {
    const customerid = cartbody.customerid,
      project = cartbody.project,
      taskname = cartbody.taskname,
      target = cartbody.target,
      cartdata = JSON.stringify(cartbody.cartdata),
      taskid = cartbody.taskid,
      openWindow = cartbody.openWindow,
      closeWindow = cartbody.closeWindow,
      taskcost = cartbody.taskcost,
      totalVaoCost =  cartbody?.totalVaoCost ?? 0,
      totalSRPCost =  cartbody?.totalSRPCost ?? 0,
      checked = cartbody.checked,
      taskFrequency = cartbody.taskFrequency;

    // const cartBody = {customerid, taskid, project, taskname, createdAt, target, cartdata, taskcost, checked};
    const cartBody = {
      customerid,
      taskid,
      project,
      taskname,
      openWindow,
      closeWindow,
      target,
      cartdata,
      taskcost,
      checked,
      taskFrequency,
      totalVaoCost,
      totalSRPCost
    };
    // console.log(cartbody)
    let result = '';
    const res = this.http
      .post(`${this.server}/cart/add2cart/${userid}`, { cartBody })
      .subscribe((res: any) => {
        //  console.log(res.cart, res.Msg)
        // if (res.cart.length>0) this.cartBS.next(res.cart);
        return 'Success';
      });
  }
  delCartdata(cartid: string) {
    const userId = this.currentUser.id;
    let cartdetail = { taskid: cartid, item: -9, userId };
    if (cartid.includes('#')) {
      const taskid = cartid.split('#')[0];
      const item = parseInt(cartid.split('#')[1]);
      cartdetail = { taskid, item, userId };
    }
    const res = this.http.post(`${this.server}/cart/delete`, { cartdetail });
    return res;
  }

  deleteSensor(taskBody: any) {
    let cartdetail = { taskid: taskBody.taskid, operators: taskBody.operators, userId: this.currentUser.id };
    const res = this.http.post(`${this.server}/cart/deleteSensor`, { cartdetail });
    return res;
  }

  taskIdResponse(action: string, tasks: any) {

    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      tasks,
    };
    let route = 'tasks';
    const res = this.http.post(
      `${this.server}/${route}/${action}/${tasks.taskid}`,
      { taskBody }
    );
    return res;
  }

  cancelSensor(action: string, tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      tasks,
    };
    let route = 'cancel';
    const res = this.http.post(`${this.server}/${route}/cancelSensor`, { taskBody });
    return res;
  }

  cancelFeasibility(opportunities: any,tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      tasks,
      opportunities: opportunities,
    };
    let route = 'cancel';
    const res = this.http.post(`${this.server}/${route}/axelorderedFeasibilityCancel`, { taskBody });
    return res;
  }

  getCancelPolicy(tasks: any) {
    const taskBody = {
      user: { userId: this.currentUser.id },
      tasks,
    };
    let route = 'cancel';
    const res = this.http.post(`${this.server}/${route}/cancelPolicy`, { taskBody });
    return res;
  }

  taskResponse(params: string, userid: string, taskBody: any) {
    if (params !== 'newtask') {
      // for get or patch request
      const taskid = taskBody;
      const res = this.http.get(
        `${this.server}/index/${params}/${userid}/${taskid}`
      );
      return res;
    }
    return
  }
  testmail() {
    const result = this.http.get(`${this.server}/tasks`).subscribe((res) => {
      console.log(res);
    });
  }
  newTaskSubmit(tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      tasks,
    };
    let result;
    result = this.http.post(`${this.server}/tasks/newtask`, { taskBody });
    return result;
  }

  multiPoiTaskSubmit(tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      tasks,
    };
    let result;
    result = this.http.post(`${this.server}/status/submitMultiTask`, { taskBody });
    return result;
  }

  genCollection(userid: any, useremail, tasks: any) {
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email },
      tasks,
    };
    // console.log(params, this.server); return
    let result;
    result = this.http.post(
      `${this.server}/tasks/collection/${userid}/${useremail}`,
      { taskBody }
    );
    return result;
  }

  getProjects() {
    return this.http.get(`${this.server}/tasks/getprojects/${this.currentUser?.id}`);
  }

  getSensorDetails(sensorType: any,target: any, intentOption: any) {
    let intent: any;
    if (intentOption === 'Internal Use') intent = 'internalUse';
    else if (intentOption === 'Product For Distribution') intent = 'productForDistribution';
    else if (intentOption === 'Both') intent = 'internalAndDistribution';
    else intent = intentOption;
    return this.http.get(`${this.server}/tasks/getResolution/${this.currentUser?.id}/${sensorType}/${target}/${intent}`);
  }

  getBudgetSensorType(intent) {
    return this.http.post(`${this.server}/budget/listBudgetSensors`, intent);
  }

  getBudgetResolutionDetails(sensorType: any) {
    const taskBody = {
      sensorType:sensorType
    };
    return this.http.post(`${this.server}/budget/listBudgetResolution`,  taskBody );
  }
  getBudgetTierDetails(sensorType: any,resolution:any) {
    const taskBody = {
      sensorType:sensorType,
      resolution:resolution
    };
    return this.http.post(`${this.server}/budget/listTier`, taskBody );
  }
  getBudgetCcandHoldbackDetails(sensorType: any,resolution:any,tier:any) {
    const taskBody = {
      sensorType:sensorType,
      resolution:resolution,
      tier:tier
    };
    return this.http.post(`${this.server}/budget/listInputs`, taskBody );
  }
  
  outcomeTaskSubmit(tasks: any) {
    tasks.customerid = this.currentUser.id;
    const taskBody = {
      user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
      tasks,
    };
    let result;
    result = this.http.post(`${this.server}/tasks/submitOutcomeTask`, { taskBody });
    return result;
  }
 
  getObservations() {
    return this.http.get(`${this.server}/tasks/getObservation`);
  }

  getProjectName() {
    return this.http.get(`${this.server}/tasks/getprojectname/${this.currentUser?.id}`);
  }

  getCollections(item) {
    return this.http.get(`${this.server}/tasks/getcollection/${this.currentUser?.id}/${item}`);
  }

  getAISData(item, taskid) {
    return this.http.get(`${this.server}/tasks/vessels/${taskid}?polygon=${item.polygon}&startTime=${item.startTime}&endTime=${item.endTime}&action=${item.type}`);
  }

  getAISRefreshData(sensorObj) {
    sensorObj.userid = this.currentUser?.id;
    return this.http.post(`${this.server}/tasks/getRefreshHistory`, sensorObj);
  }

  getClydeAISData(item, taskid) {
    return this.http.get(`${this.server}/tasks/getClydeVessels/${taskid}?message_type=1&startTime=${item.startTime}&endTime=${item.endTime}&polygon=${item.polygon}&action=${item.type}`);
  }

  getCollectionImage(taskId, sensorObj) {
    return this.http.post(`${this.server}/tasks/getcollectionImage/${this.currentUser?.id}/${taskId}`, sensorObj);
  }

  getInformationServices(sensorType, taskid) {
    return this.http.post(`${this.server}/tasks/getInformationServices/`, {sensorType, taskid});
  }

  getFeedsBySensorType(sensorType) {
    return this.http.post(`${this.server}/tasks/getInformationServicesBySensorType/`, {sensorType});
  }

  deleteInformationService(data) {
    return this.http.post(`${this.server}/cart/deleteMlData`, {
      taskid: data.taskId,
      informationid: data.informationId,
      userid: data.userId,
      sensorid: data?.sensorId
    });
  }
  getSatelliteData(sensorObj: any) {
    return this.http.post(`${this.server}/tasks/getSatelliteData`, sensorObj);
  }

  updateCollectionDownload(sensorObj: any) {
    return this.http.post(`${this.server}/tasks/updateCollection`, sensorObj);
  }

  returnRefundRequest(sensorObj: any) {
    return this.http.post(`${this.server}/tasks/returnRefundRequest`, sensorObj);
  }

  returnRefundStatus(sensorObj: any) {
    const params = {taskId: sensorObj?.taskId, sensorId: sensorObj?.sensorId, sceneId: sensorObj?.sceneId, product: sensorObj?.product,
      action: sensorObj?.action
    };
    return this.http.post(`${this.server}/tasks/returnRefundStatus`, params);
  }

  restoreRequest(sensorObj: any) {
    return this.http.post(`${this.server}/tasks/saveRestoreDetails`, sensorObj);
  }

  restoreStatus(statusObj: any) {
    return this.http.post(`${this.server}/tasks/restoreStatus`, statusObj);
  }
  
  getCloudCoverData(sensorObj: any) {
    return this.http.post(`${this.server}/tasks/getCloudCoverData`, sensorObj);
  }

  getFeasibilities(userId: any, accessId: any, taskid: any, row: any) {
    const params = {userId: userId, accessId: accessId, taskId: taskid, sensors: row};
    return this.http.post(`${this.server}/tasks/getOpportunityList`, params);
  }

  getFeasibilityCost(userId: any, feasibilities: any, taskid: any, row: any) {
    const params = {userId: userId, opportunities: feasibilities, taskId: taskid, sensors: row};
    return this.http.post(`${this.server}/tasks/feasibilityCostUpdation`, params);
  }
  saveOpportunity(userId: any, taskid: any,  row: any) {
    const params = {userId: userId, taskId: taskid, sensors: row};
    return this.http.post(`${this.server}/tasks/saveOpportunity`, params);
  }

  getSensorTypes(task: any) {
    const params = {userId: this.currentUser?.id, taskId: task};
    return this.http.post(`${this.server}/tasks/sensorTypes`, params);
  }

  getSensorData(sensorType: any, task: any) {
    const params = {userId: this.currentUser?.id, sensorType: sensorType, taskId: task};
    return this.http.post(`${this.server}/tasks/sensors`, params);
  }

  addAllocation(sensors: any) {
    
    const isTristereo = sensors?.sensorName?.sensor === "Tristereo" ? true : false;
    const sensorBody = {
      userId: this.currentUser.id,
      sensor: sensors.sensorType,
      operator: sensors.operator,
      resolution: sensors.resolution,
      unitCost: sensors.unitCost,
      taskingTier: sensors.taskingTier,
      amountPercentage: sensors.amountInPercentage,
      amountDollar: sensors.actualAmount,
      noOfTasks: sensors.noOfTasks,
      isTristereo: isTristereo
    };
    let result;
    result = this.http.post(`${this.server}/tasks/addAllocation`, sensorBody );
    return result;
  }

  getAllocation() {
    return this.http.get(`${this.server}/tasks/listAllocatedSensors/${this.currentUser?.id}`);
  }

  allocateUser(topUpBalance) {
    const params = {userId: this.currentUser?.id, topUpBalance: topUpBalance};
    console.log("params", params);
    return this.http.post(`${this.server}/tasks/allocateUser`, params);
  }

  deleteAllocationTable() {
    const params = {userId: this.currentUser?.id};
    return this.http.post(`${this.server}/tasks/dropAllAllocation`, params);
  }

  deleteCartData(taskId: any) {
    const params = {userId: this.currentUser?.id, taskId: taskId};
    return this.http.post(`${this.server}/tasks/deleteFromCart`, params);
  }

  editAllocation(sensors: any) {
    const isTristereo = sensors?.sensorName?.sensor === "Tristereo" ? true : false;
    const sensorBody = {
      userId: this.currentUser.id,
      sensor: sensors.sensorType,
      operator: sensors.operator,
      resolution: sensors.resolution,
      unitCost: sensors.unitCost,
      amountPercentage: sensors.amountInPercentage,
      amountDollar: sensors.actualAmount,
      _id: sensors.id,
      noOfTasks: sensors.noOfTasks,
      taskingTier: sensors.taskingTier,
      isTristereo:isTristereo
    };
    let result;
    result = this.http.post(`${this.server}/tasks/updateAllocation`, sensorBody);
    return result;
  }

  deleteAllocation(id: any) {
    const options = {
      body: { userId: this.currentUser?.id, _id: id }
    };
    return this.http.delete(`${this.server}/tasks/deleteAllocation`, options);
  }

  allocationSufficientCheck(operatorData: any, taskId) {
    const object = {
      userid: this.currentUser.id,
      taskId: taskId,
      operators: operatorData,
    };
    let result;
    result = this.http.post(`${this.server}/tasks/checkBalance`, object );
    return result;
  }

  getTaskList(projectId: any, category: string) {
    return this.http.get(`${this.server}/status/getTaskByProject/${this.currentUser?.id}/${category}/${projectId}`);
  }

  getProjectList(category: string) {
    return this.http.get(`${this.server}/status/getDistinctProjects/${this.currentUser?.id}/${category}`);
  }

  getTaskCount(userId) {
    return this.http.get(`${this.server}/status/getTasksCount/${userId}`);
  }

  getData(taskParams: any) {
    let projectId = taskParams?.projectId, category = taskParams?.tabSelected, pageIndex = taskParams?.currentIndex,
    searchKey = taskParams?.searchKey, taskId = taskParams?.taskId;
    const searchKeyParam = searchKey ? `/${searchKey}` : '';
    const taskIdParam = taskId ? `/${taskId}` : '';
    return this.http.get(`${this.server}/status/getTaskDetails/${this.currentUser?.id}/${category}/${projectId}${taskIdParam}/${pageIndex}${searchKeyParam}`);
  }

  saveRating(taskId: any, message: any) {
  const params = {userId: this.currentUser?.id,
    taskId: taskId,
    feedback: message.feedback,
    rating: message.rating
  };
  return this.http.post(`${this.server}/tasks/submitFeedback`, params);
}

getBudgetInputs() {
  return this.http.get(`${this.server}/budget/getBudgetInputs`);
}

createBudget(tasks: any) {
  const taskBody = {
    user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
    tasks,
  };
  let result;
  result = this.http.post(`${this.server}/budget/newBudget`, { taskBody });
  return result;
}

saveBudget(tasks: any) {
  const taskBody = {
    user: { name: this.currentUser.name, email: this.currentUser.email, userId: this.currentUser.id },
    tasks,
  };
  let result;
  result = this.http.post(`${this.server}/budget/saveBudget`, { taskBody });
  return result;
}
updateBudget(id:any){
  let result;
  result = this.http.put(`${this.server}/budget/budgetIsTasked/${id}`, {  });
  return result;
}
getHistoricalData(vessels: any) {  
  return this.http.get(`${this.server}/tasks/getHistoricalVesselData?endTime=${vessels.endTime}&inputMinutes=${vessels.inputMinutes}&mmsi=${vessels.mmsi}&eeTaskId=${vessels.eeTaskId}`);
}


getClydeHistoricalData(vessels: any) {  
  return this.http.get(`${this.server}/tasks/getClydeHistoricalData/${vessels.eeTaskId}?message_type=1&startTime=${vessels.startTime}&endTime=${vessels.endTime}&polygon=${vessels.polygon}&startTimeType=${vessels.startTimeType}&inputTime=${vessels.duration}&mmsi=${vessels.mmsi}`);
}

addCollectionData(sensorObj: any) {
  return this.http.post(`${this.server}/status/getAdditionalProducts`, sensorObj);
}

getValueAddedData(sensorObj) {
  return this.http.post(`${this.server}/vao/getCollectionImgUrl/${this.currentUser?.id}`, sensorObj);
}

getValueAddedDataImgUrl(sensorObj) {
  return this.http.post(`${this.server}/srp/getCollectionImgUrl/${this.currentUser?.id}`, sensorObj);
}
getIndicesDataImgUrl(sensorObj) {
  return this.http.post(`${this.server}/indices/getCollectionImgUrl/${this.currentUser?.id}`, sensorObj);
}

checkIsWater(location) {
  location.userId = this.currentUser?.id;
  return this.http.post(`${this.server}/status/checkIsWater`, location);
}

getPurpose() {
  return this.http.get(`${this.server}/tasks/getpurposes`);
}

processVADP(sensorObj: any) {
  return this.http.post(`${this.server}/tasks/processVadRequest`, sensorObj);
}

getOrganisations() {
  return this.http.get(`${this.server}/tasks/getOrganisationTypes`);
}

  async genReport(orders: any, start: any, end: any) {

    let reportType = 'Order Report',
      logo;
    const exs: any = [];
    const receiptNo = null;
    const paidamout = 0;
    const orderamount = 0;
    const gstpercent = 37;
    const grandTotal = orderamount + (orderamount * gstpercent) / 100;
    const balance = grandTotal - paidamout;
    const logoimge = '../assets/images/logo.png';
    const customer = this.currentUser;
    if (this.currentUser?.id) {
      this.userService.personalInfo(this.currentUser?.id).subscribe(async (res: any) => {
        this.currentUser = await res;

        let address, fullAddress;
        if (this.currentUser?.address1) {
          fullAddress =
            this.currentUser?.address1 + ' ' + this.currentUser?.address2 + ' , ' + this.currentUser?.country;
        } else {
          fullAddress = 'Not available';
        }
        let periods = "", dateRange = '';
        if (start !== undefined && end !== undefined) {
          start = start.toDateString();
          end = end.toDateString();
          periods = start + ' - ' + end;
          dateRange = start + ' - ' + end;
        }
        else {
          periods = 'Till ' + new Date().toDateString();
          dateRange = new Date().toDateString();
        }
        await this.toDataURL(logoimge).then((dataUrl) => {
          logo = dataUrl;
        });
        // console.log(logo)
        exs.push([
          {
            columns: [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: 'Item No.',
                style: 'tableHeader',
              },
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: 'Order Id',
                style: 'tableHeader',
              },
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: 'Order/Credit Note Date',
                style: 'tableHeader',
              },
              {
                width: 120,
                alignment: 'center',
                fontSize: 10,
                text: 'Description',
                style: 'tableHeader',
              }, 
              this.currentUser?.allocated 
                ? {
                  width: 60,
                  alignment: 'center',
                  fontSize: 10,
                  text: 'Task Number',
                  style: 'tableHeader',
                } :
                {
                  width: 60,
                  alignment: 'center',
                  fontSize: 10,
                  text: 'Amount',
                  style: 'tableHeader',
                },
              {
                width: 80,
                alignment: 'center',
                fontSize: 10,
                text: 'Order Status',
                style: 'tableHeader',
              },
            ],
          },
        ]);
        orders.forEach((order, index) => {     
          const description = order.taskid;
          const status =
            order.orderstatus.charAt(0).toUpperCase() + order.orderstatus.slice(1);
          const orderamount = this.dollarUS.format(order?.refundAmount ? order.refundAmount : order.orderamount);
          const taskNumber = order?.refundTaskNumber ? order.refundTaskNumber : order?.totalTaskNumber;
          const orderpoint = order?.refundPoint ? order.refundPoint : order.orderPoint;
          let reportType = 'Receipt';
          if (order.payoption === 'Pay later') {
            reportType = 'Invoice';
          } else if (order.refundCheck) {
            reportType = 'Credit Note';
          }

          exs.push([
            {
              columns: [
                { width: 50, alignment: 'center', fontSize: 10, text: index + 1 },
                { width: 100, alignment: 'center', fontSize: 10, text: order.id },
                {
                  width: 100,
                  alignment: 'center',
                  fontSize: 10,
                  text: this.datepipe.transform(new Date(order.createdAt).toDateString(), 'dd/MM/yyyy'),
                },
                {
                  width: 120,
                  alignment: 'center',
                  fontSize: 10,
                  text: reportType,
                },
                { width: 60, alignment: 'center', fontSize: 10, text: this.currentUser?.allocated ? taskNumber : this.currentUser?.pointsEligible ? orderpoint + ' Points' : orderamount },
                { width: 80, alignment: 'center', fontSize: 10, text: status },
              ],
            },
          ]);
        });

        let docDefinition: any = {
          header: {
            margin: 1,
            columns: [
              {
                image: logo,
                width: 140,
                margin: [30,10,5,5]
              },
            ],
          },
          footer: {
            alignment: 'center',
            columns: [
              {
                text: 'Eartheye Space Technologies Pte Ltd, 1 North Bridge Road, #18-03, High Street Centre, Singapore 179094',
                style: 'CompanyAddress',
              },
            ],
          },
          content: [
            {
              text: reportType,
              fontSize: 18,
              alignment: 'center',
              color: '#000',
              margin: 20,
            },
            {
              text: [
                { text: 'Name: ', style: 'HeaderNameA' },
                { text: customer.name, style: 'HeaderNameB' },
              ],
            },
            {
              text: [
                { text: 'Email: ', style: 'HeaderNameA' },
                { text: customer.email, style: 'HeaderNameB' },
              ],
            },
            {
              text: [
                { text: 'Address: ', style: 'HeaderNameA' },
                { text: fullAddress, style: 'HeaderNameB' },
              ],
            },
            {
              text: [
                { text: 'Order history as at: ', style: 'HeaderNameA' },
                { text: new Date().toDateString(), style: 'HeaderNameB' },
              ],
              margin: [0, 10, 0, 0],
            },
            {
              text: [
                { text: 'Period: ', style: 'HeaderNameA' },
                { text: periods, style: 'HeaderNameB' },
              ],
              margin: [0, 0, 0, 10],
            },
            {
              style: 'tableExample',
              table: {
                headerRows: 1,
                body: [...exs],
              },
              layout: {
                hLineWidth: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return i === 0 || i === node.table.widths.length ? 2 : 1;
                },
                hLineColor: function (i, node) {
                  return i === 0 || i === node.table.body.length ? 'black' : 'gray';
                },
                vLineColor: function (i, node) {
                  return i === 0 || i === node.table.widths.length
                    ? 'black'
                    : 'gray';
                },
              },
            },
            {
              columns: [
                [
                  {
                    text: '*Order Value in USD',
                    style: 'BottomPrice',
                  },
                ],
              ],
            },
            {
              columns: [
                [
                  {
                    text: 'Thank you for your patronage.',
                    style: 'thanksMsg',
                  },
                ],
              ],
            },
          ],
          styles: {
            logostyle: {
              alignment: 'center',
              // margin: [0, 0, 0, 0]
            },
            CompanyAddress: {
              fontSize: 10,
              bold: false,
              alignment: 'center',
              color: '#000',
              // margin: [0, 10, 0, 20]
            },
            OrderRight: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [0, 50, 0, 0],
            },
            CustomerLeft: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 50, 0, 0],
            },
            OrderRight1: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [0, 5, 0, 0],
            },
            CustomerLeft1: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 5, 0, 0],
            },
            Notes: {
              fontSize: 18,
              bold: true,
              alignment: 'left',
              color: '#000',
              margin: [0, 5, 0, 0],
            },
            BottomPrice: {
              fontSize: 10,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [2, 5, 0, 0],
            },
            thanksMsg: {
              fontSize: 12,
              margin: [10, 20, 0, 0],
            },
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: 'black',
            },
            HeaderNameA: {
              fontSize: 12,
              bold: true,
              alignment: 'left',
              color: '#000',
              margin: [0, 10, 10, 0],
            },
            HeaderNameB: {
              fontSize: 12,
              bold: false,
              alignment: 'left',
              color: '#000',
              margin: [0, 10, 10, 0],
            },
            HeaderNameC: {
              fontSize: 12,
              bold: false,
              alignment: 'right',
              color: '#000',
              margin: [0, 10, 10, 0],
            },
          },
        };

        if (this.isInIframe) {
          delete docDefinition.header;
          pdfMake.createPdf(docDefinition).download(dateRange + '.pdf');
        } else {
          pdfMake.createPdf(docDefinition).open();
        }
      });
    }
  }

  async genPdf(order: any) {
    let reportType = 'Receipt',
      logo;

    if (order.paystatus !== 'success') reportType = 'Invoice';
    const exs: any = [];
    const receiptNo = order.id;
    const paidamout = 0;
    const orderamount = order.orderamount;
    const gstpercent = 37;
    const grandTotal = orderamount + (orderamount * gstpercent) / 100;
    const balance = grandTotal - paidamout;
    const logoimge = '../assets/images/logo.png';

    let address, fullAddress;
    if (this.currentUser?.id) {
      this.userService.personalInfo(this.currentUser?.id).subscribe(async (res: any) => {
        this.currentUser = res;
        address = JSON.parse(this.currentUser.address);
        fullAddress =
          address.housedetail + ' - ' + address.pincode + ', ' + address.country;
      });
    } else {
      fullAddress = 'Not available';
    }
    await this.toDataURL(logoimge).then((dataUrl) => {
      logo = dataUrl;
    });
    exs.push([
      {
        columns: [
          {
            width: 50,
            alignment: 'center',
            fontSize: 10,
            text: 'Item No.',
            style: 'tableHeader',
          },
          {
            width: 120,
            alignment: 'center',
            fontSize: 10,
            text: 'Sensor Name',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Resolution',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Unit Price',
            style: 'tableHeader',
          },
          {
            width: 50,
            alignment: 'center',
            fontSize: 10,
            text: 'Quantity',
            style: 'tableHeader',
          },
          {
            width: 100,
            alignment: 'center',
            fontSize: 10,
            text: 'Subtotal',
            style: 'tableHeader',
          },
        ],
      },
    ]);
    order.orderdetail.forEach((sensor, index) => {
      // let sensor = JSON.parse(currentValue);
      const name = sensor.operator.concat(' ', sensor.sensor);
      const description = sensor.resolution;
      const cost = this.dollarUS.format(sensor.cost);
      const qnty = 1;
      const subcost = this.dollarUS.format(sensor.cost);
      exs.push([
        {
          columns: [
            [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: index + 1,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 120,
                alignment: 'left',
                fontSize: 10,
                text: name,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 120,
                alignment: 'center',
                fontSize: 10,
                text: description,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: cost,
                margin: [35, 5, 0, 5],
              },
            ],
            [
              {
                width: 50,
                alignment: 'center',
                fontSize: 10,
                text: qnty,
                margin: [0, 5, 0, 5],
              },
            ],
            [
              {
                width: 100,
                alignment: 'center',
                fontSize: 10,
                text: subcost,
                margin: [0, 5, 0, 5],
              },
            ],
          ],
        },
      ]);
    });

    let docDefinition = {
      header: {
        margin: 10,
        columns: [
          {
            image: logo,
            width: 140,
          },
        ],
      },
      content: [
        // {
        //   text: 'logo',
        //   link: '../assets/images/logo.png',
        //   alignment: 'left',
        //   color: 'blue',
        // },
        {
          text: reportType,
          fontSize: 20,
          alignment: 'right',
          color: '#000',
        },
        {
          margin: [0, 25, 0, 0],
          text: 'Eartheye Space Technologies Pte Ltd',
          fontSize: 12,
          bold: true,
          alignment: 'left',
          color: '#000',
        },
        {
          text: '1 North Bridge Road',
          style: 'CompanyAddress',
        },
        {
          text: '#18-03, High Street Centre',
          style: 'CompanyAddress',
        },
        {
          text: 'Singapore 179094',
          style: 'CompanyAddress',
        },
        {
          columns: [
            [{ text: 'Bill to: ', style: 'CustomerLeft' }],
            [
              {
                text: ' Order No: ' + order.id,
                style: 'OrderRight',
                // text: [
                //   {text: 'Order No: ', bold:true, style:'OrderRight' },
                //  {text: order.id, style:'OrderRight' }
                // ],
              },
            ],
          ],
        },
        {
          columns: [
            [{ text: this.currentUser.name, style: 'CustomerLeft1' }],
            [
              {
                // text: [{text: reportType+' Date: ', bold:true, style:'OrderRight1' }, {text: new Date(order.createdAt).toDateString(), style:'OrderRight1' }],
                text:
                  reportType +
                  ' Date: ' +
                  new Date(order.createdAt).toDateString(),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Address: ' + fullAddress,
                style: 'CustomerLeft1',
                margin: [0, 0, 0, 10],
              },
            ],
            [
              {
                // text: [{text: reportType+' No: ', bold:true, style:'OrderRight1' }, {text: receiptNo, style:'OrderRight1' }],
                text: reportType + ' No: ' + receiptNo,
                style: 'OrderRight1',
              },
            ],
          ],
        },
        // {
        //   columns: [
        //     [{
        //       text: 'Address: ' + fullAddress,
        //       style: 'CustomerLeft1',
        //       margin: [0, 0, 0, 10]
        //     }
        //     ]]
        // },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*'],
            body: [
              ...exs,
              // ['Item No.', 'Item Name', 'Description', 'Unit Price','Quantity','Subtotal'],
              ///  orderdetail.map(o=>([o.name,o.name,o.name,o.name,o.name,o.name]))
            ],
          },
        },
        // {
        //   style: 'tableExample',
        //   table: {
        //     headerRows: 1,
        //     body: [
        //       ['Subtotal: ', this.dollarUS.format(orderamount)],
        //       ['GST (%):',  gstpercent.toFixed(2)],
        //       ['Total: ',this.dollarUS.format(grandTotal)],
        //       ['Balance Due: ',this.dollarUS.format(balance)],
        //     ],
        //     alignment: "right"
        //   },
        //   layout: 'noBorders'
        // },
        {
          columns: [
            [
              {
                text: 'Subtotal: ' + this.dollarUS.format(orderamount),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'VAT+NFRC (%): ' + gstpercent.toFixed(2),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Total: ' + this.dollarUS.format(grandTotal),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Balance Due: ' + this.dollarUS.format(balance),
                style: 'OrderRight1',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Notes',
                style: 'Notes',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: 'Price in USD',
                style: 'BottomPrice',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: '<Any other Notes>',
                style: 'BottomPrice',
              },
            ],
          ],
        },
        {
          columns: [
            [
              {
                text: '<Eartheye Director Signature> 	',
                style: 'BottomPrice',
              },
            ],
          ],
        },
      ],
      styles: {
        CompanyAddress: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 10, 0, 0],
        },
        OrderRight: {
          fontSize: 12,
          bold: false,
          alignment: 'right',
          color: '#000',
          margin: [0, 50, 0, 0],
        },
        CustomerLeft: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 50, 0, 0],
        },
        OrderRight1: {
          fontSize: 12,
          bold: false,
          alignment: 'right',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        CustomerLeft1: {
          fontSize: 12,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        Notes: {
          fontSize: 18,
          bold: true,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        BottomPrice: {
          fontSize: 13,
          bold: false,
          alignment: 'left',
          color: '#000',
          margin: [0, 5, 0, 0],
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          margin: [5, 5, 0, 5],
          color: 'black',
        },
      },
    };
    //pdfmake.createPdf(docDefinition).open();
    //pdfmake.createPdf(docDefinition).download();
  }

  genPdf_bak(status: string, order: any) {
    var dd = {
      content: [
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: ['*', 'auto', 100, '*'],

            body: [
              [
                { text: 'First', bold: true },
                { text: 'Second', bold: true },
                { text: 'Third', bold: true },
                { text: 'The last one', bold: true },
              ],
              ['Value 1', 'Value 2', 'Value 3', 'Value 4'],
              ['Val 2', 'Val 2', 'Val 3', 'Val 4'],
            ],
          },
        },
      ],
    };

    const documentDefinition = {
      content: 'This is an sample PDF printed with pdfMake',
    };
    // pdfmake.createPdf(dd).open();
    // pdfmake.createPdf(dd).download();
  }

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );
  
  approvePayment(payerId:any, paymentId:any) {
    return this.http.post(`${this.server}/tasks/approvePayment`, {
      payerId: payerId,
      paymentId: paymentId,
    });
  }

  paymentSuccess(paymentId:any, userId: any, paymentMethod: any) {
    return this.http.post(`${this.server}/tasks/deposits`, {
      paymentId: paymentId,
      customerId: userId,
      paymentType: paymentMethod
    });
  }

  getTaskingTiersData(sensorType: any, sensor:any) {
    const params = {userId: this.currentUser?.id, sensorType: sensorType, sensor: sensor.name, resolution: sensor.resolution};
    return this.http.post(`${this.server}/tasks/getTaskingTiers`, params);
  }

  /** Annotation Apis */
  addAnnotation(params:any){
    params.customerid = this.currentUser.id;
    return this.http.post(`${this.server}/tasks/annotations`, params);
  }

  getAnnotations(taskId:string, sensorId:string, sceneId:string)
  {
    const res = this.http.get(
      `${this.server}/tasks/annotations/${this.currentUser.id}/${taskId}/${sensorId}/${sceneId}`);
    return res;
  }

  deleteAnnotations(params) {
    params.customerid = this.currentUser.id;
    return this.http.post(`${this.server}/tasks/deleteannotation`, params);
  }

  editAnnotations(params) {
    params.customerid = this.currentUser.id;
    return this.http.post(`${this.server}/tasks/updateAnnotation`, params);
  }
}
