import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialog } from './confirmdialog';
import * as moment from 'moment';
import { CountrycheckComponent } from './countrycheck/countrycheck.component';
import { SatellogicWarningComponent } from './satellogic-warning/satellogic-warning.component';
import { WarningPopup } from './warning-popup/warning-popup';
import { SensorpreviewComponent } from './sensorpreview/sensorpreview.component';
import { CAPELLA_MODES, CONFIG_VALUES, OPERATORS, polarization, RF_RADIUS, SENSOR_BAND_DATA } from 'src/app/services/constants';
import { PopupService } from 'src/app/services/popup.service';
import { AdvanceSettingsComponent } from './advance-settings/advance-settings.component';
import { OperatorPopupComponent } from './operator-popup/operator-popup.component';
import { DataService } from 'src/app/services/data.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AgreeComponent } from '../payment/agree/agree.component';
var geoParse = require('wellknown');
import { ConfirmPopupComponent } from '../my-task/confirm-popup/confirm-popup.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as Cesium from 'cesium';
import { MatSelect } from '@angular/material/select';
import { RequiredPopupComponent } from './required-popup/required-popup.component';

interface WaterCheckResponse {
  water: boolean;
  latitude: string;
  longitude: string;
}

@Component({
  selector: 'app-taskcost',
  templateUrl: './taskcost.component.html',
  styleUrls: ['./taskcost.component.scss'],
})
export class TaskcostComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('mySelects') mySelects: MatSelect;
  @Output() resetTasking = new EventEmitter<any>();
  @Input() target: any;
  sensors: any;
  SensorsTypes = this.apiService.SENSORSTYPE;
  totalAmount: number;
  currency: any;
  currencySign: any;
  currentUser: any;
  subscription: any;
  SENSORS: any[];
  AVAILABLESENSORS: any = [];
  FILTEREDSENSORS: any[];
  selectedSensorTypes: string;
  allComplete: boolean;
  dataSource: any;
  isAISSensor: boolean = false;
  displayedColumns: string[] = [
    'operator',
    'band',
    'preview',
    'mode',
    'resolution',
    'area_calculated',
    'dem1',
    'cloudcovers',
    'ONA',
    'unitcost',
    'holdback',
    'advance',
    'cost',
    'opportunity',
    'footprint',
    'clientInfo',
    'eula',
  ];
  selectedSensors: any[] = [];
  selctedCollectMode: any = [];
  selectedLatency: any = [];
  selectedCost: any[] = [];
  selectedHoldBack: any = [];
  selectedONA: any = [];
  selectedMode: any = [];
  tooltip: any[];
  totalAOI: any;
  taskparams: any;
  isAllSensors = false;
  isNightSensor = false;
  latency = new FormControl();
  cloudcover = new FormControl();
  collectmode = new FormControl();
  errorMsg: any;
  valid = false;
  agreeTnC = true;
  enable: boolean = false;
  modeName: string;
  cloudFactor = 1;
  ONAFactor = 1;
  holdBack = 1;
  userDetails: any;
  tooltipValue: any;
  resolutionTooltip: boolean = false;
  expectedAge: any;
  tooltipDisabled: boolean = true;
  detailsText: any = '';
  satCapeEnable: boolean;
  capellaEnable: boolean;
  capellaRecurrent: boolean;
  @Output() childButtonEvent = new EventEmitter();
  @Output() childEvent = new EventEmitter();
  @Output() childDataEvent = new EventEmitter();
  @Output() resetData = new EventEmitter();
  @Output() resetRFData = new EventEmitter();
  @Output() resetSpireData = new EventEmitter();
  selectedItems: any[] = [];
  selectedLatencyKey: any = [];
  demoView: boolean = false;
  OPERATOR: any;
  showColumn: boolean = true;
  selectedSensorList: any[] = [];
  nameONA: string = 'ONA/Look/Grazing Angle';
  spot: boolean = false;
  strip: boolean = false;
  noOfMonths: any = 1;
  feasible: boolean = false;
  collectionTime: boolean = false;
  collectionStart: any;
  collectionEnd: any;
  uniqueNamesData: any = [];
  selectedObservationSensorTypes: string[] = [];
  observationSensorTypeOption: any = [];
  previousSensorType: any = [];
  tnqSensorDisabled: boolean = true;
  tipAndQSelectedRows: [] = [];
  tipAndQSelected: boolean = false;
  selectedTnqSensorType: any;
  getSensorObj: any = [];
  isTipAndCueRule: boolean;
  isTipAndCue: boolean = false;
  captureMode: string;
  darkTheme: boolean = false;
  tooltipImagePath: string;
  qualitySticker: string;
  orbitImage: string;
  maskImage: string;
  cpcAndAllocated: boolean = false;
  advanceSettingsImage: string;
  operatorsEula: any;
  taskPoint: number = 0;
  multiPointsCoveredArea: number = 0;
  showLooks: boolean = false;
  totalTaskNumber = 0;
  dem_calculation = true;
  superResolutions: any = [];
  enable_sr_calculation = true;
  selectedItemsByGroup: { [key: string]: any[] } = {};
  actionProviders = ['DEM', 'DSM', 'DTM'];
  lband: boolean = false;
  lbandOnly: boolean = false;
  singleCircle: any;
  initialSelection: any[] = [];
  rfSignals: boolean = false;
  isDurationChanging: boolean = false;
  columnResolution: string = 'Resolution';
  aisOnly: boolean = true;
  resolutionColumnTooltip: string = 'Image pixel resolution';
  enable_indices_calculation = false;
  @ViewChild('vadpselect') vadpSelect!: MatSelect;
  polarization: any;
  onaTooltip: string;
  private originalClose: (() => void) | null = null; // Correctly typed function
  noOfWeeks: number = 1;
  bandInfoTooltip: any;
  selectedOperator: any;

  constructor(
    private elementRef: ElementRef,
    private authService: AuthService,
    private userService: UserService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private popupService: PopupService,
    private localStorageService: DataService,
    private utilsService: UtilsService,
    private cdRef: ChangeDetectorRef
  ) {
    this.OPERATOR = OPERATORS;
    this.polarization = polarization;
    this.authService.user.subscribe((user) => (this.currentUser = user));
    if (this.currentUser?.id) {
      this.userDetails = this.currentUser;
      this.cpcAndAllocated =
        this.userDetails?.cpc && this.userDetails?.allocated;
    }
    let userSubs = this.userDetails?.subscriptionType || 'standard';
    this.localStorageService.darkTheme.subscribe((newValue) => {
      this.darkTheme = newValue;

      this.tooltipImagePath = this.darkTheme
        ? '../../../assets/images/union-dark.svg'
        : '../../../assets/images/union.svg';
      this.orbitImage = this.darkTheme
        ? '../../../assets/images/orbit-dark.png'
        : '../../../assets/images/orbit.png';
      this.maskImage = this.darkTheme
        ? '../../../assets/images/mask-dark.png'
        : '../../../assets/images/mask.png';
      this.advanceSettingsImage = this.darkTheme
        ? '../../../assets/images/advance-dark.png'
        : '../../../assets/images/advance.png';
        this.qualitySticker = this.darkTheme ? '../../../assets/images/qualitySticker.svg' : '../../../assets/images/qualitySticker.svg';
    });

    this.apiService.taskparams.subscribe((res) => {
      let useCaseId : any;
      
      if(res.taskStrategy !== "Help Select Sensor(s)"){
         useCaseId = null
      }
      else
      useCaseId = res.useCase?._id
      this.collectionTime = false;
      this.multiPointsCoveredArea = 0;
      if (res.geoJSON) {
        this.getSensorObj = {
          rule: res?.rule,
          observation: res?.observation?.observation,
          usecaseId: useCaseId
        };
        let intent = res?.intent?.key ? res?.intent.key : res?.intent;
        
        this.subscription = this.userService
          .getSensors(userSubs, this.currentUser?.id, intent, this.getSensorObj)
          .subscribe(async (result: any) => {
            this.rfSignals = false;
            if (result) {
              if (this.target.multiTarget?.length > 2) {
                this.multiPointsCoveredArea = this.utilsService.calculateArea(this.target.multiTarget);
              }

              if (result[0].operators) {
                const allocatedSensorTypes = Array.from(new Set(result[0].operators.map(item => item.sensortype)));
                this.SensorsTypes = this.apiService.SENSORSTYPE.filter(sensor => allocatedSensorTypes.includes(sensor.value));
              }

              this.SENSORS = result[0].operators;
              this.noOfMonths = 1;
              let startdate = moment(this.target?.openWindow)
                .utcOffset(0, true)
                .format();
              let enddate = moment(this.target?.closeWindow)
                .utcOffset(0, true)
                .format();
              let time_difference =
                new Date(enddate).getTime() - new Date(startdate).getTime();
              let days_difference = Math.ceil(
                time_difference / (1000 * 60 * 60 * 24)
              );
              this.noOfMonths = Math.round(days_difference / 30);
              this.noOfWeeks = Math.round(days_difference / 7);

              this.childEvent.emit();
              this.resetData.emit();
              this.target = res;

              this.chkTaskInput(this.target);
              let selectedIndx = -1;
              if (this.SENSORS && this.target.taskStrategy === 'Tip-and-Cue') {
                this.isTipAndCue = true;
                this.isTipAndCueRule =
                  this.target.tipAndCueBasedOn === 'Rules' &&
                  this.target.rule === 'rule-1'
                    ? true
                    : false;
                if (this.isTipAndCueRule) {
                  this.SENSORS.forEach((sensor) => {
                    if (sensor.ruleChecked) {
                      this.selectedSensorTypes += sensor.sensortype + '/';
                    }
                  });
                } else {
                  this.selectedSensorTypes = '';
                  this.isTipAndCueRule = false;
                }
              }
              this.SensorsTypes.forEach((type, index) => {
                if (this.selectedSensorTypes) {
                  if (this.selectedSensorTypes.includes(type.value)) {
                    selectedIndx = index;
                    this.SensorsTypes[index].selected = true;
                  }
                } else {
                  this.SensorsTypes[index].selected = false;
                }
              });
              this.dataSource = undefined;
              this.FILTEREDSENSORS = [];
              if (this.SENSORS) {
                this.SENSORS.forEach((sensor) => {
                  if (sensor.key === OPERATORS.STE && sensor.sensor === 'SAR' && this.target.area > 0) {
                    const order = {
                      "PSTRIP": ["L1", "L3"],
                      "PSPOT": ["L1", "L3"]
                    };

                    let reversedMode = sensor.collection_mode.sort((a, b) => {
                      if (a.captureMode !== b.captureMode) {
                        // Prioritize "Strip" over "Spot"
                        return a.captureMode === "Strip" ? -1 : 1;
                      }
                      if (a.mode !== b.mode) {
                        return a.mode.localeCompare(b.mode);
                      }
                      return order[a.mode].indexOf(a.productLevel) - order[b.mode].indexOf(b.productLevel);
                    });
                  } else if (sensor.key === OPERATORS.CAPELLA && this.target.area > 0 && sensor.collection_mode[0].captureMode === 'Strip') {
                    sensor.collection_mode.reverse();
                  }
                  this.FILTEREDSENSORS.push(sensor);
                });
              }
              this.dataSource = undefined;
              this.AVAILABLESENSORS = [];
              this.selectedSensors = [];
              if (selectedIndx > -1)
                this.updateAllComplete(
                  this.SensorsTypes[selectedIndx],
                  selectedIndx
                );
              if (this.FILTEREDSENSORS.length > 0) this.initializeData();
            }
          });
      }
    });
  }

  ngOndestroy() {
    this.elementRef.nativeElement.remove();
  }

  ngOnInit(): void {
    this.errorMsg = undefined;
    this.currency = environment.payCurrency;
    this.currencySign = environment.payCurrencySign;
    this.SensorsTypes = this.apiService.SENSORSTYPE;
    if(this.currentUser.allocated) {
      this.SensorsTypes = this.apiService.SENSORSTYPE.filter(sensor => sensor.name !== 'AIS');
    }
    this.observationSensorTypeOption = this.apiService.observationSensorType;
    this.totalAmount = 0;
    if (environment.demoView === 'true') {
      this.demoView = true;
    } else {
      this.demoView = false;
    }
  }

  chkTaskInput(newtask: any) {
    let project: any = newtask.project || undefined;
    let taskname: any = newtask.taskname || undefined;
    let geoJson: any = JSON.stringify(newtask.geoJSON) || undefined;
    const err: any = [];
    if (!project) err.push('Project Name');
    if (!taskname) err.push('Task Name');
    if (!geoJson) err.push('Target POI/AOI');
    if (project && taskname && geoJson.includes('P')) {
      this.valid = true;
    } else {
      this.valid = false;
    }
    this.errorMsg = 'Please check Tasking Details for valid ' + err.join(', ');
  }

  async initializeData() {
    this.satCapeEnable = false;
    this.capellaEnable = false;

    if (
      this.target.selectedOption === 1 &&
      this.target?.repeatOption > 3 &&
      this.target?.repeatOption < 7 &&
      this.target?.subDailyCount > 1
    ) {
      this.satCapeEnable = true;
    } else if (
      this.target.selectedOption === 1 &&
      this.target?.repeatOption === 7
    ) {
      this.capellaEnable = true;
    }

    let i = 0,
      areacalc = 0,
      minArea = 0;
    this.tooltip = [];
    const targetArea = this.target.area;
    let selectedTaskingPrice: any, selectedHistoricalPrice: any;

    this.FILTEREDSENSORS.forEach(async (sensor: any, i) => {
      this.selectedLatency[i] = sensor?.latency[0]?.name;
      this.selectedLatencyKey[i] = sensor?.latency[0];
      if (sensor.name) {
        if (targetArea === 0 || (sensor?.key === OPERATORS.SATELLOGIC && sensor?.collection_mode?.length === 1)) {
          if (targetArea < sensor.minareaspot)
            this.totalAOI = sensor.minareaspot;
          minArea = sensor.minareaspot;
        } else {
          if (targetArea < sensor.minareastrip)
            this.totalAOI = sensor.minareastrip;
          minArea = sensor.minareastrip;
        }

        if (sensor.areacalc) {
          areacalc = sensor.areacalc;
        } else {
          areacalc = 0;
        }
        const cloudC: any = [];
        for (var key in sensor.cloudcover) {
          cloudC.push({ key, value: sensor.cloudcover[key] });
        }

        let cloudcovers: any,
          selectedModes: any = [],
          modeSelected: any = [];
        let SZA: any,
          AC: any,
          selectedHoldBackOption: any,
          selectedCC: any,
          cloudcover: any;
        let defaultAzimuthAngleMax: any,
          defaultAzimuthAngleMin: any,
          defaultPolarization: any;

        if (sensor?.cloudcover) {
          cloudcovers = cloudC;
          cloudcover = cloudC[0].key;
          selectedCC = cloudC[0].key;
        }

        let cloudcovernew: any, defaultMinTooltip: any, defaultMinArea: any;
        if (sensor?.cloudcover) cloudcovernew = sensor.cloudcover;
        let collectmodes: any;
        if (sensor.collectmodes) collectmodes = sensor.collectmodes;
        if (sensor?.resolution) {
          var selectedResolution = sensor?.resolution[0];

          if((sensor?.key === OPERATORS.AT21 || sensor?.key === OPERATORS.GHG || sensor?.key === OPERATORS.ECURS) && sensor.resolution?.length > 1) {
            sensor.resolution.forEach((res, index) => {
              res.disable = index !== 0; // Set disable = true for all except the first element
            });
          }
          if ((sensor?.key === OPERATORS.STE && sensor.resolution?.length > 1 && sensor?.sensor === 'SAR' && this.target.area === 0) || 
          (sensor?.key === OPERATORS.ISI && sensor.resolution?.length > 1) || (sensor?.key === OPERATORS.CAPELLA && sensor.resolution?.length > 1)) {
            sensor.resolution[1].disable = true;
            sensor.resolution[0].disable = false;
          }

          if ((sensor?.key === OPERATORS.STE && sensor.resolution?.length > 1 && sensor?.sensor === 'SAR' && this.target.area > 0)) {
            sensor.resolution[0].disable = true;
            selectedResolution = sensor.resolution[1];
          }

          if ((sensor?.key === OPERATORS.SYNSPECTIVE && sensor.resolution?.length > 1 && sensor?.sensor === 'SAR')) {
            sensor.resolution[0].disable = true;
            selectedResolution = sensor.resolution[1];
            sensor.resolution[1].disable = false;
          }

        }
        if (sensor?.collection_mode?.length > 0) {
          selectedModes.push(sensor?.collection_mode[0]);
          modeSelected.push(sensor?.collection_mode[0]);
          if (
            sensor?.key === OPERATORS.BLACKSKY &&
            selectedModes[0].sceneSize
          ) {
            minArea = selectedModes[0].sceneSize;
          }

          if (this.target.area > 0 && sensor?.key === OPERATORS.SATELLOGIC && 
            sensor?.collection_mode?.length > 1) {
            selectedModes = [];
            modeSelected = [];
            selectedModes.push(sensor?.collection_mode[1]);
            modeSelected.push(sensor?.collection_mode[1]);
            defaultMinTooltip = '';
            defaultMinArea = sensor?.collection_mode[1]?.value;
          } else if (
            this.target.area > 0 &&
            sensor?.key === OPERATORS.KOMPSAT &&
            sensor?.sensor === 'MSI'
          ) {
            selectedModes = [];
            modeSelected = [];
            selectedModes.push(sensor?.collection_mode[3]);
            modeSelected.push(sensor?.collection_mode[3]);
            defaultMinTooltip = sensor?.collection_mode[3]?.minTooltip;
            defaultMinArea = sensor?.collection_mode[3]?.value;
          } else if (
            sensor?.key === OPERATORS.GHG &&
            (this.target?.multiTarget?.length > 2 && this.multiPointsCoveredArea <= 144)
          ) {
            selectedModes = [];
            modeSelected = [];
            selectedModes.push(sensor?.collection_mode[1]);
            modeSelected.push(sensor?.collection_mode[1]);
            defaultMinTooltip = sensor?.collection_mode[1]?.minTooltip;
            defaultMinArea = sensor?.collection_mode[1]?.value;
          } else {
            defaultMinArea = sensor?.collection_mode[0]?.value;
            defaultMinTooltip = sensor?.collection_mode[0]?.minTooltip;
          }
        }

        if (sensor?.key === 'STE' && sensor.sensor != 'SAR') {
          if (this.target.selectedOption === 0) {
            cloudcover = '';
            selectedCC = '';
          }
        } else if (
          this.isTipAndCueRule &&
          sensor?.key === OPERATORS.SATELLOGIC
        ) {
          cloudcover = '';
          selectedCC = '';
        }

        if (sensor?.ONA?.length > 0 || sensor?.onaWithResolution?.length > 0  ) {
         
        //  var selectedONAOption = sensor?.onaWithResolution ?  sensor?.onaWithResolution[0]:sensor?.ONA[0];
          let filteredSensors =sensor?.onaWithResolution? sensor?.onaWithResolution?.filter((item: any) => item.default === true):sensor?.ONA;
         
         var selectedONAOption = sensor?.onaWithResolution ?  filteredSensors[0]:sensor?.ONA[0];
         var uniqueOnaArray =sensor?.onaWithResolution? this.getUniqueOnaArray( sensor?.onaWithResolution):sensor.ONA;
     
          if(selectedONAOption && !selectedONAOption?.key && sensor?.onaWithResolution){
            selectedONAOption.key=selectedONAOption?.ona
            sensor.selectedONA =selectedONAOption.ona?selectedONAOption?.ona:selectedONAOption?.key
            selectedONAOption.value =selectedONAOption.onaUplift? selectedONAOption?.onaUplift:selectedONAOption?.value;
            sensor.selectedONAOption = selectedONAOption;
            sensor.ONAFactor =  selectedONAOption.value ;
          
         if( sensor.resolution.length<2 && sensor?.onaWithResolution)
          if(selectedONAOption?.resolution ){
         
            let newResoution:any= {key:"0",value:selectedONAOption.resolution,originalResolution :selectedONAOption.forResolution}
            if(selectedONAOption?.mode_value){
              newResoution.mode_value= selectedONAOption?.mode_value;
            }
            sensor.resolution =[newResoution];
            sensor.selectedResolution =  newResoution
          }
           if( sensor.resolution.length > 1 && sensor?.onaWithResolution){
            const resolutionArray = this.createResolutionArray(sensor?.onaWithResolution,  sensor.selectedONA,sensor.resolution);
            sensor.resolution = resolutionArray;
            selectedResolution = resolutionArray[0];
          }
        }
          
        }
        
        if (sensor?.SZA?.length > 0) {
          SZA = sensor?.SZA[0];
        }
        if (sensor?.AC?.length > 0) {
          AC = sensor?.AC[0];
        }

        if (sensor?.holdback?.length > 0) {
          selectedHoldBackOption = sensor?.holdback[0];
        }

        if (sensor?.key === OPERATORS.UMBRA) {
          defaultAzimuthAngleMax = sensor?.targetAzimuthAngleMax;
          defaultAzimuthAngleMin = sensor?.targetAzimuthAngleMin;
          defaultPolarization = sensor?.sarPolarization[0].value;
        }

        if (sensor?.key === OPERATORS.LBAND) {
          for (let i = 0; i < sensor.signal_of_interest.length; i++) {
            sensor.signal_of_interest[i].isAudio = false;
          }
        }

        const deliveytime_stand = new Date().setDate(
          new Date().getDate() + sensor.latency_standard
        );
        const defaultLatency = [],
          latencySTE: any = [],
          latency: any = [];
        let unitcost = 0;
        if (sensor?.key === 'Capella') {
          if (
            this.target.freqOption === 'Weekly' ||
            this.target.freqOption === 'Monthly'
          )
            this.capellaRecurrent = true;
          else this.capellaRecurrent = false;
        }

        if (sensor?.key === OPERATORS.CLYDE) {
          let totalAOIInMillion = targetArea / 1000000;

          let selectedKey = Object.keys(sensor?.pricing).find(
            (key) => totalAOIInMillion <= Number(key)
          );
          if (!selectedKey) {
            selectedKey = Object.keys(sensor.pricing).sort(
              (a: any, b: any) => b - a
            )[0];
          }

          let selectedhistoricalKey = Object.keys(sensor?.historicalPricing).find(
            (key) => totalAOIInMillion <= Number(key)
          );
          if (!selectedhistoricalKey) {
            selectedhistoricalKey = Object.keys(sensor.historicalPricing).sort(
              (a: any, b: any) => b - a
            )[0];
          }
      
          selectedTaskingPrice = {
            data: {
              selectedKey,
              price: sensor.pricing[selectedKey],
            },
          };
          
          selectedHistoricalPrice = {
            data: {
              selectedhistoricalKey,
              price: sensor.historicalPricing[selectedhistoricalKey],
            },
          }; 
         }

        for (let j = 0; j < sensor.latency.length; j++) {
          let selected = false;
          if (j === 0) selected = true;
          if (sensor.latency[j]?.key === 'RR' && sensor?.key === OPERATORS.SATELLOGIC && this.target?.openWater) {
            selected = true;
            this.selectedLatency[i] = sensor.latency[j]?.name;
            this.selectedLatencyKey[i] = sensor.latency[j];
          } else if (
            sensor?.key === OPERATORS.CAPELLA &&
            sensor.latency[j]?.key === 'recurrent' &&
            this.target.selectedOption === 1
          ) {
            selected = true;
            this.selectedLatency[i] = 'recurrent';
            this.selectedLatencyKey[i] = sensor.latency[j];
          }
          else if (sensor?.key === 'STE' && sensor.latency[j]?.key === 'standard' && this.target.selectedOption != 0) {
            selected = true;
            this.selectedLatency[i] = 'standard';
            this.selectedLatencyKey[i] = sensor.latency[j];
          }

          else if (sensor?.key === OPERATORS.GOKTURK && sensor.latency[j]?.key === 'priority' && this.target.selectedOption != 0) {
            selected = true;
            this.selectedLatency[i] = 'priority';
            this.selectedLatencyKey[i] = sensor.latency[j];
          }

          var calCost = sensor.latency[j]?.unitcost;
          var unitCost = sensor.latency[j]?.unitcost;
          if (sensor?.key === OPERATORS.UMBRA) {
            if (sensor.resolution) {
              var calCost = sensor.resolution[0]?.cost;
              var unitCost = sensor.resolution[0]?.cost;
            }
          }

          if (sensor?.key === OPERATORS.CLYDE) {
            var calCost = selectedTaskingPrice.data.price;
            var unitCost = selectedTaskingPrice.data.price;         
           }

           if (sensor?.key === OPERATORS.AXELSPACE && this.currentUser.id != CONFIG_VALUES.AxelCustomerId) {
            let unitPrice: any = this.getAxelPrice(targetArea, sensor.pricing);
            var calCost = unitPrice;
            var unitCost = unitPrice;
          }

          if (sensor.latency[j]?.mode) var mode = sensor.latency[j]?.mode;
          else var mode: any = '<=';
          if (this.currentUser?.channelPrice) {
            var calCost = sensor.channelPrice[sensor.latency[j]]?.unitcost;
            var unitCost = sensor.channelPrice[sensor.latency[j]]?.unitcost;
          }

          latency.push({
            name: sensor.latency[j]?.name,
            unitcost: unitCost,
            cost: calCost,
            latency: sensor.latency[j]?.latency,
            selected: selected,
            mode: mode,
            sensor: sensor.name,
            taskingwindow: sensor.latency[j]?.taskingwindow,
            key: sensor.latency[j]?.key,
          });
        }

        let STElatency: any = [];
        if (sensor?.key === OPERATORS.STE) {
          latencySTE.push(latency);
          STElatency = JSON.parse(JSON.stringify(latencySTE));
        }

        let taskcost;
        const existId = this.AVAILABLESENSORS
          .filter(function (el) {
            return  sensor?.sensorId === el?.sensorId ;
          })
        const satIds = sensor?.satIds ? sensor?.satIds.map(obj => obj.id) : [];
        
        if (existId.length === 0) {
          if (this.selectedSensorTypes && sensor?.name != 'TripleSat') {
            if (sensor.enabled) {
              const tooltip = sensor;
              this.AVAILABLESENSORS.push({
                name: sensor.name,
                resolution: sensor.resolution,
                unit: sensor.costunit,
                type: sensor?.type,
                unitcost: unitcost,
                sensor: sensor.sensor,
                sensortype: sensor.sensortype,
                minarea: minArea,
                areacalc: areacalc,
                area_calculated: this.totalAOI,
                tooltip: tooltip,
                cloudcovers: cloudcovers,
                cloudcover: cloudcover,
                cloudnew: cloudcovernew,
                collectmodes: collectmodes,
                defaultLatency: defaultLatency,
                latency: latency,
                selectedCost: taskcost,
                selectedCC: selectedCC,
                selectedLatency: this.selectedLatency[i],
                selectedLatencyKey: this.selectedLatencyKey[i],
                cost: taskcost,
                enabled: sensor.enabled,
                ONA: uniqueOnaArray ? uniqueOnaArray:sensor.ONA,
                holdback: sensor.holdback,
                collection_mode: sensor.collection_mode,
                country: sensor.country,
                selectedModes: selectedModes,
                selectedONAOption: selectedONAOption,
                sensorTooltip: sensor.tooltip,
                sensorDesc: sensor?.sensordesc,
                rushCloudCover: false,
                key: sensor?.key,
                operatorEmail: sensor?.operatorEmail,
                modeType: sensor?.multiplemode,
                modeSelected: modeSelected,
                satIds: satIds,
                SZA: SZA,
                AC: AC,
                selectedResolution: selectedResolution,
                sarPolarization: sensor?.sarPolarization,
                sarOrbitDirection: sensor?.sarOrbitDirection,
                productAnciallary: sensor?.productAnciallary,
                satelliteName: sensor?.satelliteName,
                collectionCost: sensor?.collection_cost,
                selectedHoldBackOption: selectedHoldBackOption,
                feasibility: sensor?.feasibility,
                tooltipValue: defaultMinTooltip,
                selectedAnciallary: '',
                selectedOrbitDirection: '',
                selectedPolarization: '',
                siteData: sensor?.siteData,
                collectionunit: sensor?.collectionunit,
                selectedTnqSensorType: '',
                tnqSensorDisabled: true,
                minimumArea: defaultMinArea,
                captureMode: '',
                STElatency: STElatency,
                defaultPolarization: defaultPolarization,
                defaultAzimuthAngleMax: defaultAzimuthAngleMax,
                defaultAzimuthAngleMin: defaultAzimuthAngleMin,
                pricing: sensor?.pricing,
                cancelPolicy: sensor?.cancel_policy,
                onaWithResolution: sensor?.onaWithResolution,
                imageryname: sensor?.imageryname,
                selectedTaskingPrice: selectedTaskingPrice,
                selectedHistoricalPrice: selectedHistoricalPrice,
                sensorId:sensor?.sensorId,
                valAddedArray: sensor.valueAddedOption,
                valueAddedAction: this.formatvalueAddedOption(sensor, selectedModes, selectedResolution),
                superResolutions: this.setSuperResolutions(sensor),
                signal_of_interest: this.setSignalOfInterest(sensor?.signal_of_interest),
                collection_duration: sensor?.collection_duration,
                updateFrequency: sensor?.updateFrequency,
                indicesArray: this.setIndices(sensor),
                no_imagery_country: sensor?.no_imagery_country,
                obfuscateName: sensor?.obfuscateName,
                displayName: (this.currentUser.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor.name,
                sarOrbitType: sensor?.sarOrbitType,
              });

            } else {
              const tooltip = {
                name: sensor.name,
                resolution: sensor.resolution,
                unit: sensor.costunit,
                type: sensor?.type,
                unitcost: sensor.unitcost,
                sensor: sensor.sensor,
                sensortype: sensor.sensortype,
                sensorTooltip: sensor.tooltip,
                sensorDesc: sensor?.sensordesc,
              };
              this.AVAILABLESENSORS.push({
                name: sensor.name,
                resolution: sensor.resolution,
                sensor: sensor.sensor,
                sensortype: sensor.sensortype,
                tooltip: tooltip,
                enabled: sensor.enabled,
                sensorTooltip: sensor.tooltip,
                sensorDesc: sensor?.sensordesc,
                selectedModes: selectedModes,
                collection_mode: sensor.collection_mode,
                selectedONAOption: selectedONAOption,
                type: sensor?.type,
                satIds: satIds,
                key: sensor?.key,
                operatorEmail: sensor?.operatorEmail,
                modeType: sensor?.multiplemode,
                selectedLatency: this.selectedLatency[i],
                selectedLatencyKey: this.selectedLatencyKey[i],
                modeSelected: modeSelected,
                SZA: SZA,
                AC: AC,
                selectedResolution: selectedResolution,
                sarPolarization: sensor?.sarPolarization,
                sarOrbitDirection: sensor?.sarOrbitDirection,
                productAnciallary: sensor?.productAnciallary,
                satelliteName: sensor?.satelliteName,
                selectedHoldBackOption: selectedHoldBackOption,
                feasibility: sensor?.feasibility,
                tooltipValue: defaultMinTooltip,
                selectedAnciallary: '',
                selectedOrbitDirection: '',
                selectedPolarization: '',
                siteData: sensor?.siteData,
                collectionunit: sensor?.collectionunit,
                minimumArea: defaultMinArea,
                STElatency: STElatency,
                defaultPolarization: defaultPolarization,
                defaultAzimuthAngleMax: defaultAzimuthAngleMax,
                defaultAzimuthAngleMin: defaultAzimuthAngleMin,
                pricing: sensor?.pricing,
                cancelPolicy: sensor?.cancel_policy,
                imageryname: sensor?.imageryname,
                selectedTaskingPrice: selectedTaskingPrice,
                selectedHistoricalPrice: selectedHistoricalPrice,
                sensorId:sensor?.sensorId,
                valAddedArray: sensor.valueAddedOption,
                valueAddedAction: this.formatvalueAddedOption(sensor, selectedModes, selectedResolution),
                superResolutions: this.setSuperResolutions(sensor),
                updateFrequency: sensor?.updateFrequency,
                no_imagery_country: sensor?.no_imagery_country,
                obfuscateName: sensor?.obfuscateName,
                displayName: (this.currentUser.obfuscated && sensor.obfuscateName) ? sensor.obfuscateName : sensor.name,
                sarOrbitType: sensor?.sarOrbitType,
              });
            }
          }
        }
        this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
        this.dataSource.sort = this.sort;

        for (const [sensorIndex, sensors] of this.AVAILABLESENSORS.entries()) {
          if (sensors?.key === OPERATORS.GHG) {
            sensors.latency = sensors.latency.map(latencyItem => {
              const matchingMode = sensors.selectedModes[0].cost.find(mode => mode[latencyItem.key] !== undefined);
              return {
                ...latencyItem,
                cost: matchingMode ? matchingMode[latencyItem.key] : null,
                unitcost: matchingMode ? matchingMode[latencyItem.key] : null,
              };
            });
          }
          if (sensors?.key === OPERATORS.CAPELLA || sensors?.key === OPERATORS.GOKTURK || sensor?.key === OPERATORS.BLACKSKY || (sensors?.key === OPERATORS.STE && sensors?.sensor === 'MSI'
            && sensors.selectedResolution.value.includes('Bundle')
          ) || sensors?.key === OPERATORS.ECURS) {
            let pricingArray= sensors.selectedModes[0]?.pricing;
            for (const latencyItem of sensors.latency) {
              const key = latencyItem.key;
              if (pricingArray && pricingArray.hasOwnProperty(key)) {
                const newCost = Number(pricingArray[key]);
                latencyItem.cost = newCost;
                latencyItem.unitcost = newCost;
              }
            }
          }

          if (sensors?.key === OPERATORS.USL) {
            let priceArray = sensor?.collection_cost;            
            let subdailyCount = this.target.subDailyCount || 1;
            if (priceArray) {
              for (const latencyItem of sensors.latency) {
                for (const pricingItem of priceArray) {
                  if (
                    latencyItem.key === pricingItem.latency &&
                    pricingItem.unit === subdailyCount
                  ) {
                    latencyItem.cost = Number(pricingItem.cost);
                    latencyItem.unitcost = Number(pricingItem.cost);
                    break;
                  }
                }
              }              
            }                       
          }
          this.taskingOption(sensors.selectedLatency, sensorIndex, sensors, 1);
        }
      }
    });
   
  }
  getTextLength(captureMode: string, productLevel: string): number {
    const captureModeLength = captureMode ? captureMode.length : 0;
    const productLevelLength = productLevel ? productLevel.length : 0;
    return captureModeLength + (productLevel ? productLevelLength + 3 : 0); // Adding 3 for ' ()'
  }
  
  createResolutionArray(data, selectedOna,resolution) {
    // Initialize the result array and a counter for the auto-increment key
    let result :any= [];
    let counter = 1;

    // Loop through the original data
    data?.forEach((item, index) => {
        // Check if the current item's 'ona' matches the selected 'ona'
        if (item.ona === selectedOna) {
          const matchingResolution = resolution.find(
            (res) => (res.value === item.forResolution || res?.originalResolution === item.forResolution)
          );
          
            // Push the new object with auto-increment key and forResolution value to the result array
            result.push({ key: counter, value: item.resolution , order:item.order,originalResolution :item.forResolution, onaUplift:item.onaUplift,
              bands: item?.bands, multiplier: matchingResolution?.multiplier, combinedResolution: item?.combinedResolution ? item?.combinedResolution : matchingResolution?.combinedResolution
            });
            // Increment the counter
            counter++;
        }
    });
   
    result.sort((a, b) => a.order - b.order);
    if (result?.length > 1) {
      result[1].disable = true;
    }
    return result;
}
getUniqueOnaArray(data:any) {
  // Initialize an empty Set to keep track of unique 'ona' values
  const uniqueOna = new Set();
  // Initialize the result array
  const result:any = [];

  // Loop through the original data
  data.forEach(item => {
      // If the 'ona' value is not in the Set, add it to the Set and the result array
      if (!uniqueOna.has(item.ona)) {
          uniqueOna.add(item.ona);
          result.push(item);
      }
  });
  result.sort((a, b) => b.default - a.default);
  return result;
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }
  updateAllComplete(e: any, i: number) {
    this.nameONA = 'ONA/Look/Grazing Angle';
    this.showColumn = true;
    this.lbandOnly = false;
    this.columnResolution = 'Resolution';
    this.aisOnly = true;
    this.resolutionColumnTooltip = 'Image pixel resolution';
    this.onaTooltip = 'ONA: Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.' +'\n' +
    'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' +'\n' +
    'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.'
    ;

    this.displayedColumns = [
      'operator',
      'band',
      'preview',
      'mode',
      'resolution',
      'area_calculated',
      'cloudcovers',
      'ONA',
      'unitcost',
      'holdback',
      'advance',
      'cost',
      'opportunity',
      'footprint',
      'clientInfo',
      'eula',
    ];

    if (e.selected) {
      this.selectedSensorList.push(e?.name);
    } else {
      const index = this.selectedSensorList.findIndex(
        (selectedItem) => selectedItem === e.name
      );
      this.selectedSensorList.splice(index, 1);

      this.AVAILABLESENSORS.forEach((operator) => {
        if (operator.checked && operator.key === OPERATORS.LBAND && e.name === 'RF- comms') {          
          this.resetRFData.emit();
        } else if (operator.checked && operator.key === OPERATORS.SPIRE && e.name === 'AIS') {
          this.resetSpireData.emit();
        }
      });
    }

    if (
      this.selectedSensorList?.length === 1 &&
      this.selectedSensorList[0] === 'MSI'
    ) {
      this.nameONA = 'ONA';
      this.onaTooltip = 'Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.';
    }

    if (this.target.taskStrategy === 'Tip-and-Cue') {
      this.displayedColumns = [
        'operator',
        'band',
        'preview',
        'observationSensorType',
        'mode',
        'resolution',
        'area_calculated',
        'cloudcovers',
        'ONA',
        'unitcost',
        'holdback',
        'advance',
        'cost',
        'opportunity',
        'footprint',
        'clientInfo',
        'eula',
      ];
    } else if (
      this.selectedSensorList?.length === 1 &&
      (this.selectedSensorList.includes('SAR') ||
        this.selectedSensorList.includes('InSAR')) &&
      this?.target.rule != 'rule-1'
    ) {
      this.nameONA = 'Look/Grazing Angle';
      this.onaTooltip = 'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' +'\n' +
      'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.';
      this.showColumn = false;
      if (this.target.taskStrategy === 'Tip-and-Cue') {
        this.displayedColumns = [
          'operator',
          'band',
          'preview',
          'observationSensorType',
          'mode',
          'resolution',
          'area_calculated',
          'ONA',
          'unitcost',
          'holdback',
          'advance',
          'cost',
          'opportunity',
          'footprint',
          'clientInfo',
          'eula',
        ];
      } else {
          this.displayedColumns = ['operator', 'band', 'preview', 'mode', 'resolution', 'area_calculated',
            'ONA', 'unitcost', 'holdback', 'advance', 'cost', 'opportunity', 'footprint', 'clientInfo', 'eula'];
        }
    } else if (
      this.selectedSensorList?.length === 2 &&
      this.selectedSensorList.includes('SAR') &&
      this.selectedSensorList.includes('InSAR') &&
      this?.target.rule != 'rule-1'
    ) {
      this.nameONA = 'Look/Grazing Angle';
      this.onaTooltip = 'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' +'\n' +
      'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.';
      this.showColumn = false;
      if (this.target.taskStrategy === 'Tip-and-Cue') {
        this.displayedColumns = [
          'operator',
          'band',
          'preview',
          'observationSensorType',
          'mode',
          'resolution',
          'area_calculated',
          'ONA',
          'unitcost',
          'holdback',
          'advance',
          'cost',
          'opportunity',
          'footprint',
          'clientInfo',
          'eula',
        ];
      } else {
          this.displayedColumns = ['operator', 'band', 'preview', 'mode', 'resolution', 'area_calculated',
            'ONA', 'unitcost', 'holdback', 'advance', 'cost', 'opportunity', 'footprint','clientInfo', 'eula'];
        }
    } else if (
      this.selectedSensorList.includes('SAR') ||
      this.selectedSensorList.includes('InSAR') ||
      this?.target.rule === 'rule-1'
    ) {
      this.nameONA = 'ONA/Look/Grazing Angle';
      this.onaTooltip = 'ONA: Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.' +'\n' +
      'Look Angle: The angle between the sensor’s line of sight and the Earth’s surface’s vertical direction (nadir).' +'\n' +
      'Grazing Angle: The angle between the sensor beam’s contact angle and the Earth’s surface.';
      this.showColumn = true;
      if (this.target.taskStrategy === 'Tip-and-Cue') {
        this.displayedColumns = [
          'operator',
          'band',
          'preview',
          'observationSensorType',
          'mode',
          'resolution',
          'area_calculated',
          'cloudcovers',
          'ONA',
          'unitcost',
          'holdback',
          'advance',
          'cost',
          'opportunity',
          'footprint',
          'clientInfo',
          'eula',
        ];
      } else {
          this.displayedColumns = ['operator', 'band', 'preview', 'mode', 'resolution', 'area_calculated', 'cloudcovers',
            'ONA', 'unitcost', 'holdback', 'advance', 'cost', 'opportunity', 'footprint', 'clientInfo', 'eula'];
        }
    }  
    else  {
      this.nameONA = 'ONA';
      this.onaTooltip = 'Off Nadir Angle is the degree or range to which the camera onboard a satellite can be tilted while imaging to capture a large area.';
    }

    if(((this.selectedSensorList.includes('Stereo') && this.target.selectedOption !== 1) 
      || this.selectedSensorList.includes('MSI')) 
      && (!this.currentUser.allocated && this.target?.taskStrategy === 'User Initiated')) {
        
      const modifiedColumns = [...this.displayedColumns];
      const modeIndex = modifiedColumns.indexOf('mode');
      modifiedColumns.splice(modeIndex + 1, 0, 'dem');
      this.displayedColumns = modifiedColumns;
    }

    if (this.selectedSensorList.includes('SAR')) {
      this.showLooks = true;
      const resolutionIndex = this.displayedColumns.indexOf('resolution');
      if (resolutionIndex !== -1) {
        this.displayedColumns.splice(resolutionIndex + 1, 0, 'looks');
      }
    }

    if (this.selectedSensorList.includes('RF- comms')) {
      this.lband = true;
      const resolutionIndex = this.displayedColumns.indexOf('resolution');
      if (resolutionIndex !== -1) {
        this.displayedColumns.splice(resolutionIndex + 1, 0, 'signalOfInterest');
      }
    }

    if (this.selectedSensorList.includes('AIS')) {
     if (this.selectedSensorList?.length === 1) {
      this.columnResolution = 'Update Frequency';
      this.resolutionColumnTooltip = 'Signal update frequency';
     }
     else {
      this.aisOnly = false;
      this.columnResolution = 'Resolution/Update Frequency';
      this.resolutionColumnTooltip = 'Image pixel resolution/ signal update frequency';
     }
    }

    this.selectedSensorTypes = '';
    this.FILTEREDSENSORS = [];

    this.SensorsTypes.forEach((type, index) => {
      if (this.SensorsTypes[index].selected === true) {
        this.selectedSensorTypes += type.value + '/';
        this.SensorsTypes[i].selected = e.selected;

        this.SENSORS.forEach((sensor) => {
          if (type.value === sensor.sensortype) {
            const existId = this.FILTEREDSENSORS.findIndex(
              (ele) => JSON.stringify(ele) === JSON.stringify(sensor)
            );
            if (existId === -1) {
              if (this.isAllSensors) {
                this.FILTEREDSENSORS.push(sensor);
              } else {
                if (
                  sensor.sensortype === 'MSI' &&
                  sensor.enabled
                ) {
                  this.FILTEREDSENSORS.push(sensor);
                } else if (sensor.enabled && sensor.sensortype != 'MSI')
                  this.FILTEREDSENSORS.push(sensor);
              }
            }
          }
        });
      }
    });
    this.initializeData();
    if (!e.selected) {
      const AVAILABLESENSORSFilter = this.AVAILABLESENSORS.filter(
        (el) => e.value !== el.sensortype
      );
      this.AVAILABLESENSORS = AVAILABLESENSORSFilter;
      this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
      this.dataSource.sort = this.sort;
      this.selectedSensors = [];
      this.AVAILABLESENSORS.forEach((operator) => {
        if (operator.checked) this.selectedSensors.push(operator);
      });
    }

    if (this.FILTEREDSENSORS.length === 0) {
      this.dataSource = undefined;

      // this.dataSource = new MatTableDataSource([]);
    } else {
      this.FILTEREDSENSORS.sort(function (a, b) {
        return -(a.enabled - b.enabled);
      });
    }
    this.clickHandler();
  }
  toggleAll() {
    this.FILTEREDSENSORS = [];
    this.SensorsTypes.forEach((type, index) => {
      if (this.SensorsTypes[index].selected === true) {
        this.selectedSensorTypes += type.value + '/';
        this.SENSORS.forEach((sensor) => {
          if (type.value === sensor.sensortype) {
            if (!this.isAllSensors) {
              if (sensor.sensortype === 'MSI') {
                this.FILTEREDSENSORS.push(sensor);
              } else if (sensor.sensortype != 'MSI')
                this.FILTEREDSENSORS.push(sensor);
            } else {
              if (
                sensor.sensortype === 'MSI' &&
                sensor.enabled
              ) {
                this.FILTEREDSENSORS.push(sensor);
              } else if (sensor.enabled && sensor.sensortype != 'MSI')
                this.FILTEREDSENSORS.push(sensor);
            }
          }
        });
      }
    });
    this.FILTEREDSENSORS.sort(function (a, b) {
      return -(a.enabled - b.enabled);
    });
    this.initializeData();
    if (this.isAllSensors) {
      const AVAILABLESENSORSFilter = this.AVAILABLESENSORS.filter(
        (el) => el.enabled === true
      );
      this.AVAILABLESENSORS = AVAILABLESENSORSFilter;
      this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
      this.dataSource.sort = this.sort;
    }
  }

  toggleNightSensors() {
    this.resetSorting();
    this.FILTEREDSENSORS = [];
    this.AVAILABLESENSORS = [];
    this.selectedSensors = [];
    this.selectedSensorTypes = '';
    this.SENSORS.forEach((sensor) => {
      if (sensor.nightvision) {
        this.FILTEREDSENSORS.push(sensor);
      }
    });
    this.SensorsTypes.forEach((type, index) => {
      if (this.SensorsTypes[index].selected === true) {
        this.SensorsTypes[index].selected = false;
      }
    });
    if (!this.isNightSensor) {
      this.selectedSensorTypes += '/';
    }
    this.initializeData();
    this.clickHandler();
  }

  async ccChange(cCover: any, indx: number, row: any) {
    if (cCover) {
      const ccFactor = this.AVAILABLESENSORS[indx]?.cloudnew[cCover];
      this.cloudFactor = ccFactor;
      this.AVAILABLESENSORS[indx].selectedCC = cCover;
      this.AVAILABLESENSORS[indx].cloudFactor = ccFactor;
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx,idx);
      }
    }
  }

  async ONAOption(cCover: any, indx: number, row: any, type: any) {
    if(cCover) {
      if(!cCover?.key){
        cCover.key =cCover.ona? cCover.ona:cCover.key;
        cCover.value =cCover.value? cCover.value:cCover.onaUplift;
      }
      if(cCover?.ona && type === 1){
       if( this.AVAILABLESENSORS[indx].resolution.length<2 ){
      
        let newResoution:any= {key:"0",value:cCover.resolution,originalResolution :cCover.forResolution}
        if(cCover?.mode_value){
          newResoution.mode_value= cCover?.mode_value;
        }
        this.AVAILABLESENSORS[indx].resolution =[newResoution] 
        this.AVAILABLESENSORS[indx].selectedResolution =newResoution
             
         }
         if( this.AVAILABLESENSORS[indx].resolution.length>1){

          const resolutionArray = this.createResolutionArray(this.AVAILABLESENSORS[indx]?.onaWithResolution,  cCover?.ona,this.AVAILABLESENSORS[indx].resolution);
          this.AVAILABLESENSORS[indx].resolution=resolutionArray;
          this.AVAILABLESENSORS[indx].selectedResolution =resolutionArray[0];
          this.AVAILABLESENSORS[indx].selectedResolutionValue =resolutionArray[0]?.value;
          this.AVAILABLESENSORS[indx].modeResetValue =null;
          this.AVAILABLESENSORS[indx].selectedModes = [row?.collection_mode[0]];
          this.AVAILABLESENSORS[indx].modeSelected = [row?.collection_mode[0]];

          if ((row?.key === OPERATORS.STE && row?.sensor === 'MSI')) {
            this.resetCost(row,this.AVAILABLESENSORS[indx].selectedResolution,indx);
          }
        }
       
      }
     
      const ccFactor = cCover.value;
      this.ONAFactor = ccFactor;
      this.AVAILABLESENSORS[indx].selectedONA = cCover.ona? cCover.ona:cCover.key;
      this.AVAILABLESENSORS[indx].selectedONAOption = cCover;
      this.AVAILABLESENSORS[indx].ONAFactor = ccFactor;
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx,idx);
      }
    }

    if ((row.key === OPERATORS.HEAD) || row.key === OPERATORS.GOKTURK || (row.key === OPERATORS.AT21)) {
      this.resetResolution(this.AVAILABLESENSORS[indx].selectedModes[0],indx,row?.key);
    }

    if(this.AVAILABLESENSORS[indx].selectedResolution && this.AVAILABLESENSORS[indx].selectedResolution?.bands) {
      this.AVAILABLESENSORS[indx].sensorTooltip = this.AVAILABLESENSORS[indx].selectedResolution.bands;
    }
  }

  async modeChange(cMode: any, indx: number, row: any) {
    const cmFactor = this.SENSORS[indx].collectmodes[cMode];
    this.selctedCollectMode[indx] = cMode;
    const idx = this.SENSORS[indx].latency.findIndex(
      (elem) => elem.cost === this.SENSORS[indx].selectedCost
    );
    this.SENSORS[indx].latency[0].cost = Math.round(
      cmFactor * this.SENSORS[indx].defaultLatency[0].cost
    );
    this.SENSORS[indx].latency[1].cost = Math.round(
      cmFactor * this.SENSORS[indx].defaultLatency[1].cost
    );
    this.SENSORS[indx].latency[2].cost = Math.round(
      cmFactor * this.SENSORS[indx].defaultLatency[2].cost
    );

    if (idx > -1) {
      this.SENSORS[indx].selectedCost = Math.round(
        this.SENSORS[indx].latency[idx].cost
      );
    }

    this.SENSORS[indx].cost = parseInt(
      this.SENSORS[indx].selectedCost.toString()
    );
    this.SENSORS[indx].checked = true;
    // console.log(this.SENSORS)
    await this.getTotalCost(true, indx, row);
  }
  async toggleSelect(checked: boolean) {
    this.SENSORS.forEach((sensor, indx) => {
      if (sensor.enabled) {
        this.SENSORS[indx].checked = checked;
      }
    });
  }
  async taskingOption(latency: any, rowIndx: number, row: any, type: number) {
    if (this.AVAILABLESENSORS[rowIndx].prevent === true) {
      this.AVAILABLESENSORS[rowIndx].prevent = false;
      this.AVAILABLESENSORS[rowIndx].checked = false;
    }
    const latencyFilter = row?.latency.filter((el: any) => el.name === latency);
    let latencyKey = latencyFilter[0];
    const startDate: any = new Date(
      moment(this.target.openWindow).utc().format()
    );
    const endDate: any = new Date(
      moment(this.target.closeWindow).utc().format()
    );
    const start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    const differenceInTime: number = endDate.getTime() - startDate.getTime();
    const differenceInDays: number = Math.floor(
      differenceInTime / (1000 * 60 * 60 * 24)
    );
    const keysToInclude = ['key', 'name', 'latency'];
    const latencyArray = latencyFilter.map((item) => {
      const newItem: any = {};
      keysToInclude.forEach((key) => (newItem[key] = item[key]));
      return newItem;
    });

    if (
      latencyKey.key === 'rush' ||
      latencyKey.key === 'RR' ||
      latencyKey.key === 'emergency' ||
      (latencyKey.key === 'priority' && (row?.key === OPERATORS.STE && row.sensor != 'SAR'))
    ) {
      this.AVAILABLESENSORS[rowIndx].rushCloudCover = true;
      this.AVAILABLESENSORS[rowIndx].selectedCC = '';
    }
    else {
      if (latencyKey.key != 'standard' && type === 0 && this.AVAILABLESENSORS[rowIndx]?.cloudcovers) {
      if(row.key === OPERATORS.HEAD || (row.key === OPERATORS.STE && row.sensor === 'MSI') || row.key === OPERATORS.AXELSPACE || row.key === OPERATORS.WYVERN) {               
        this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[4]?.key;
        this.AVAILABLESENSORS[rowIndx].cloudValue = null;
      } else if (row.key === OPERATORS.KOMPSAT || row.key === OPERATORS.ISI || row.key === OPERATORS.GOKTURK) {
        this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[5]?.key;
        this.AVAILABLESENSORS[rowIndx].cloudValue = null;
      }
 
      }
      else if (this.AVAILABLESENSORS[rowIndx]?.cloudcovers && type === 0) {
        this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[0]?.key;
        this.AVAILABLESENSORS[rowIndx].cloudValue = null;
      }
      this.AVAILABLESENSORS[rowIndx].rushCloudCover = false;
      this.ccChange(this.AVAILABLESENSORS[rowIndx].selectedCC, rowIndx, 'row');
    }

    if (latencyKey.key === 'priority' && row.key === OPERATORS.BLACKSKY) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = '100%';
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    } else if (latencyKey.key != 'standard' && row.key === OPERATORS.GOKTURK) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[5]?.key;
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    } else if (latencyKey.key != 'standard' && type != 0 && (row.key === OPERATORS.STE && row.sensor === 'MSI')) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[4]?.key;
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    } else if (latencyKey.key === 'priority' && row.key === OPERATORS.WYVERN) {
      this.AVAILABLESENSORS[rowIndx].selectedCC = this.AVAILABLESENSORS[rowIndx]?.cloudcovers[4]?.key;
      this.AVAILABLESENSORS[rowIndx].cloudValue = null;
    }

    if (this.FILTEREDSENSORS[rowIndx]?.checked)
      this.FILTEREDSENSORS[rowIndx].checked = true;
    
    this.AVAILABLESENSORS[rowIndx].selectedLatency = latency;
    this.AVAILABLESENSORS[rowIndx].selectedLatencyKey = latencyArray[0];
    this.AVAILABLESENSORS[rowIndx].latency.forEach((elem, i) => {
      if (elem.name === latency) {
        this.AVAILABLESENSORS[rowIndx].cost =
          this.AVAILABLESENSORS[rowIndx].latency[i].unitcost;
        this.AVAILABLESENSORS[rowIndx].unitcost =
          this.AVAILABLESENSORS[rowIndx].latency[i].unitcost;
        //if(this.AVAILABLESENSORS[rowIndx].key !== OPERATORS.SPIRE) {
        this.AVAILABLESENSORS[rowIndx].selectedCost =
          this.AVAILABLESENSORS[rowIndx].latency[i].unitcost;
        //}
        this.AVAILABLESENSORS[rowIndx].latency[i].selected = true;
      } else this.AVAILABLESENSORS[rowIndx].latency[i].selected = false;
    });

    if (row?.key === OPERATORS.WYVERN) {
      this.AVAILABLESENSORS[rowIndx].selectedHoldBackOption =
        row?.holdback.filter(
          (value) => value.type === row.selectedLatencyKey.key
        )[0];
      this.AVAILABLESENSORS[rowIndx].holdBackValue = null;
      for (const [index, item] of row.latency.entries()) {
        let key = this.target?.intent?.key + '_' + item.key;
        if (row?.pricing.hasOwnProperty(key)) {
          item.cost = row.pricing[key];
          item.unitcost = row.pricing[key];
        }
      }
      this.AVAILABLESENSORS[rowIndx].latency = row.latency;
      if (latencyKey.key === 'priority')
        this.AVAILABLESENSORS[rowIndx].minarea = 50;
      else this.AVAILABLESENSORS[rowIndx].minarea = 100;
    }

    this.holdBackOption(
      this.AVAILABLESENSORS[rowIndx].selectedHoldBackOption,
      rowIndx,
      row
    );
    this.ONAOption(
      this.AVAILABLESENSORS[rowIndx].selectedONAOption,
      rowIndx,
      row,
      0
    );

    const idx = this.AVAILABLESENSORS[rowIndx].latency.findIndex(
      (elem) => elem.name === this.AVAILABLESENSORS[rowIndx].selectedLatency
    );

    if (idx > -1) {
      this.calculateUnitcost(rowIndx,idx);
    }

    if (row?.key === OPERATORS.CAPELLA && differenceInDays < 1 && row.selectedLatencyKey.key === 'rush' && type === 0) {
      const message = `${row?.displayName} ${row?.sensor} requires a minimum one day window for rush tasking. Any lesser, it may be rejected. Please provide a minimum of one day time of interest (TOI).`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    }

    else if (row?.key === OPERATORS.CAPELLA && differenceInDays < 7 && row.selectedLatencyKey.key === 'standard' && type === 0) {
      const message = `${row?.displayName} ${row?.sensor} requires a minimum seven day window for standard tasking. Any lesser, it may be rejected. Please provide a minimum of seven day time of interest (TOI).`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    }

    else if (row?.key === OPERATORS.CAPELLA && differenceInDays < 3 && row.selectedLatencyKey.key === 'priority' && type === 0) {
      const message = `${row?.displayName} ${row?.sensor} requires a minimum three day window for priority tasking. Any lesser, it may be rejected. Please provide a minimum of three day time of interest (TOI).`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    }

    let standardResponseTime;

    if (row?.key === OPERATORS.AT21 && type === 0) {
      const latencyNumber = parseInt(row.selectedLatencyKey.latency.match(/\d+/)[0], 10);
      standardResponseTime = latencyNumber;
      if (row.selectedLatencyKey.latency === '24 hrs') standardResponseTime = 1;

      if (differenceInDays < standardResponseTime) {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === rowIndx) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
        const message = `The time of interest (TOI) is less than the selected tasking tier response time. Please change the tasking tier or amend the TOI.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');

        this.selectedSensors = [];
        this.AVAILABLESENSORS.forEach((operator) => {
          if (operator.checked) this.selectedSensors.push(operator);
        });
        this.clickHandler();
      }   
    }
  }

  async holdBackOption(cCover: any, indx: number, row: any) {
    if (cCover) {
      const ccFactor = cCover?.value;
      this.holdBack = ccFactor;
      this.AVAILABLESENSORS[indx].selectedHoldBack = cCover?.key;
      this.AVAILABLESENSORS[indx].selectedHoldBackOption = cCover;
      this.AVAILABLESENSORS[indx].ccFactor = ccFactor;
      this.AVAILABLESENSORS[indx].holdbackFactor = ccFactor;
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx,idx);
      }
    }
  }

  async modeOption(productMode: any, indx: number, row: any) {
    row.valueAddedOption = [];
    if (row?.key === OPERATORS.CAPELLA) {
      let mode = CAPELLA_MODES[productMode?.mode_type];
      if (this.AVAILABLESENSORS[indx].prevent === true) {
        this.AVAILABLESENSORS[indx].prevent = false;
        this.AVAILABLESENSORS[indx].checked = false;
      }

      if (this.target.area > 0 && mode === 'POI'
      ) {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === indx) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
        const message = `${row?.displayName} ${row?.sensor} has different modes of collection based on whether the location
        is an area (AOI) or point (POI) of interest. Stripmap_100 is used for AOI,
        and all the other modes are used for POI.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');

        this.selectedSensors = [];
        this.AVAILABLESENSORS.forEach((operator) => {
          if (operator.checked) this.selectedSensors.push(operator);
        });
        this.clickHandler();
      } else if (this.target.area === 0 && mode === 'AOI'
      ) {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === indx) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
        const message = `${row?.displayName} ${row?.sensor} has different modes of collection based on whether the location
        is an area (AOI) or point (POI) of interest. Stripmap_100 is used for AOI,
        and all the other modes are used for POI.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');

        this.selectedSensors = [];
        this.AVAILABLESENSORS.forEach((operator) => {
          if (operator.checked) this.selectedSensors.push(operator);
        });
        this.clickHandler();
      }
    }

    if (row?.key === OPERATORS.GHG) {
      if (this.AVAILABLESENSORS[indx].prevent === true) {
        this.AVAILABLESENSORS[indx].prevent = false;
        this.AVAILABLESENSORS[indx].checked = false;
      }

      this.checkTargetLocation().then(result => {
        switch (result) {
          case 'water':
            if (productMode.productLevel.includes('Onshore')) {
              this.AVAILABLESENSORS.forEach((operator, idx) => {
                if (idx === indx) {
                  this.AVAILABLESENSORS[idx].checked = false;
                  this.AVAILABLESENSORS[idx].prevent = true;
                }
              });

              const message = `${row?.displayName} ${row?.sensor} has different products based on whether the tasked area is over open waters or land. 
              Please change your product choice or change the location of your task.`;
              this.popupService.openPopup(WarningPopup, message, '', '50%');

              this.selectedSensors = [];
              this.AVAILABLESENSORS.forEach((operator) => {
                if (operator.checked) this.selectedSensors.push(operator);
              });
              this.clickHandler();
            }
            break;
          case 'land':
            if (productMode.productLevel.includes('Offshore')) {
              this.AVAILABLESENSORS.forEach((operator, idx) => {
                if (idx === indx) {
                  this.AVAILABLESENSORS[idx].checked = false;
                  this.AVAILABLESENSORS[idx].prevent = true;
                }
              });

              const message = `${row?.displayName} ${row?.sensor} has different products based on whether the tasked area is over open waters or land. 
              Please change your product choice or change the location of your task.`;
              this.popupService.openPopup(WarningPopup, message, '', '50%');

              this.selectedSensors = [];
              this.AVAILABLESENSORS.forEach((operator) => {
                if (operator.checked) this.selectedSensors.push(operator);
              });
              this.clickHandler();
            }
            break;
          case 'both':
            this.AVAILABLESENSORS.forEach((operator, idx) => {
              if (idx === indx) {
                this.AVAILABLESENSORS[idx].checked = false;
                this.AVAILABLESENSORS[idx].prevent = true;
              }
            });

            const message = `${row?.displayName} ${row?.sensor} has different products based on whether the tasked area is over open waters or land. 
            Please change your product choice or change the location of your task.`;
            this.popupService.openPopup(WarningPopup, message, '', '50%');

            this.selectedSensors = [];
            this.AVAILABLESENSORS.forEach((operator) => {
              if (operator.checked) this.selectedSensors.push(operator);
            });
            this.clickHandler();
            break;
          default:
            console.error('Unexpected result:', result);
        }
      });
    }

    this.resolutionTooltip = true;
    this.AVAILABLESENSORS[indx].selectedModes = [];
    if (productMode?.isCaptureMode && !productMode?.isProductLevel) {
      this.AVAILABLESENSORS[indx].selectedMode = productMode.captureMode;
    } else if (productMode?.isProductLevel && !productMode?.isCaptureMode) {
      this.AVAILABLESENSORS[indx].selectedMode = productMode.productLevel;
    } else if (productMode?.isProductLevel && productMode?.isCaptureMode) {
      this.AVAILABLESENSORS[indx].selectedMode =
        productMode.captureMode + ' (' + productMode.productLevel + ')';
    }

    if (
      row?.key === OPERATORS.AT21
    ) {
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (productMode?.productLevel === 'L1') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Bundle')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.productLevel === 'L4'
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.value.includes('Pan-sharpened')) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      } else {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.value.includes('Both')) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }
      }

      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx,idx);
      }
    }

    else if (
      (row?.key === OPERATORS.STE && row?.sensor === 'SAR')
    ) {
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (productMode?.mode === 'PSPOT') {
        this.AVAILABLESENSORS[indx].resolution[1].disable = true;
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      } else if (
        productMode?.mode === 'PSTRIP'
      ) {
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].resolution[0].disable = true;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[1];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[1].value;
      } else {
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      }
    }

    else if (
      (row?.key === OPERATORS.SYNSPECTIVE && row?.sensor === 'SAR')
    ) {      
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (
        productMode?.key === 'Area'
      ) { 
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].resolution[1].disable = true;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      } else {
        this.AVAILABLESENSORS[indx].resolution[0].disable = true;
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[1];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[1].value;
      }
    }

    else if (
      (row?.key === OPERATORS.ISI && row?.sensor === 'Stereo')
    ) {      
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      if (
        productMode?.key != 'Bundle'
      ) { 
        this.AVAILABLESENSORS[indx].resolution[0].disable = false;
        this.AVAILABLESENSORS[indx].resolution[1].disable = true;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[0];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[0].value;
      } else {
        this.AVAILABLESENSORS[indx].resolution[0].disable = true;
        this.AVAILABLESENSORS[indx].resolution[1].disable = false;
        this.AVAILABLESENSORS[indx].selectedResolution =
          this.AVAILABLESENSORS[indx].resolution[1];
        this.AVAILABLESENSORS[indx].selectedResolutionValue =
          this.AVAILABLESENSORS[indx].resolution[1].value;
      }
    }

    if (
      row?.key === OPERATORS.CAPELLA
    ) {
      if (productMode?.mode_type === 'spotlight_ultra') {
        this.AVAILABLESENSORS[indx].selectedResolutionData = null;
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key === 1) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }

      } else if (productMode?.mode_type === 'spotlight') {
        this.AVAILABLESENSORS[indx].selectedResolutionData = null;

        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key === 2) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      }
    }

    this.AVAILABLESENSORS[indx].selectedModes = productMode;
    this.getTooltip(productMode, indx, row);
    const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
      (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
    );
    if (productMode?.length) {
      this.spot = false;
      this.strip = false;
      if (productMode[0]?.key.includes('Spot') && row?.key === OPERATORS.STE) {
        this.spot = true;
      } else if (row?.key === OPERATORS.STE) {
        this.strip = true;
      }

      this.AVAILABLESENSORS[indx].selectedMode = productMode[0].key;

      if (productMode[0]?.isCaptureMode && !productMode[0]?.isProductLevel) {
        this.AVAILABLESENSORS[indx].selectedMode = productMode[0].captureMode;
      } else if (
        productMode[0]?.isProductLevel &&
        !productMode[0]?.isCaptureMode
      ) {
        this.AVAILABLESENSORS[indx].selectedMode = productMode[0].productLevel;
      } else if (
        productMode[0]?.isProductLevel &&
        productMode[0]?.isCaptureMode
      ) {
        this.AVAILABLESENSORS[indx].selectedMode =
          productMode[0].captureMode + ' (' + productMode[0].productLevel + ')';
      }

      this.getTooltip(productMode[0], indx, row);
    } else if (productMode.length === 0) {
      this.spot = false;
      this.strip = false;
      this.getTooltip(row?.collection_mode[0], indx, row);
      this.AVAILABLESENSORS[indx].selectedModes = [];
      this.AVAILABLESENSORS[indx].selectedModes =
        this.AVAILABLESENSORS[indx].modeSelected;
    } else {
      this.AVAILABLESENSORS[indx].selectedModes = [];
      this.AVAILABLESENSORS[indx].selectedModes.push(productMode);
    }

    if (row?.key === OPERATORS.UMBRA) {
      let looks: any;
      this.AVAILABLESENSORS[indx].selectedLooks = null;
      this.AVAILABLESENSORS[indx].selectedONA =
        this.AVAILABLESENSORS[indx].ONA[0].key;
      this.AVAILABLESENSORS[indx].selectedONAOption =
        this.AVAILABLESENSORS[indx].ONA[0];
      this.AVAILABLESENSORS[indx].selectedItem = null;
      this.AVAILABLESENSORS[indx].selectedResolutionData = null;
      this.AVAILABLESENSORS[indx].selectedResolution =
        this.AVAILABLESENSORS[indx].resolution[0];
      this.AVAILABLESENSORS[indx].selectedResolutionValue =
        this.AVAILABLESENSORS[indx].resolution[0].value;
      this.AVAILABLESENSORS[indx].selectedLooksValue = null;
      looks = this.AVAILABLESENSORS[indx].selectedResolution.numberOfLooks[0];

      if (productMode?.value === '5km x 10km') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].ONA.entries()) {
          if (index > 2) {
            items.disabled = true;
          } else items.disabled = false;
        }
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          items.disabled = false;
        }
      } else if ((productMode?.value === '10km x 10km') || (productMode?.value === '10km x 10km(CPHD)')) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].ONA.entries()) {
          if (index > 0) {
            items.disabled = true;
          } else items.disabled = false;
        }

        if (
          !this.AVAILABLESENSORS[indx].resolution[0].value.includes('looks')
        ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key != 3 && items.key != 4) {
              items.disabled = true;
            } else items.disabled = false;
          }

          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 3 || items.key === 4) {
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value;              
              break;
            }
          }
        }
      } else if (productMode?.value === 'SAR Video') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key != 4) {
            items.disabled = true;
          } else {
            items.disabled = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value;
            looks = this.AVAILABLESENSORS[indx].selectedResolution.numberOfLooks[4];
            for (const [index, item] of this.AVAILABLESENSORS[
              indx
            ].selectedResolution.numberOfLooks.entries()) {
              if (item === 'x5') {
                this.AVAILABLESENSORS[indx].selectedLooks = item;
                this.AVAILABLESENSORS[indx].numberOfLooks = item;
              }
            }
          }
        }
      }
      else {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].ONA.entries()) {
          items.disabled = false;
        }
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          items.disabled = false;
        }
      }

      let sceneSize = this.AVAILABLESENSORS[indx].selectedModes[0].value;
      this.AVAILABLESENSORS[indx].numberOfLooks = looks;

      row.latency[0].cost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      row.latency[0].unitcost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[0].name, indx, row, 1);
    }

    if (row?.key === OPERATORS.BLACKSKY) {
      if (productMode.sceneSize)
        this.AVAILABLESENSORS[indx].minarea = productMode.sceneSize;
    }

    if (row?.key === OPERATORS.GHG) {
      const selectedIndex = this.AVAILABLESENSORS[indx].latency.findIndex(
        (item) => item.selected === true
      );
      row.latency = row.latency.map(latencyItem => {
        const matchingMode = row.selectedModes[0].cost.find(mode => mode[latencyItem.key] !== undefined);
        return {
          ...latencyItem,
          cost: matchingMode ? matchingMode[latencyItem.key] : null,
          unitcost: matchingMode ? matchingMode[latencyItem.key] : null,
        };
      });
        this.AVAILABLESENSORS[indx].latency = row.latency;
        this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    }

    if(row?.key === OPERATORS.STE && row?.sensor === 'MSI') {
      this.resetCost(row,this.AVAILABLESENSORS[indx].selectedResolution,indx)
    }

    if (row?.key === OPERATORS.CAPELLA || row?.key === OPERATORS.BLACKSKY || row?.key === OPERATORS.GOKTURK 
      || row?.key === OPERATORS.ECURS
    ) {
      let pricingArray= row.selectedModes[0]?.pricing;
      const selectedIndex = this.AVAILABLESENSORS[indx].latency.findIndex(
        (item) => item.selected === true
      );
      for (const latencyItem of this.AVAILABLESENSORS[indx].latency) {
        const key = latencyItem.key;
        if (pricingArray.hasOwnProperty(key)) {
          const newCost = Number(pricingArray[key]);
          latencyItem.cost = newCost;
          latencyItem.unitcost = newCost;
        }
      }

      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    }

    let minimumArea: any;
    if ((row.unit === 'scene' || row.unit === 'week' || row.unit === 'day') && row.key != OPERATORS.BLACKSKY) {
      if (row.tooltipValue) {
        minimumArea = this.extractAndCalculateValues(row.tooltipValue);
        this.AVAILABLESENSORS[indx].minimumAreaSize = minimumArea;
      } else if (row.minimumArea) {
        minimumArea = this.extractAndCalculateValues(row.minimumArea);
        this.AVAILABLESENSORS[indx].minimumAreaSize = minimumArea;
      }
    } else {
      minimumArea = row.minarea;
      this.AVAILABLESENSORS[indx].minimumAreaSize = minimumArea;
    }

    if((row?.key === OPERATORS.HEAD) || row?.key === OPERATORS.GOKTURK || row?.key === OPERATORS.GHG || row?.key === OPERATORS.ECURS) {
      this.resetResolution(productMode,indx,row?.key);
    }

    if(this.AVAILABLESENSORS[indx].selectedResolution && this.AVAILABLESENSORS[indx].selectedResolution?.bands) {
      this.AVAILABLESENSORS[indx].sensorTooltip = this.AVAILABLESENSORS[indx].selectedResolution.bands;
    }

    this.resetValueAddedDropdown(indx, row);
  }

  resetResolution(productMode,indx,operatorKey) {
    this.AVAILABLESENSORS[indx].selectedResolutionData = null;
    let item = this.AVAILABLESENSORS[indx];
    if ((productMode?.key === 'L3C' && operatorKey === OPERATORS.HEAD && item.sensor != 'HSI') || 
        (productMode?.productLevel === 'L2B' && operatorKey === OPERATORS.GOKTURK && productMode?.key.includes('pan_sharpened'))) {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('Pan-sharpened')) {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = items;
          this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
        } else {
          items.disable = true;
        }
      }
    } else if ((productMode?.key === 'L3D' && operatorKey === OPERATORS.HEAD)) {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('TCI')) {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = items;
          this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
        } else {
          items.disable = true;
        }
      }
    } else if ((productMode?.key != 'L3D' && operatorKey === OPERATORS.HEAD && item.sensor === 'HSI')) {      
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('TCI')) {
          items.disable = true;
        } else {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = item.resolution[1];
          this.AVAILABLESENSORS[indx].selectedResolutionValue = item.resolution[1].value
        }
      }
    } else if (operatorKey === OPERATORS.AT21) {
      if (productMode?.productLevel === 'L1') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value.includes('Bundle')) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.productLevel === 'L4'
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.value.includes('Pan-sharpened')) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      } else {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.value.includes('Both')) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }
      }
    } else if (operatorKey === OPERATORS.ECURS) {
      if (productMode?.mode === 'SL') {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.key === 1) {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.mode === 'SM'
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 2) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      } else if (
        productMode?.mode === 'NSS'
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 3) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      } else {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.key === 4) {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }
      }
    } else if (operatorKey === OPERATORS.GHG) {
      if (productMode?.productLevel.includes('Onshore')) {
        for (const [index, items] of this.AVAILABLESENSORS[
          indx
        ].resolution.entries()) {
          if (items.value === '25m') {
            items.disable = false;
            this.AVAILABLESENSORS[indx].selectedResolution = items;
            this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
          } else {
            items.disable = true;
          }
        }
      } else if (
        productMode?.productLevel.includes('Offshore')
      ) {
          for (const [index, items] of this.AVAILABLESENSORS[
            indx
          ].resolution.entries()) {
            if (items.value === '30-80m') {
              items.disable = false;
              this.AVAILABLESENSORS[indx].selectedResolution = items;
              this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
            } else {
              items.disable = true;
            }
          }  
      }
    } else {
      for (const [index, items] of this.AVAILABLESENSORS[
        indx
      ].resolution.entries()) {
        if (items.value.includes('Bundle')) {
          items.disable = false;
          this.AVAILABLESENSORS[indx].selectedResolution = items;
          this.AVAILABLESENSORS[indx].selectedResolutionValue = items.value
        } else {
          items.disable = true;
        }
      }
    }
  }

  async resolutionChange(resolution: any, indx: number, row: any) {
    if (row?.key === OPERATORS.UMBRA) {
      this.AVAILABLESENSORS[indx].selectedLooks = null;
      let sceneSize = this.AVAILABLESENSORS[indx].selectedModes[0].value;
      let looks = resolution.numberOfLooks[0];
      this.AVAILABLESENSORS[indx].numberOfLooks = looks;

      if (sceneSize === 'SAR Video') {
        for (const [index, item] of this.AVAILABLESENSORS[
          indx
        ].selectedResolution.numberOfLooks.entries()) {
          if (item === 'x5') {
            this.AVAILABLESENSORS[indx].selectedLooks = item;
            this.AVAILABLESENSORS[indx].numberOfLooks = item;
            looks = item;
          }
        }
      }
      
      row.latency[0].cost =
        resolution?.sceneSizePricing[sceneSize][looks];
      row.latency[0].unitcost =
        resolution?.sceneSizePricing[sceneSize][looks];
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[0].name, indx, row,1);
    }

    if ((row?.key === OPERATORS.STE && row?.sensor === 'MSI')) {
      this.resetCost(row,resolution,indx);
    }

    this.AVAILABLESENSORS[indx].selectedResolution = resolution;
    this.AVAILABLESENSORS[indx].selectedResolutionValue = resolution.value;
    
    if(resolution?.bands) {
      this.AVAILABLESENSORS[indx].sensorTooltip = resolution.bands;
    }

    if(row?.key === OPERATORS.AXELSPACE || row?.key ===OPERATORS.AT21) {
      const idx = this.AVAILABLESENSORS[indx].latency.findIndex(
        (elem) => elem.name === this.AVAILABLESENSORS[indx].selectedLatency
      );

      if (idx > -1) {
        this.calculateUnitcost(indx,idx);
      }
    }

    this.resetValueAddedDropdown(indx, row);
  }

  resetCost(row,resolution,indx) {
    const selectedIndex = this.AVAILABLESENSORS[indx].latency.findIndex(
      (item) => item.selected === true
    );

    if ((row.key === OPERATORS.STE && resolution.value.includes('Bundle')
    )) {
      let pricingArray= this.AVAILABLESENSORS[indx].selectedModes[0]?.pricing;
      for (const latencyItem of this.AVAILABLESENSORS[indx].latency) {
        const key = latencyItem.key;
        if (pricingArray.hasOwnProperty(key)) {
          const newCost = Number(pricingArray[key]);
          latencyItem.cost = newCost;
          latencyItem.unitcost = newCost;
        }
      }
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    } else if (row?.key === OPERATORS.STE && row?.sensor === 'MSI'
      && resolution.value.includes('Pan-sharpened')) {
        let costSTE = this.AVAILABLESENSORS[indx].STElatency[0];
        row.latency[0].cost = costSTE[0].cost;
        row.latency[0].unitcost = costSTE[0].unitcost;
        row.latency[1].cost = costSTE[1].cost;
        row.latency[1].unitcost = costSTE[1].unitcost;
        this.taskingOption(row.latency[selectedIndex].name, indx, row, 1);
    }
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    // Convert values to comparable types
    const aValue = typeof a === 'string' ? parseFloat(a) : a;
    const bValue = typeof b === 'string' ? parseFloat(b) : b;

    // Handle cases where one value is NaN (string couldn't be converted to number)
    if (isNaN(aValue) && !isNaN(bValue)) {
      return isAsc ? 1 : -1; // Strings come after numbers
    }
    if (!isNaN(aValue) && isNaN(bValue)) {
      return isAsc ? -1 : 1; // Numbers come before strings
    }

    // Both values are either numbers or strings, compare them
    return (aValue < bValue ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareArrays(
    array1: any[],
    array2: any[],
    property: string,
    isAsc: boolean
  ): number | string {
    // Compare the arrays by the specified property
    for (let i = 0; i < Math.min(array1.length, array2.length); i++) {
      const value1 = array1[i][property];
      const value2 = array2[i][property];

      const comparisonResult =
        parseFloat(value1.match(/\d+(\.\d+)?/g)[0]) -
        parseFloat(value2.match(/\d+(\.\d+)?/g)[0]);

      if (comparisonResult !== 0) {
        return comparisonResult * (isAsc ? 1 : -1);
      }
    }

    // If all compared elements are equal, compare the lengths of the arrays
    return (array1.length - array2.length) * (isAsc ? 1 : -1);
  }
  sortData(sort: Sort) {
    const data = this.AVAILABLESENSORS.slice();
    if (!sort.active || sort.direction === '') {
      this.AVAILABLESENSORS = data;
      return;
    }

    this.AVAILABLESENSORS = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'operator':
          return this.compare(a.name, b.name, isAsc);
        case 'resolution':
          return this.compareArrays(a.resolution, b.resolution, 'value', isAsc);
        case 'area_calculated':
          return this.compare(a.minarea, b.minarea, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource(this.AVAILABLESENSORS);
  }
  submit(feasibility: boolean) {
    let click = true;
    let selectedSensors: any = [],
      newTaskParams = {};
    let id = -1;
    let no_of_repeats = 1;
    let noOfMonths = 1;
    let no_of_weeks = 1;
    let startdate = moment(this.target.openWindow).utcOffset(0, true).format();
    let enddate = moment(this.target.closeWindow).utcOffset(0, true).format();
    let time_difference =
      new Date(enddate).getTime() - new Date(startdate).getTime();
    let days_difference = Math.ceil(time_difference / (1000 * 60 * 60 * 24));
    noOfMonths = Math.ceil(days_difference / 30);
    let totalTaskNumber = 0;

    if (this.target.selectedOption === 1) {
      if (this.target.freqOption === 'Weekly') {
        no_of_repeats = Math.ceil(days_difference / 7);
        this.expectedAge = '7 days, 00:00:00';
      } else if (this.target.freqOption === 'Monthly') {
        no_of_repeats = Math.ceil(days_difference / 30);
        this.expectedAge = '30 days, 00:00:00';
      } else if (this.target.freqOption === 'Daily') {
        no_of_repeats = days_difference;
        this.expectedAge = '1 day, 00:00:00';
      } else if (this.target.subDailyCount) {
        no_of_weeks = Math.ceil(days_difference / 7);
        no_of_repeats = days_difference * this.target.subDailyCount;
        this.expectedAge = '0 day, 04:00:00';
      }
      if (no_of_repeats === 0) {
        no_of_repeats = 1;
      }
    } else {
      no_of_repeats = 1;
    }

    this.AVAILABLESENSORS.forEach((sensor, index) => {
      if (sensor.checked) {
        id += 1;
        let sensordata: any;
        if (sensor.sensortype === 'SAR' || sensor.sensortype === 'InSAR') {
          
          if (this.target.area < sensor.minimumAreaSize) {
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              latency: sensor.selectedLatencyKey,
              collectmode: this.selctedCollectMode[index],
              cost: parseFloat((sensor.selectedCost * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((sensor.selectedCost * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: sensor.selectedCost,
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization
                ? sensor?.selectedPolarization
                : sensor?.defaultPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              orbitalPlane: sensor?.orbitalPlane,
              orbitalState: sensor?.orbitalState,
              observationDirection: sensor?.observationDirection,
              polarization: sensor?.polarization,
              numOfLooks: sensor?.numOfLooks,
              imageWidth: sensor?.imageWidth,
              imageLength: sensor?.imageLength,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              noOfUsers: sensor?.noOfUsers,
              targetAzimuthAngleMin: sensor?.selectedAzimuthAngleMin ? sensor?.selectedAzimuthAngleMin : sensor?.defaultAzimuthAngleMin,
              targetAzimuthAngleMax: sensor?.selectedAzimuthAngleMax ? sensor?.selectedAzimuthAngleMax : sensor?.defaultAzimuthAngleMax,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName,
              sarOrbitType: sensor?.selectedOrbitType,
            };
          } else {
            let scene = Math.ceil(this.target.area / sensor.minimumAreaSize);
 
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              latency: sensor.selectedLatencyKey,
              collectmode: this.selctedCollectMode[index],
              cost: parseFloat((scene * sensor.selectedCost * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((scene * sensor.selectedCost * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: (sensor.selectedCost * scene),
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization
                ? sensor?.selectedPolarization
                : sensor?.defaultPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              orbitalPlane: sensor?.orbitalPlane,
              orbitalState: sensor?.orbitalState,
              observationDirection: sensor?.observationDirection,
              polarization: sensor?.polarization,
              numOfLooks: sensor?.numOfLooks,
              imageWidth: sensor?.imageWidth,
              imageLength: sensor?.imageLength,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              noOfUsers: sensor?.noOfUsers,
              targetAzimuthAngleMin: sensor?.selectedAzimuthAngleMin ? sensor?.selectedAzimuthAngleMin : sensor?.defaultAzimuthAngleMin,
              targetAzimuthAngleMax: sensor?.selectedAzimuthAngleMax ? sensor?.selectedAzimuthAngleMax : sensor?.defaultAzimuthAngleMax,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName,
              sarOrbitType: sensor?.selectedOrbitType,
         
            };
          }

          if (sensor.key === OPERATORS.UMBRA) {            
            if (sensor.numberOfLooks) sensordata.numberOfLooks = sensor.numberOfLooks
            else sensordata.numberOfLooks = sensor.selectedResolution.numberOfLooks[0]
          }
        } else if (sensor.sensortype === 'RF') {
          let targetLength = this.target.multiTarget?.length > 0 ? this.target.multiTarget.length : 1;
          
          if (sensor.key === OPERATORS.LBAND) {
            for (const signal of sensor?.signalsOfInterest || []) {
              if (signal.collectionDuration === 60) {
                signal.isAudio = true;
              }
            }
          }

          if (this.target.area < sensor.areacalc || sensor?.key === OPERATORS.LBAND) {
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              latency: sensor.selectedLatencyKey,
              collectmode: this.selctedCollectMode[index],
              cost: parseFloat((sensor.selectedCost * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((sensor.selectedCost * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: sensor.selectedCost,
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sensorCost: sensor.selectedCost,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              signalOfInterest: sensor?.signalOfInterest,
              isAudio: sensor?.isAudio,
              noOfPoints: targetLength,
              noOfCollections: sensor?.noOfCollections,
              overlapPercentage: sensor?.overlapPercentage,
              targetPoints: this.target?.targetPoints,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName
            };
          } else {
            let scene = Math.ceil(this.target.area / sensor.areacalc);
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              latency: sensor.selectedLatencyKey,
              collectmode: this.selctedCollectMode[index],
              cost: parseFloat((scene * sensor.selectedCost * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((scene * sensor.selectedCost * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: (sensor.selectedCost * scene),
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sensorCost: sensor.selectedCost,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              signalOfInterest: sensor?.signalOfInterest,
              isAudio: sensor?.isAudio,
              noOfPoints: targetLength,
              noOfCollections: sensor?.noOfCollections,
              overlapPercentage: sensor?.overlapPercentage,
              targetPoints: this.target?.targetPoints,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName   
            };
          }
        } else if (sensor.sensortype === 'GHG') {
          let annualCost = 0;
          let targetLength = this.target.multiTarget?.length > 0 ? this.target.multiTarget.length : 1;
          if (sensor.selectedModes[0].captureMode.includes('annual')) {
            const keyToMatch = sensor.selectedLatencyKey.key; // Extract the key
            let cost = null;

            // Find the matching cost
            for (const item of sensor.selectedModes[0].cost) {
              if (item[keyToMatch] !== undefined) {
                cost = item[keyToMatch];
                break;
              }
            }
            
            if(cost) {
              let monthlyCost = cost / 12;
              if (noOfMonths > 12) {
                if (noOfMonths % 12 === 0) {
                  annualCost = (noOfMonths / 12) * cost;
                } else {
                  annualCost = cost +
                    (noOfMonths - 12) * monthlyCost;
                }
              } else {
                annualCost = cost;
              }
            }
          } else {
            annualCost = sensor.selectedCost * noOfMonths;
          }

          if (!sensor.selectedModes[0].captureMode.includes('Complex')) {
            annualCost = annualCost * targetLength;
          }

          if (this.target.area < sensor.areacalc) {
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              cloudcover: sensor.selectedCC,
              latency: sensor.selectedLatencyKey,
              cost: parseFloat((annualCost).toFixed(2)),
              unitCost: parseFloat((annualCost).toFixed(2)),
              unit: sensor.unit,
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              noOfMonths: noOfMonths,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName   
            };
          } else {
            let scene = Math.ceil(this.target.area / sensor.areacalc);
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              cloudcover: sensor.selectedCC,
              latency: sensor.selectedLatencyKey,
              cost: parseFloat((annualCost * scene).toFixed(2)),
              unitCost: parseFloat((annualCost * scene).toFixed(2)),
              unit: sensor.unit,
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              noOfMonths: noOfMonths,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName
            };
          }
        } else if (sensor?.key === OPERATORS.SPIRE) {
          let area = this.target.area;
          if (this.target.area > 0
            && this.target.targetPoints) {
            area = '10000';
          }
          sensordata = {
            taskid: id,
            name: sensor.name,
            sensor: sensor.sensor,
            sensortype: sensor.sensortype,
            resolution: sensor.selectedResolution,
            cloudcover: sensor.selectedCC,
            latency: sensor.selectedLatencyKey,
            cost: 0,
            unitCost: parseFloat((sensor.selectedCost).toFixed(2)),
            unit: sensor.unit,
            country: sensor.country,
            collectionMode: sensor.selectedModes,
            ONA: sensor.selectedONAOption,
            type: sensor?.type,
            satIds: sensor?.satIds,
            operatorKey: sensor?.key,
            operatorEmail: sensor?.operatorEmail,
            SZA: sensor?.SZA,
            AC: sensor?.AC,
            sarPolarization: sensor?.selectedPolarization,
            sarOrbitDirection: sensor?.selectedOrbitDirection,
            productAnciallary: sensor?.selectedAnciallary,
            satelliteName: sensor?.satelliteName,
            holdBack: sensor?.selectedHoldBackOption,
            supportFeasibility: sensor?.feasibility,
            siteName: sensor?.sitename,
            tnqSensorType: sensor?.selectedTnqSensorType,
            siteData: sensor?.siteData,
            captureMode: sensor?.captureMode,
            noOfUsers: sensor?.noOfUsers,
            noOfRefreshes: sensor?.noOfRefreshes,
            isHistorical: sensor?.isHistorical,
            noOfHistoricalData: sensor?.noOfHistoricalData,
            historicalCost: sensor?.historicalCost,
            minimumArea: sensor?.minimumAreaSize,
            isPoint:this.target.geoJSON.includes("POINT")?true:false,
            isCalculatedAreaSmaller:sensor?.minimumAreaSize >this.target.area? true:false,
            areacalc:area,
            updateFrequency: sensor?.updateFrequency,
            obfuscateName: sensor?.obfuscateName,
            displayName: sensor.displayName
          };
        } else if (sensor?.key === OPERATORS.CLYDE) {
          sensordata = {
            taskid: id,
            name: sensor.name,
            sensor: sensor.sensor,
            sensortype: sensor.sensortype,
            resolution: sensor.selectedResolution,
            cloudcover: sensor.selectedCC,
            latency: sensor.selectedLatencyKey,
            cost: 0,
            unit: sensor.unit,
            country: sensor.country,
            collectionMode: sensor.selectedModes,
            ONA: sensor.selectedONAOption,
            type: sensor?.type,
            satIds: sensor?.satIds,
            operatorKey: sensor?.key,
            operatorEmail: sensor?.operatorEmail,
            SZA: sensor?.SZA,
            AC: sensor?.AC,
            sarPolarization: sensor?.selectedPolarization,
            sarOrbitDirection: sensor?.selectedOrbitDirection,
            productAnciallary: sensor?.selectedAnciallary,
            satelliteName: sensor?.satelliteName,
            holdBack: sensor?.selectedHoldBackOption,
            supportFeasibility: sensor?.feasibility,
            siteName: sensor?.sitename,
            tnqSensorType: sensor?.selectedTnqSensorType,
            siteData: sensor?.siteData,
            captureMode: sensor?.captureMode,
            noOfUsers: sensor?.noOfUsers,
            isHistorical: sensor?.isHistorical,
            minCoverageArea: sensor.selectedTaskingPrice.data.selectedKey,
            noOfMonths: noOfMonths,
            unitCost: sensor.selectedTaskingPrice.data.price,
            noOfHistoricalData: sensor?.noOfHistoricalData,
            minimumArea: sensor?.minimumArea,
            isPoint: this.target.geoJSON.includes('POINT') ? true : false,
            isCalculatedAreaSmaller:
              sensor?.minimumArea > this.target.area ? true : false,
            areacalc: this.target.area,
            historicalUnitCost: sensor?.selectedHistoricalPrice?.data.price,
            updateFrequency: sensor?.updateFrequency,
            obfuscateName: sensor?.obfuscateName,
            displayName: sensor.displayName
          };
        } else {
          if (sensor?.key === OPERATORS.BLACKSKY && this.target.area === 0) {
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              cloudcover: sensor.selectedCC,
              latency: sensor.selectedLatencyKey,
              cost: parseFloat((sensor.selectedCost * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((sensor.selectedCost * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: sensor.selectedCost,
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              captureMode: sensor?.captureMode,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              valueAddedOption: this.formatVAR(sensor?.valueAddedOption),
              demTotalPoint: sensor?.demTotalPoint,
              demTotalPrice: sensor?.demTotalPrice,
              indices: sensor?.indices,
              super_resolution: sensor?.super_resolution,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName
            };
          } else if (this.target.area < sensor.minarea) {
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              cloudcover: sensor.selectedCC,
              latency: sensor.selectedLatencyKey,
              cost: parseFloat((sensor.selectedCost * sensor.minarea * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((sensor.selectedCost * sensor.minarea * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: (sensor.selectedCost * sensor.minarea),
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              captureMode: sensor?.captureMode,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              valueAddedOption: this.formatVAR(sensor?.valueAddedOption),
              demTotalPoint: sensor?.demTotalPoint,
              demTotalPrice: sensor?.demTotalPrice,
              indices: sensor?.indices,
              super_resolution: sensor?.super_resolution,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName
            };
          } else {
            sensordata = {
              taskid: id,
              name: sensor.name,
              sensor: sensor.sensor,
              sensortype: sensor.sensortype,
              resolution: sensor.selectedResolution,
              cloudcover: sensor.selectedCC,
              latency: sensor.selectedLatencyKey,
              cost: parseFloat((this.target.area * sensor.selectedCost * no_of_repeats).toFixed(2)),
              unitCost: parseFloat((this.target.area * sensor.selectedCost * no_of_repeats).toFixed(2)),
              unit: sensor.unit,
              multiplierCost: (sensor.selectedCost * this.target.area),
              country: sensor.country,
              collectionMode: sensor.selectedModes,
              ONA: sensor.selectedONAOption,
              type: sensor?.type,
              satIds: sensor?.satIds,
              operatorKey: sensor?.key,
              operatorEmail: sensor?.operatorEmail,
              SZA: sensor?.SZA,
              AC: sensor?.AC,
              sarPolarization: sensor?.selectedPolarization,
              sarOrbitDirection: sensor?.selectedOrbitDirection,
              productAnciallary: sensor?.selectedAnciallary,
              satelliteName: sensor?.satelliteName,
              holdBack: sensor?.selectedHoldBackOption,
              supportFeasibility: sensor?.feasibility,
              siteName: sensor?.sitename,
              tnqSensorType: sensor?.selectedTnqSensorType,
              siteData: sensor?.siteData,
              captureMode: sensor?.captureMode,
              noOfUsers: sensor?.noOfUsers,
              minimumArea: sensor?.minimumAreaSize,
              isPoint:this.target.geoJSON.includes("POINT")?true:false,
              isCalculatedAreaSmaller:sensor?.minimumAreaSize > this.target.area ? true:false,
              areacalc:this.target.area,
              valueAddedOption: this.formatVAR(sensor?.valueAddedOption),
              demTotalPoint: sensor?.demTotalPoint,
              demTotalPrice: sensor?.demTotalPrice,
              indices: sensor?.indices,
              super_resolution: sensor?.super_resolution,
              obfuscateName: sensor?.obfuscateName,
              displayName: sensor.displayName
            };
          }
          if (sensor.key === OPERATORS.UMBRA) {            
            if (sensor.numberOfLooks) sensordata.numberOfLooks = sensor.numberOfLooks
            else sensordata.numberOfLooks = sensor.selectedResolution.numberOfLooks[0]
          }
        }
        selectedSensors.push(sensordata);
      }
    });
    
      /** Adding points variable to sensors */

      selectedSensors = selectedSensors.map(sensor => {
        let tNumber = 0;
        if (this.currentUser?.allocated) {
            if (sensor.isPoint === true) {
                tNumber = 1 * no_of_repeats;
                this.totalTaskNumber += tNumber;
            } else {
                if (sensor.unit === 'sqkm' || sensor.unit === 'scene' || sensor.unit === 'week' || sensor.unit === 'day') {
                    tNumber = (Math.ceil(sensor.areacalc / sensor.minimumArea) * no_of_repeats);
                    this.totalTaskNumber += tNumber;
                }
            }
        }
        const p = this.currentUser.pointsEligible ? this.convertCostToPoints(sensor.cost, false) : 0;
        return {
            ...sensor,
            point: p,
            taskNumber: tNumber
        };
      });
    const uniqueNames = new Set<string>();
    this.uniqueNamesData = [];

    for (const item of selectedSensors) {
      if (!uniqueNames.has(item.operatorKey)) {
        uniqueNames.add(item.operatorKey);
        this.uniqueNamesData.push(item);
      }
    }
    for (const sensors of this.uniqueNamesData) {
      if (
        feasibility &&
        this.target.area > 0 &&
        (sensors?.operatorKey === OPERATORS.SATELLOGIC ||
          sensors?.operatorKey === OPERATORS.CAPELLA)
      ) {
        const message = `${sensors?.displayName} ${sensors?.sensor} does not support AOI (area of interest) tasking by specific opportunity.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        click = false;
        return;
      } else if (
        feasibility &&
        sensors?.operatorKey === OPERATORS.CAPELLA &&
        !this.collectionTime
      ) {
        click = false;
        const dialog = this.dialog.open(OperatorPopupComponent, {
          minWidth: '50%',
          backdropClass: 'blurred',
          disableClose: true,
          data: { selectedRow: sensors, operatorKey: OPERATORS.CAPELLA },
        });

        dialog.afterClosed().subscribe((result) => {
          if (result != 'close') {
            this.collectionTime = true;
            this.collectionStart = result?.collectionStart;
            this.collectionEnd = result?.collectionEnd;
            click = true;
          } else {
            this.collectionTime = false;
            click = false;
            return;
          }
        });
      } else if (feasibility && this.target.selectedOption === 1) {
        const message =
          'Opportunity-based tasking is only available for specific date and period tasking. Please select automated tasking if you require recurrence.';
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        click = false;
        return;
      } else if (
        feasibility &&
        sensors?.operatorKey === OPERATORS.CAPELLA &&
        this.target.selectedOption === 0
      ) {
        const message = `Opportunity-based tasking for ${sensors?.displayName} ${sensors?.sensor} is only available for period tasking. Please select automated tasking if you require recurrence or specific date.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        return;
      } else if (
        feasibility &&
        sensors?.operatorKey === OPERATORS.SATELLOGIC &&
        days_difference > 8
      ) {
        const message = `Opportunity tasking for ${sensors?.displayName} ${sensors?.sensor} is available for the next eight days from the TOI start only. If you require tasking for a more extended period, please select automated tasking.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        click = false;
        return;
      } else if (
        this.target?.taskStrategy === 'Tip-and-Cue' &&
        this.target?.rule === 'rule-3' &&
        this.target.area > 0
      ) {
        const message =
          'Tip-and-cue is available for period taskings over points of interest (POI).';
        this.popupService.openPopup(WarningPopup, message, '', '50%');
        click = false;
        return;
      }
    }

    const isGHGIncluded = this.selectedSensors.some(item => item.key === OPERATORS.GHG);
    const isImagingSensorsIncluded = this.selectedSensors.some(item => (item.sensor != 'AIS' && item.sensor != 'RF' ));
    const isLBandIncluded = this.selectedSensors.some(item => (item.key === OPERATORS.LBAND));

    if (this.target?.multiTarget?.length > 1 && this.selectedSensors?.length > 1 && isGHGIncluded) {
      const message = `GHGSat Methane is currently not available together with other sensors for multi-point tasking.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      click = false;
      return
    }

    if (isImagingSensorsIncluded && isLBandIncluded) {
      const message = `You can only combine RF sensors with other RF sensors, such as AIS.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      click = false;
      return
    }

    if (isLBandIncluded && !this.rfSignals) {
      const message = `Please select at least one RF signal of interest from the options.`;
      this.popupService.openPopup(RequiredPopupComponent, message, '', '50%');
      click = false;
      return
    }

    if (this.target?.multiTarget?.length > 1 && this.selectedSensors?.length > 1 && isGHGIncluded) {
      const message = `GHGSat Methane is currently not available together with other sensors for multi-point tasking.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      click = false;
      return
    }

    if (this.target?.taskStrategy === 'Tip-and-Cue' && selectedSensors[0]?.tnqSensorType === 'Tipping') {
      selectedSensors = selectedSensors.reverse();
    }

    const currentDate: any = new Date();

    this.totalAmount = selectedSensors.reduce((a, { cost }) => a + cost, 0);
    this.taskPoint = this.currentUser.pointsEligible ? selectedSensors.reduce((total, sensor) => {
      return total + this.convertCostToPoints(sensor.cost,false);
    }, 0) : 0;
    newTaskParams['customerid'] = this.currentUser.id;
    newTaskParams['taskname'] = this.target.taskname;
    newTaskParams['purpose'] = this.target?.purposeOption;
    newTaskParams['area'] = this.target.area;
    newTaskParams['project'] = this.target.project;
    newTaskParams['fileName'] = this.target?.fileName;
    if (this.target.geotargets != null) {
      newTaskParams['target'] = this.target.geotargets;
    } else {
      newTaskParams['target'] = JSON.stringify(geoParse(this.target.geoJSON));
    }
    newTaskParams['geometryCentroid'] = this.target?.geometryCentroid;
    newTaskParams['aoiCountry'] = this.target.country;
    newTaskParams['aoiPlace'] = this.target.place;
    newTaskParams['sensors'] = JSON.stringify(selectedSensors);
    newTaskParams['taskcost'] = this.totalAmount.toFixed(2);
    newTaskParams['taskPoints'] = this.taskPoint;
    newTaskParams['frequency'] = this.target.selectedOption;
    if (this.target.selectedOption === 1) {
      newTaskParams['repeatFrequency'] = this.target.freqOption;
      newTaskParams['noOfRepeats'] = no_of_repeats;
      newTaskParams['subDailyCount'] = this.target.subDailyCount;
    } else {
      newTaskParams['repeatFrequency'] = '';
      newTaskParams['noOfRepeats'] = no_of_repeats;
      newTaskParams['subDailyCount'] = '';
    }
    newTaskParams['mode'] = this.target.mode;
    newTaskParams['expected_age'] = this.expectedAge;
    newTaskParams['circleCordinates'] = this.target.circleCordinates;

    newTaskParams['openWindow'] = moment(this.target.openWindow)
      .utcOffset(0, true)
      .format();
    newTaskParams['closeWindow'] = moment(this.target.closeWindow)
      .utcOffset(0, true)
      .format();
    newTaskParams['projectid'] = this.target?.projectId;
    newTaskParams['feasibilityTask'] = feasibility;
    newTaskParams['collectionStart'] = this.collectionStart;
    newTaskParams['collectionEnd'] = this.collectionEnd;
    newTaskParams['taskStrategy'] = this.target?.taskStrategy;
    newTaskParams['tipAndCueBasedOn'] = this.target?.tipAndCueBasedOn;
    newTaskParams['observation'] = this.target?.observation;
    newTaskParams['rule'] = this.target?.rule;
    newTaskParams['intent'] = this.target?.intent;
    newTaskParams['cloudCover'] = this.target?.cloudCover;
    newTaskParams['openWater'] = this.target?.openWater
    newTaskParams['totalTaskNumber'] = this.totalTaskNumber;

    if(this.target?.taskStrategy == "Help Select Sensor(s)"){
      newTaskParams['useCase'] = this.target?.useCase;
      newTaskParams['useCaseDescription'] = this.target?.useCaseDescription;
    }   
    

    if (click) {
      this.valid = false;
      this.enable = true;

      if (this.singleCircle && this.target?.multiTarget?.length === 3 && isLBandIncluded){
        let newTarget = this.groupPointsByOverlap(JSON.parse(newTaskParams['target']));
        newTaskParams['target'] = JSON.stringify(newTarget);
      }

      if ((this.target?.multiTarget?.length > 1 && !isGHGIncluded && !isLBandIncluded) || (this.target?.multiTarget?.length > 1 && isLBandIncluded && !this.singleCircle)
       || (this.singleCircle && this.target?.multiTarget?.length === 3 && isLBandIncluded)) {
        this.apiService.multiPoiTaskSubmit(newTaskParams).subscribe((res: any) => {
          if (res?.msg === 'Success') {
            let taskid;
            taskid = res?.taskid;
            const dialogRef = this.dialog.open(ConfirmDialog, {
              backdropClass: 'blurr', disableClose: true,
              data: { taskid: taskid, taskname: this.target.taskname, response: res, type: 'normal' },
            });

            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'Done') {
                this.resetTasking.emit('Reset');
              }
            });
          }
        }, (error: any) => {
          const dialogRef = this.dialog.open(CountrycheckComponent, {
            width: '50%', backdropClass: 'blurred', disableClose: true,
            data: { message: "fail" }
          });
        });
      } else {
        this.apiService.newTaskSubmit(newTaskParams).subscribe((res: any) => {
          // this.valid = false;    
          if (res?.msg === 'Success') {
            let data = JSON.parse(res?.response);
            let taskid;
            let sensorList = this.matchUUIDs(newTaskParams['sensors'],data);
            newTaskParams['sensors'] = JSON.stringify(sensorList);
            if (data && data.length > 0) {
              taskid = res?.taskid;
              newTaskParams['taskId'] = taskid;
              this.apiService
                .genCollection(
                  this.currentUser.id,
                  this.userDetails.email,
                  newTaskParams
                )
                .subscribe((res: any) => {
                  const taskid = res?.id;
                });
            }
            const dialogRef = this.dialog.open(ConfirmDialog, {
              backdropClass: 'blurr',
              disableClose: true,
              data: {
                taskid: taskid,
                taskname: this.target.taskname,
                response: res,
                type: 'normal',
              },
            });
            // console.log(dialogRef)
            // return
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'Done') {
                // window.location.reload();
                this.resetTasking.emit('Reset');
              }
            });
          }
        }, (error: any) => {
          const dialogRef = this.dialog.open(CountrycheckComponent, {
            width: '50%', backdropClass: 'blurred', disableClose: true,
            data: { message: "fail" }
          });
        });
      }
    }
  }

  matchUUIDs(selectedSensors, taskResponse) {
    let sensorList = JSON.parse(selectedSensors);

    const minLength = Math.min(sensorList.length, taskResponse.length);
    for (let i = 0; i < minLength; i++) {
      sensorList[i].uuid = taskResponse[i].uuid;
    }

    return sensorList;
  }

  extractAndCalculateValues(inputString: string): number {
    // Match decimal or integer numbers
    const numbers = inputString.match(/\d+(\.\d+)?/g);
  
    if (numbers && numbers.length >= 2) {
      const firstNumber = parseFloat(numbers[0]);
      const secondNumber = parseFloat(numbers[1]);
      return firstNumber * secondNumber;
    }
    return 0;
  }

  getTotalCost(checked: boolean, i: number, row: any) {
    this.isAISSensor = false;
    if (checked) {
      this.AVAILABLESENSORS[i].tnqSensorDisabled = false;
    } else {
      this.AVAILABLESENSORS[i].selectedTnqSensorType = '';
      this.AVAILABLESENSORS[i].tnqSensorDisabled = true;
    }

    this.collectionTime = false;
    const countryArr: string[] = row?.country;
    let countryCheck: boolean = false;
    let minimumArea: any, formattedMinimumArea: any;
    let isCountryRestricted: boolean = false;
    countryCheck =
      countryArr.includes(this.userDetails?.country) ||
      countryArr.includes('global');
    const baseCost = this.AVAILABLESENSORS[i].unitcost;
    if(row?.no_imagery_country) {
      isCountryRestricted = row?.no_imagery_country.includes(this.target.country);
    }    

    if ((row.unit === 'scene' || row.unit === 'week' || row.unit === 'day') && row.key != OPERATORS.BLACKSKY) {
      if (row.tooltipValue) {
        minimumArea = this.extractAndCalculateValues(row.tooltipValue);
        formattedMinimumArea = minimumArea?.toLocaleString();
        this.AVAILABLESENSORS[i].minimumAreaSize = minimumArea;
      } else if (row.minimumArea) {
        minimumArea = this.extractAndCalculateValues(row.minimumArea);
        formattedMinimumArea = minimumArea?.toLocaleString();
        this.AVAILABLESENSORS[i].minimumAreaSize = minimumArea;
      }
    } else {
      minimumArea = row.minarea;
      formattedMinimumArea = minimumArea?.toLocaleString();
      this.AVAILABLESENSORS[i].minimumAreaSize = minimumArea;
    }

    if (
      row?.key === OPERATORS.AT21 ||
      row?.key === OPERATORS.KOMPSAT ||
      row?.key === OPERATORS.ISI ||
      row?.key === OPERATORS.BLACKSKY ||
      row?.key === OPERATORS.GOKTURK ||
      row?.key === OPERATORS.CAPELLA ||
      row?.key === OPERATORS.ECURS
    ) {
      this.AVAILABLESENSORS[i].selectedCost = baseCost;
      this.AVAILABLESENSORS[i].userCost = 1;
    }
    let satellogicProcess = false;
    const startDate: any = new Date(
      moment(this.target.openWindow).utc().format()
    );
    const endDate: any = new Date(
      moment(this.target.closeWindow).utc().format()
    );
    const currentDate: any = new Date();
    const timeDiffrence: any =
      (startDate.getTime() - currentDate.getTime()) / 3600 / 1000;
    let futureDate = new Date();
    futureDate.setDate(currentDate.getDate() + 14);
    const startDateWithinRange =
      startDate >= currentDate && startDate <= futureDate;
    const endDateWithinRange = endDate >= currentDate && endDate <= futureDate;
    this.feasible = false;
    let standardResponseTime;

    if (row?.key === OPERATORS.AT21) {
      const latencyNumber = parseInt(row.selectedLatencyKey.latency.match(/\d+/)[0], 10);
      standardResponseTime = latencyNumber;
      if (row.selectedLatencyKey.latency === '24 hrs') standardResponseTime = 1;      
    }

    const isSameDate =
      startDate.getFullYear() === currentDate.getFullYear() &&
      startDate.getMonth() === currentDate.getMonth() &&
      startDate.getDate() === currentDate.getDate();

    const differenceInTime: number = endDate.getTime() - startDate.getTime();
    const differenceInDays: number = Math.floor(
      differenceInTime / (1000 * 60 * 60 * 24)
    );

    let start_time = moment(this.target.openWindow).utcOffset(0, true);
    let end_time = moment(this.target.closeWindow).utcOffset(0, true);
    const enddate = moment(this.target.closeWindow).utcOffset(0, true).format();
    const clydePeriodDuration = end_time.diff(start_time, 'days', true); 

    const time_difference =
      new Date(enddate).getTime() - new Date(currentDate).getTime();
    let days_Difference = Math.ceil(time_difference / (1000 * 60 * 60 * 24));

    if ((row?.key != OPERATORS.GHG && row?.key != OPERATORS.LBAND && row?.key != OPERATORS.CAPELLA && row?.key != OPERATORS.UMBRA &&
      row?.key != OPERATORS.SATELLOGIC && row?.key != OPERATORS.BLACKSKY) && this.target?.multiTarget?.length > 1 && checked) { 

      const message = `${row?.displayName} ${row?.sensor} does not support multiple points of interest (POIs) at this time.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
      this.feasible = true;
      return;
    }
    else if (row?.key === OPERATORS.SPIRE && this.target.geoJSON.includes('POINT') && checked) {
      const message = `${row?.displayName} ${row?.sensor} accepts only AOI (area) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.CLYDE &&
      this.target.geoJSON.includes('POINT') && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} accepts only AOI (area) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.SPIRE &&
      this.target.taskStrategy !== 'User Initiated' &&
      this.target.taskStrategy !== 'AIS Included'
      && checked
    ) {
      const message = `Please choose the 'AIS included/User Initiated' tasking strategy to use this sensor.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.CLYDE &&
      this.target.taskStrategy !== 'User Initiated' &&
      this.target.taskStrategy !== 'AIS Included'
      && checked
    ) {
      const message = `Please choose the 'AIS included/User Initiated' tasking strategy to use this sensor.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.SPIRE &&
      (this.target.selectedOption === 1 || this.target.selectedOption === 0)
      && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} accepts only  orders with a period collection at this time. Recurrent and specific date orders are a future capability. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.SPIRE &&
      this.target.area > 0 &&
      this.target.area > 10000
      && !this.target.targetPoints
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message =
        'The area of interest (AOI) requested is larger than the allowed 10,000 sqkm set by the operator. Please reduce the task size to less than 10,000 sqkm or choose an alternative provider.';
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    } else if (
      row?.key === OPERATORS.CLYDE &&
      (this.target.selectedOption === 1 || this.target.selectedOption === 0)
      && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} accepts only  orders with a period collection at this time. Recurrent and specific date orders are a future capability. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.CLYDE &&
      (this.target.selectedOption === 2 && clydePeriodDuration < 90)
      && checked
    ) {
        const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires TOI should be minimum 3 months. Please change your time of interest or choose an alternative provider.`;
        const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

        dialogRef.afterClosed().subscribe(() => {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            if (idx === i) {
              this.AVAILABLESENSORS[idx].checked = false;
              this.AVAILABLESENSORS[idx].prevent = true;
            }
          });
        });
      }

    else if (row?.key === OPERATORS.SPIRE && !this.target?.openWater && checked) {
      const message = `The ${row?.displayName} ${row?.sensor} can only be used for open water orders. Please change your area of interest (AOI).`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.CLYDE && !this.target?.openWater && checked) {
      const message = `The ${row?.displayName} ${row?.sensor} can only be used for open water orders. Please change your area of interest (AOI).`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if ((row?.key != OPERATORS.GHG) &&
      (row?.key != OPERATORS.LBAND) &&
      this.target?.multiTarget?.length > 1 &&
      (this.target.taskStrategy !== 'User Initiated' && this.target.taskStrategy !== 'Help Select Sensor(s)')) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message = `Multiple points of interest (POIs) tasking is only available for user initiated and Help Select Sensor(s) tasking strategies at this time.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      this.feasible = true;
    return;
  } else if ((row?.key != OPERATORS.GHG) &&
      (row?.key != OPERATORS.LBAND) &&
      this.target?.multiTarget?.length > 1 &&
      this.currentUser.pointsEligible) { 
 
      const message = `Multiple points of collection (POIs) and areas of collections (AOIs) are
      not available for points usage at this time. Please task single POI or AOI.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
      this.feasible = true;
    return;
    } else if ((row?.key === OPERATORS.CAPELLA || row?.key === OPERATORS.UMBRA ||
      row?.key === OPERATORS.SATELLOGIC || row?.key === OPERATORS.BLACKSKY) && (this.target?.multiTarget?.length > 3 || (this.target?.multiTarget?.length > 1 && this.selectedSensors?.length > 1)) && checked) { 
  
      const message = `Multiple points of interest (POIs) tasking is limited to three locations and two sensors.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
      return;
    } else if (row?.key === OPERATORS.SATELLOGIC && timeDiffrence > 336) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      satellogicProcess = true;
      const dialogRef = this.dialog.open(SatellogicWarningComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: {
          satellogicProcess: satellogicProcess,
          name: row.displayName + ' ' + row.sensor,
        },
      });
    } else if (
      row?.key === OPERATORS.SATELLOGIC &&
      this.target.selectedOption === 1 &&
      this.target.area > 0
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      satellogicProcess = false;
      const dialogRef = this.dialog.open(SatellogicWarningComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: {
          satellogicProcess: satellogicProcess,
          name: row.displayName + ' ' + row.sensor,
        },
      });
    } else if (
      row?.key === OPERATORS.SATELLOGIC &&
      this.target.subDailyCount != 2 &&
      this.target.freqOption === 'Sub-daily' &&
      this.target.selectedOption === 1
    ) {
      const message = `${row?.displayName} ${row?.sensor} allows 2 sub-daily collection(s)  at this time. Please select an appropriate sub-daily count.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.SATELLOGIC &&
      this.target.area > 0 &&
      this.target?.openWater
    ) {
      const message = `${row?.displayName} ${row?.sensor} accepts only POI (point of interest) tasks over open waters. Please choose an alternative provider for an AOI (area of interest) task.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.SATELLOGIC &&
      this.target.area > 0 && this.target.area < 50 &&
      this.cpcAndAllocated
    ) {
      const message = `Please task with point of interest (POI) when the area is less than 50sqkm.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.CAPELLA &&
      this.target.selectedOption === 1 &&
      this.target.area > 0
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      satellogicProcess = false;
      const dialogRef = this.dialog.open(SatellogicWarningComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { satellogicProcess: satellogicProcess, name: row.displayName },
      });
    } else if (
      row?.key === OPERATORS.CAPELLA &&
      this.target.area > 0 &&
      CAPELLA_MODES[row?.selectedModes[0]?.mode_type] === 'POI'
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message = `${row?.displayName} ${row?.sensor} has different modes of collection based on whether the location
      is an area (AOI) or point (POI) of interest. Stripmap_100 is used for AOI,
      and all the other modes are used for POI.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    } else if (
      row?.key === OPERATORS.CAPELLA &&
      this.target.area === 0 &&
      CAPELLA_MODES[row?.selectedModes[0]?.mode_type] === 'AOI'
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message = `${row?.displayName} ${row?.sensor} has different modes of collection based on whether the location
      is an area (AOI) or point (POI) of interest. Stripmap_100 is used for AOI,
      and all the other modes are used for POI.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    } else if (
      row?.key === OPERATORS.AT21 &&
      (this.target.selectedOption === 1 || this.target.selectedOption === 0)
      && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} accepts only  orders with a period collection at this time. Recurrent and specific date orders are a future capability. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.AT21 &&
      this.target.area > 0 &&
      !this.utilsService.calculateMinSwathWidth(this.target.geoJSON)
      && checked
    ) {
      const message =
        'Each line in the area of interest (AOI) must be 5km minimum long. Please redraw your AOI.';
        const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

        dialogRef.afterClosed().subscribe(() => {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            if (idx === i) {
              this.AVAILABLESENSORS[idx].checked = false;
              this.AVAILABLESENSORS[idx].prevent = true;
            }
          });
        });
    }

    else if (row?.key === OPERATORS.AT21 && differenceInDays < standardResponseTime && checked) {
      const message = `The time of interest (TOI) is less than the selected tasking tier response time. Please change the tasking tier or amend the TOI.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }

    else if (row?.key === OPERATORS.USL && timeDiffrence < 12 && checked) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start time to be current date + 12hrs. Please change your time of interest or choose an alternative provider.`;

      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }
    
     else if (row?.key === OPERATORS.UMBRA && this.target.area > 0) {
      const message = `${row?.displayName} ${row?.sensor} accepts only POI (point of interest) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.SATVU && this.target.area > 0) {
      const message = `${row?.displayName} ${row?.sensor} accepts only POI (point of interest) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.UMBRA &&
      this.target.selectedOption === 1
    ) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.HEAD &&
      this.target.area > 0 &&
      !this.utilsService.calculateMinSwathWidth(this.target.geoJSON)
      && checked
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (operator.key === OPERATORS.HEAD) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message =
        'Each line in the area of interest (AOI) must be 5km minimum long. Please redraw your AOI.';
      this.popupService.openPopup(WarningPopup, message, '', '50%');
     } else if (row?.key === OPERATORS.HEAD && this.target.area > 0 && row?.name === 'Video') {
      const message = `${row?.displayName} accepts only POI (point of interest) orders. Please change to a lng/lat and try again.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.HEAD && isSameDate && row?.name === 'Video') {
      const message = `We are unable to accept this task as ${row?.displayName} requires start date to be current date+1. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.HEAD &&
      this.target.selectedOption === 1 && row?.name === 'Video'
    ) {
      const message = `${row?.displayName} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.HEAD && isSameDate && row?.name != 'Video') {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date+1. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.HEAD &&
      this.target.selectedOption === 1 && row?.name != 'Video'
    ) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.STE && this.target?.polygon) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} does not accept polygon. Please change your area of interest (AOI) or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.STE &&
      timeDiffrence < 24 &&
      row?.sensortype === 'SAR'
    ) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date + 24hrs. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.STE &&
      timeDiffrence < 48 &&
      row?.sensortype != 'SAR'
    ) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date + 48hrs. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.STE &&
      this.target.subDailyCount > 2 &&
      this.target.freqOption === 'Sub-daily' &&
      this.target.selectedOption === 1
    ) {
      const message = `${row?.displayName} ${row?.sensor} allows 2 sub-daily collection(s) at this time. Please select an appropriate sub-daily count.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.STE &&
      !this.utilsService.isLatitudeInRange(this.target, -13, 13)
    ) {
      const message = `${row?.displayName} ${row?.sensor} cannot be tasked outside the latitude range between -13 and 13. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.KOMPSAT &&
      ((this.target?.fileName && this.target?.fileName.includes('kml')) ||
        (this.target?.fileName && this.target?.fileName.includes('kmz')))
        && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} only accepts JSON file format. Please convert your KML, SHP or any other file formats to JSON and upload again.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.KOMPSAT &&
      this.target.selectedOption === 1 && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.KOMPSAT && isSameDate && checked) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date+1. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.KOMPSAT && countryCheck && checked) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (operator?.key === OPERATORS.KOMPSAT) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const dialogRef = this.dialog.open(CountrycheckComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'countryblock', sensor: row.displayName },
      });
    } else if (row?.key === OPERATORS.USL && this.target.area === 0 && checked) {
      const message = `${row?.displayName} ${row?.sensor} accepts only AOI (area of interest) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.USL &&
      (this.target.subDailyCount === 7 || 
        this.target.subDailyCount === 5
      )
      && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} not allows 5 and 7 sub-daily collection(s)  at this time. Please select an appropriate sub-daily count.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.ISI && this.target.selectedOption === 1 && checked) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.ISI && timeDiffrence < 24 && checked) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date + 24hrs. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.ISI &&
      !this.utilsService.isLatitudeInRange(this.target, -42, 42) && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} cannot be tasked outside the latitude range between -42 and 42. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.AXELSPACE &&
      this.target.selectedOption === 1
    ) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }

    else if (row?.key === OPERATORS.AXELSPACE && this.target.selectedOption === 0) {
      const message = `${row?.displayName} ${row?.sensor} does not support specific date at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }

    else if (row?.key === OPERATORS.AXELSPACE && this.target.area === 0) {
      const message = `${row?.displayName} ${row?.sensor} accepts only AOI (area of interest) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.AXELSPACE && isSameDate) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date+1. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.AXELSPACE &&
      (endDateWithinRange === false || startDateWithinRange === false)
    ) {
      const message = `${row?.displayName} ${row?.sensor} requires the time of interest (TOI) to be 14 days or less from the current date. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }

    else if (row?.key === OPERATORS.AXELSPACE && (this.target.area < 100)) {
      const message = 'The ' + row?.displayName + ' ' + row?.sensor + '  has a requirement for 100 sqkm as the minimum task area. Your area of interest (AOI) is less than 100 sqkm. Kindly increase the size of the polygon or choose an alternative provider.';
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }
    
    else if (row?.key === OPERATORS.AXELSPACE && (this.target.area > 900)) {
      const message = 'The AOI (area of interest) must be within 30 km each in length and width.';
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    }

    else if (row?.key === OPERATORS.GHG && this.target.freqOption != 'Monthly' && this.target.selectedOption === 1) {
      const message = `${row?.displayName} ${row?.sensor} only support monthly recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.GHG && differenceInDays < 30) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires TOI should be minimum 30 days. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.GHG && this.target.selectedOption === 0) {
      const message = `${row?.displayName} ${row?.sensor} does not support specific date at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.HEAD &&
      this.target.area > 0 &&
      this.target.area < minimumArea
    ) {
      const message =
        'The ' +
        row?.displayName +
        ' ' +
        row?.sensor +
        '  has a requirement for ' +
        formattedMinimumArea +
        ' sqkm as the minimum task area. Your area of interest (AOI) is less than ' +
        formattedMinimumArea +
        ' sqkm. Kindly increase the size of the polygon or choose an alternative provider.';
        const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

        dialogRef.afterClosed().subscribe(() => {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            if (idx === i) {
              this.AVAILABLESENSORS[idx].checked = false;
              this.AVAILABLESENSORS[idx].prevent = true;
            }
          });
        });
    } else if (
      row?.key === OPERATORS.HEAD &&
      this.target.area === 0 &&
      (endDateWithinRange === false || startDateWithinRange === false)
      && row?.name === 'Video'
    ) {
      const message = `${row?.displayName} requires the time of interest (TOI) to be 14 days or less from the current date. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.HEAD &&
      this.target.area === 0 &&
      (endDateWithinRange === false || startDateWithinRange === false)
      && row?.name != 'Video'
    ) {
      const message = `${row?.displayName} ${row?.sensor} requires the time of interest (TOI) to be 14 days or less from the current date. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.BLACKSKY && this.target.area > 0 && checked) {
      const message = `${row?.displayName} ${row?.sensor} accepts only POI (point of interest) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.BLACKSKY &&
      this.target.freqOption === 'Sub-daily' &&
      this.target.selectedOption === 1 &&
      this.target.subDailyCount > 4 && checked
    ) {
      const message = `${row?.displayName} ${row?.sensor} allows 4 sub-daily collection(s)  at this time. Please select or revisit number accordingly.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.BLACKSKY &&
      this.target.freqOption === 'Sub-daily' &&
      this.target.selectedOption === 1 &&
      differenceInDays < 7 && checked
    ) {
      const message = `The sub-daily tasking option for ${row?.displayName} ${row?.sensor} is only available for a minimum of seven days. Please choose an alternative provider if you require sub-daily tasking for less than seven days.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.ORORATECH &&
      (this.target.selectedOption === 1 || this.target.selectedOption === 0)
    ) {
      const message = `${row?.displayName} ${row?.sensor} accepts only  orders with a period collection at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.ORORATECH && days_Difference > 7) {
      const message = `The ${row?.displayName} ${row?.sensor} can only be tasked in the next seven days. Please ensure your time of interest (TOI) is within seven days from today.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.WYVERN && this.target.area === 0) {
      const message = `${row?.displayName} ${row?.sensor} accepts only AOI (area of interest) orders. Please choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.WYVERN &&
      this.target.selectedOption === 1
    ) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.WYVERN &&
      this.target.area > 0 &&
      this.target.area > 5000
    ) {
      const message =
        'The area of interest (AOI) requested is larger than the allowed 5000 sqkm set by the operator. Please reduce the task size to less than 5000 sqkm or choose an alternative provider.';
        const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

        dialogRef.afterClosed().subscribe(() => {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            if (idx === i) {
              this.AVAILABLESENSORS[idx].checked = false;
              this.AVAILABLESENSORS[idx].prevent = true;
            }
          });
        });
    } else if (row?.key === OPERATORS.WYVERN && timeDiffrence < 72) {
      const message = `We are unable to accept this task as ${row?.displayName} ${row?.sensor} requires start date to be current date + 72hrs. Please change your time of interest or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.WYVERN &&
      this.target.area > 0 &&
      !this.utilsService.calculateMinSwathWidth(this.target.geoJSON)
    ) {
      const message =
        'Each line in the area of interest (AOI) must be 5km minimum long. Please redraw your AOI.';
        const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

        dialogRef.afterClosed().subscribe(() => {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            if (idx === i) {
              this.AVAILABLESENSORS[idx].checked = false;
              this.AVAILABLESENSORS[idx].prevent = true;
            }
          });
        });
    } else if (
      row?.key === OPERATORS.WYVERN &&
      this.checkEndDate(startDate, endDate)
    ) {
      const message = `The time of interest (TOI) end date cannot exceed 12 months from the start
      date. Please change your TOI or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.LBAND && this.target.area > 0 && !this.target.targetPoints) {
 
      const message = `RF band(s) detection, such as UHF, VHF, and L-band, is a point-based (POI) task but covers a large area given the satellite's field of view. 
      Therefore, combining these signal detections with non imaging sensors is possible, provided Multi-RF is selected ahead of other sensors.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.LBAND &&
        (this.target.subDailyCount > 2 &&
        this.target.freqOption === 'Sub-daily' &&
        this.target.selectedOption === 1)
    ) {
      const message = `RF bands detection is possible up to two subdaily counts. Please select appropriate subdaily count.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.LBAND && this.target?.multiTarget?.length > 3) {
      const message = `The tasking of multiple points of interest (POIs) in a single task is limited to three subtasks.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (
      row?.key === OPERATORS.GOKTURK &&
      this.target.selectedOption === 1 && checked
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    } else if (
      row?.key === OPERATORS.GOKTURK && isCountryRestricted && checked) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message =
        'The operator has not allowed the tasking of the sensor at the country tasked. Please continue with other sensors.';
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    } else if (row?.key === OPERATORS.GOKTURK && countryCheck && checked) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (operator?.key === OPERATORS.GOKTURK) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const dialogRef = this.dialog.open(CountrycheckComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'countryblock', sensor: row.displayName },
      });
    } else if (
      row?.key === OPERATORS.GOKTURK &&
      this.target.area > 0 &&
      !this.utilsService.calculateMinSwathWidth(this.target.geoJSON) && checked
    ) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const message =
        'Each line in the area of interest (AOI) must be 5km minimum long. Please redraw your AOI.';
      this.popupService.openPopup(WarningPopup, message, '', '50%');
    } else if (
      row?.key === OPERATORS.SATVU &&
      this.target.selectedOption === 1
    ) {
      const message = `${row?.displayName} ${row?.sensor} does not support recurrency at this time. Please change your mode of frequency or choose an alternative provider.`;
      const dialogRef = this.popupService.openPopup(WarningPopup, message, '', '50%');

      dialogRef.afterClosed().subscribe(() => {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].prevent = true;
          }
        });
      });
    } else if (row?.key === OPERATORS.SATVU && checked) {
      const dialog = this.dialog.open(OperatorPopupComponent, {
        minWidth: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { selectedRow: row?.key, operatorKey: OPERATORS.SATVU },
      });

      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          if (result.dayMode && result.nightMode) {
            this.captureMode = 'day-night';
          } else if (result.nightMode) {
            this.captureMode = 'night';
          } else {
            this.captureMode = 'day';
          }
          this.AVAILABLESENSORS[i].captureMode = this.captureMode;
          this.AVAILABLESENSORS[i].checked = true;
          this.selectedSensors = [];
          this.AVAILABLESENSORS.forEach((operator) => {
            if (operator.checked) this.selectedSensors.push(operator);
          });
          for (const sensors of this.selectedSensors) {
            if (!sensors.feasibility) {
              this.feasible = true;
            }
          }
          this.clickHandler();
        } else {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            this.AVAILABLESENSORS[i].checked = false;
          });
        }
      });
    } else if (
        row?.key === OPERATORS.CAPELLA && checked && row.collection_mode[0]?.captureMode === 'Strip'
    ) {
      const dialog = this.dialog.open(OperatorPopupComponent, {
        minWidth: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { selectedRow: row, operatorKey: row?.key, type: 'imageWidth'
         },
      });

      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          this.AVAILABLESENSORS[i].imageWidth = result;

          if ((this.target?.intent.key === 'internalUse' ||
            this.target?.intent.key === 'internalAndDistribution') &&
          this.target.taskStrategy != 'Tip-and-Cue') {
            const dialog = this.dialog.open(OperatorPopupComponent, {
              minWidth: '50%',
              backdropClass: 'blurred',
              disableClose: true,
              data: { selectedRow: row, operatorKey: row?.key, daysCount: days_Difference,
                subscriptonAmount: row?.selectedHistoricalPrice?.data?.price, type: 'uplift'
               },
            });
  
            dialog.afterClosed().subscribe((result) => {
              if (result != 'close') {
                this.AVAILABLESENSORS[i].noOfUsers = result.value;
                this.AVAILABLESENSORS[i].userCost = result.key;
                this.AVAILABLESENSORS[i].selectedCost = baseCost * result.key;
  
                this.AVAILABLESENSORS[i].checked = true;
                this.selectedSensors = [];
                this.AVAILABLESENSORS.forEach((operator) => {
                  if (operator.checked) this.selectedSensors.push(operator);
                });
                for (const sensors of this.selectedSensors) {
                  if (sensors.key === OPERATORS.SPIRE) {
                    this.isAISSensor = true;
                  }
                  if (!sensors.feasibility) {
                    this.feasible = true;
                  }
                }
      
                this.clickHandler();
              } else {
                this.AVAILABLESENSORS.forEach((operator, idx) => {
                  this.AVAILABLESENSORS[i].checked = false;
                });
              }
            });
          }
        } else {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            this.AVAILABLESENSORS[i].checked = false;
          });
        }
      });
    } else if (
      (row?.key === OPERATORS.SPIRE && checked) ||
      (row?.key === OPERATORS.CLYDE && checked) ||
      ((row?.key === OPERATORS.AT21 ||
        row?.key === OPERATORS.KOMPSAT ||
        row?.key === OPERATORS.ISI ||
        row?.key === OPERATORS.BLACKSKY ||
        row?.key === OPERATORS.GOKTURK ||
        row?.key === OPERATORS.CAPELLA ||
        row?.key === OPERATORS.ECURS) &&
        checked &&
        (this.target?.intent.key === 'internalUse' ||
          this.target?.intent.key === 'internalAndDistribution') &&
        this.target.taskStrategy != 'Tip-and-Cue')
    ) {
      const dialog = this.dialog.open(OperatorPopupComponent, {
        minWidth: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { selectedRow: row, operatorKey: row?.key, daysCount: days_Difference,
          subscriptonAmount: row?.selectedHistoricalPrice?.data?.price, type: 'uplift'
         },
      });

      dialog.afterClosed().subscribe((result) => {
        if (result != 'close') {
          const historicalCost = result.isHistorical? 25 : 0;
          if(this.AVAILABLESENSORS[i].key === OPERATORS.SPIRE) {
            this.AVAILABLESENSORS[i].noOfRefreshes = result?.refreshCount;
            this.AVAILABLESENSORS[i].isHistorical = result?.isHistorical;
            this.AVAILABLESENSORS[i].historicalCost = historicalCost;
            this.AVAILABLESENSORS[i].noOfHistoricalData = result?.fetchCount;

            if (
              this.target.area > 0
              && this.target.targetPoints
            ) {
              const pointString = this.target.targetPoints[0];
              const geoJSON = this.convertToGeoJSON(pointString);
              this.target.geometryCentroid = geoJSON;
              row.centroid = this.target.targetPoints[0];
              let items: any = [];
              items.push(row);
              this.childDataEvent.emit(items);
            }
          } else if (this.AVAILABLESENSORS[i].key === OPERATORS.CLYDE) {
            this.AVAILABLESENSORS[i].isHistorical = result?.isHistorical;
            this.AVAILABLESENSORS[i].noOfHistoricalData = result?.daysCount;
          } else {
            this.AVAILABLESENSORS[i].noOfUsers = result.value;
            this.AVAILABLESENSORS[i].userCost = result.key;
            this.AVAILABLESENSORS[i].selectedCost = baseCost * result.key;
          }
          this.AVAILABLESENSORS[i].checked = true;
          this.selectedSensors = [];
          this.AVAILABLESENSORS.forEach((operator) => {
            if (operator.checked) this.selectedSensors.push(operator);
          });
          for (const sensors of this.selectedSensors) {
            if (sensors.key === OPERATORS.SPIRE) {
              this.isAISSensor = true;
            }
            if (!sensors.feasibility) {
              this.feasible = true;
            }
          }

          this.clickHandler();
        } else {
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            this.AVAILABLESENSORS[i].checked = false;
          });
        }
      });
    } else if ((row?.key === OPERATORS.GHG || row?.key === OPERATORS.LBAND) && checked) {
      let frequency = this.target.selectedOption;
      this.singleCircle = null;
      var _this = this;
      let numberOfRepeats = this.calculateRepeats();

      if (row?.key === OPERATORS.LBAND) {
        let items: any = [];
        items.push(row);
        this.childDataEvent.emit(items);
        this.AVAILABLESENSORS[i].overlapPercentage = this.localStorageService.getOverlapValue();
        let overlapCount = this.localStorageService.getOverlapCount();
       
        if (this.target?.multiTarget?.length === 3 && overlapCount > 1) {
          this.singleCircle = null;
          const message = `There can only be one overlapping circle per task.`;
          this.popupService.openPopup(WarningPopup, message, '', '50%');
          this.AVAILABLESENSORS.forEach((operator, idx) => {
            if (idx === i) {
              this.AVAILABLESENSORS[idx].checked = false;
              this.AVAILABLESENSORS[idx].prevent = true;
            }
          });
        } else if (!row?.signalOfInterest || row?.signalOfInterest?.length === 0) {
          this.singleCircle = null;
          const message = `Please select at least one RF signal of interest from the options.`;
          this.popupService.openPopup(RequiredPopupComponent, message, '', '50%');
          this.resetSensor();
        } else {
          this.singleCircle = false;
          this.resetSensor();
        }
       }

      else if (row?.key === OPERATORS.GHG) {
        if (this.AVAILABLESENSORS[i].prevent === true) {
          this.AVAILABLESENSORS[i].prevent = false;
          this.AVAILABLESENSORS[i].checked = false;
        }
        this.checkTargetLocation().then(result => {
          switch (result) {
            case 'water':
              if (row?.selectedModes[0]?.productLevel.includes('Onshore')) {
                this.AVAILABLESENSORS.forEach((operator, idx) => {
                  if (idx === i) {
                    this.AVAILABLESENSORS[idx].checked = false;
                    this.AVAILABLESENSORS[idx].prevent = true;
                  }
                });
  
                const message = `${row?.displayName} ${row?.sensor} has different products based on whether the tasked area is over open waters or land. 
                Please change your product choice or change the location of your task.`;
                this.popupService.openPopup(WarningPopup, message, '', '50%');
  
                this.selectedSensors = [];
                this.AVAILABLESENSORS.forEach((operator) => {
                  if (operator.checked) this.selectedSensors.push(operator);
                });
                this.clickHandler();
              } else openPopup();
              break;
            case 'land':
              if (row?.selectedModes[0]?.productLevel.includes('Offshore')) {
                this.AVAILABLESENSORS.forEach((operator, idx) => {
                  if (idx === i) {
                    this.AVAILABLESENSORS[idx].checked = false;
                    this.AVAILABLESENSORS[idx].prevent = true;
                  }
                });
  
                const message = `${row?.displayName} ${row?.sensor} has different products based on whether the tasked area is over open waters or land. 
                Please change your product choice or change the location of your task.`;
                this.popupService.openPopup(WarningPopup, message, '', '50%');
  
                this.selectedSensors = [];
                this.AVAILABLESENSORS.forEach((operator) => {
                  if (operator.checked) this.selectedSensors.push(operator);
                });
                this.clickHandler();
              } else openPopup();
              break;
            case 'both':
              this.AVAILABLESENSORS.forEach((operator, idx) => {
                if (idx === i) {
                  this.AVAILABLESENSORS[idx].checked = false;
                  this.AVAILABLESENSORS[idx].prevent = true;
                }
              });
  
              const message = `${row?.displayName} ${row?.sensor} has different products based on whether the tasked area is over open waters or land. 
              Please change your product choice or change the location of your task.`;
              this.popupService.openPopup(WarningPopup, message, '', '50%');
  
              this.selectedSensors = [];
              this.AVAILABLESENSORS.forEach((operator) => {
                if (operator.checked) this.selectedSensors.push(operator);
              });
              this.clickHandler();
              break;
            default:
              console.error('Unexpected result:', result);
          }
        });
      } 

      function openPopup() {
        const dialog = _this.dialog.open(OperatorPopupComponent, {
          minWidth: '50%',
          backdropClass: 'blurred',
          disableClose: true,
          data: {
            selectedRow: row,
            target:
              _this.target?.multiTarget?.length > 0
                ? _this.target?.multiTarget
                : [_this.target.geoJSON],
            operatorKey: row?.key,
            frequency: frequency,
            repeatFrequency: _this.target?.freqOption,
            differenceInDays: differenceInDays,
            numberOfRepeats: numberOfRepeats
          },
        });
  
        dialog.afterClosed().subscribe((result) => {
          if (result != 'close') {
            _this.AVAILABLESENSORS[i].siteData = result.siteData;
            
             _this.AVAILABLESENSORS[i].noOfCollections = result?.numberOfCollections;

            _this.AVAILABLESENSORS[i].checked = true;
            _this.selectedSensors = [];
            _this.AVAILABLESENSORS.forEach((operator) => {
              if (operator.checked) _this.selectedSensors.push(operator);
            });
            for (const sensors of _this.selectedSensors) {
              if (!sensors.feasibility) {
                _this.feasible = true;
              }
            }
            _this.clickHandler();
          } else {
            _this.AVAILABLESENSORS.forEach((operator, idx) => {
              _this.AVAILABLESENSORS[i].checked = false;
            });
          }
        });
      }
    } else if (row?.key != OPERATORS.KOMPSAT && !countryCheck && checked) {
      this.AVAILABLESENSORS.forEach((operator, idx) => {
        if (idx === i) {
          this.AVAILABLESENSORS[idx].checked = false;
          this.AVAILABLESENSORS[idx].prevent = true;
        }
      });
      const dialogRef = this.dialog.open(CountrycheckComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: { message: 'countryblock', sensor: row.displayName },
      });
    } else {
      if (!this.AVAILABLESENSORS) return;
      if (
        row?.key === OPERATORS.SATELLOGIC &&
        this.target.area === 0 &&
        this.target?.openWater &&
        checked
      ) {
        const message = `The tasked area is over open waters, and the emergency tasking is the only
        option available.`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
      } else if (
        row?.key != OPERATORS.HEAD &&
        this.target.area > 0 &&
        this.target.area < minimumArea &&
        checked
      ) {
        const message =
          'The ' +
          row?.displayName +
          ' ' +
          row?.sensor +
          ' has a requirement for  ' +
          formattedMinimumArea +
          ' sqkm as the minimum task area. Please note that you will be charged for ' +
          formattedMinimumArea +
          ' sqkm.';
        this.popupService.openPopup(WarningPopup, message, '', '50%');
      } else if (
        row?.key === OPERATORS.CAPELLA &&
        differenceInDays < 7 &&
        row.selectedLatencyKey.key === 'standard' &&
        checked
      ) {
        const message = `${row?.displayName} ${row?.sensor} requires a minimum seven day window for standard tasking. Any lesser, it may be rejected. Please provide a minimum of seven day time of interest (TOI).`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
      } else if (
        row?.key === OPERATORS.CAPELLA &&
        differenceInDays < 3 &&
        row.selectedLatencyKey.key === 'priority' &&
        checked
      ) {
        const message = `${row?.displayName} ${row?.sensor} requires a minimum three day window for priority tasking. Any lesser, it may be rejected. Please provide a minimum of three day time of interest (TOI).`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
      }
      else if (row?.key === OPERATORS.CAPELLA && differenceInDays < 1 && row.selectedLatencyKey.key === 'rush' && checked) {
        const message = `${row?.displayName} ${row?.sensor} requires a minimum one day window for rush tasking. Any lesser, it may be rejected. Please provide a minimum of one day time of interest (TOI).`;
        this.popupService.openPopup(WarningPopup, message, '', '50%');
      }

      this.AVAILABLESENSORS[i].checked = checked;
      if (this.target.taskStrategy == 'Tip-and-Cue') {
        if (
          (row?.key === OPERATORS.AT21 ||
            row?.key === OPERATORS.KOMPSAT ||
            row?.key === OPERATORS.ISI ||
            row?.key === OPERATORS.BLACKSKY ||
            row?.key === OPERATORS.GOKTURK ||
            row?.key === OPERATORS.CAPELLA ||
            row?.key === OPERATORS.ECURS) &&
          checked &&
          (this.target?.intent.key === 'internalUse' ||
            this.target?.intent.key === 'internalAndDistribution')
        ) {
          this.AVAILABLESENSORS[i].checked = false;
          const dialog = this.dialog.open(OperatorPopupComponent, {
            minWidth: '50%',
            backdropClass: 'blurred',
            disableClose: true,
            data: { selectedRow: row, operatorKey: row?.key,type: 'uplift' },
          });

          dialog.afterClosed().subscribe((result) => {
            if (result != 'close') {
              this.AVAILABLESENSORS[i].noOfUsers = result.value;
              this.AVAILABLESENSORS[i].userCost = result.key;
              this.AVAILABLESENSORS[i].selectedCost = baseCost * result.key;
              this.AVAILABLESENSORS[i].checked = true;
              this.selectedSensors = [];
              this.AVAILABLESENSORS.forEach((operator) => {
                if (operator.checked) this.selectedSensors.push(operator);
              });

              if (this.selectedSensors.length > 2) {
                this.selectedSensors.shift();
              }

              for (const sensors of this.selectedSensors) {
                if (!sensors.feasibility) {
                  this.feasible = true;
                }
              }

              for (const sensor of this.AVAILABLESENSORS) {
                let countIndex = this.selectedSensors.findIndex(
                  (item) =>
                    item.name === sensor.name &&
                    item.resolution[0].value === sensor.resolution[0].value
                );
                if (countIndex === -1 && sensor.checked) {
                  sensor.checked = false;
                  sensor.selectedTnqSensorType = '';
                  sensor.tnqSensorDisabled = true;
                }
              }
            } else {
              this.AVAILABLESENSORS.forEach((operator, idx) => {
                this.AVAILABLESENSORS[i].checked = false;
                checked = false;
              });
            }

            this.tipAndQSelectedRows = this.AVAILABLESENSORS.filter(
              (operator) =>
                operator.checked &&
                operator.selectedTnqSensorType == '' &&
                this.target.taskStrategy === 'Tip-and-Cue'
            );
            this.tipAndQSelected =
              this.tipAndQSelectedRows.length === 0 ? false : true;
          });
        } else {
          if (checked) {
            this.selectedSensors.push(this.AVAILABLESENSORS[i]);
            if (this.selectedSensors.length > 2) {
              this.selectedSensors.shift();
            }
          } else {
            let countIndex = this.selectedSensors.findIndex(
              (item) =>
                item.name === this.AVAILABLESENSORS[i].name &&
                item.resolution[0].value ===
                  this.AVAILABLESENSORS[i].resolution[0].value
            );
            if (countIndex !== -1) {
              this.selectedSensors.splice(countIndex, 1);
            }
          }

          for (const sensor of this.AVAILABLESENSORS) {
            let countIndex = this.selectedSensors.findIndex(
              (item) =>
                item.name === sensor.name &&
                item.resolution[0].value === sensor.resolution[0].value
            );
            if (countIndex === -1 && sensor.checked) {
              sensor.checked = false;
              sensor.selectedTnqSensorType = '';
              sensor.tnqSensorDisabled = true;
            }
          }

          this.tipAndQSelectedRows = this.AVAILABLESENSORS.filter(
            (operator) =>
              operator.checked &&
              operator.selectedTnqSensorType == '' &&
              this.target.taskStrategy === 'Tip-and-Cue'
          );
          this.tipAndQSelected =
            this.tipAndQSelectedRows.length === 0 ? false : true;
        }
      } else {
        this.selectedSensors = [];
        this.AVAILABLESENSORS.forEach((operator) => {
          if (operator.checked) this.selectedSensors.push(operator);
        });
      }
      this.clickHandler();
    }

    if (row?.key === OPERATORS.LBAND && !checked) {
      this.resetRFData.emit();
    }

    if (row?.key === OPERATORS.SPIRE && !checked) {
      this.resetSpireData.emit();
    }

    for (const sensors of this.selectedSensors) {
      if (sensors.key === OPERATORS.SPIRE) {
        this.isAISSensor = true;
      }
      if (!sensors.feasibility) {
        this.feasible = true;
      }
    }
  }

  convertToGeoJSON(pointString: string): string {
    // Extract coordinates from the string
    const match = pointString.match(/POINT\s*\(([-\d.]+)\s+([-\d.]+)\)/);
  
    if (!match) {
      throw new Error('Invalid POINT format');
    }
  
    // Parse the coordinates
    const longitude = parseFloat(match[1]);
    const latitude = parseFloat(match[2]);
  
    // Construct the GeoJSON object
    const geoJSON = {
      type: 'Point',
      coordinates: [longitude, latitude],
    };
  
    // Convert to JSON string
    return JSON.stringify(geoJSON);
  }

  
  getPlaceHolder(row: any, indx: any) {
    if (this.target.area > 0 && row?.key === OPERATORS.SATELLOGIC) {
      return 'Area';
    } else if (
      this.target.area > 0 &&
      row?.key === OPERATORS.KOMPSAT &&
      row?.sensor === 'MSI'
    ) {
      return 'Strip';
    } else if (
      row?.key === OPERATORS.GHG &&
      (this.target?.multiTarget?.length > 2 && this.multiPointsCoveredArea <= 144)
    ) {
      return row.collection_mode[1].captureMode;
    } else if (
      row.collection_mode[0]?.isCaptureMode &&
      !row.collection_mode[0]?.isProductLevel
    ) {
      return row.collection_mode[0].captureMode;
    } else if (
      row.collection_mode[0]?.isProductLevel &&
      !row.collection_mode[0]?.isCaptureMode
    ) {
      return row.collection_mode[0].productLevel;
    } else return `${row.collection_mode[0].captureMode} (${row.collection_mode[0].productLevel})`
  }

  getTooltip(row: any, indx: any, sensor: any) {
    if (
      this.target.area > 0 &&
      row?.captureMode == 'Strip' &&
      sensor?.key === OPERATORS.SATELLOGIC &&
      row?.collection_mode?.length > 1
    ) {
      this.AVAILABLESENSORS[indx].tooltipValue =
        row?.captureMode + ' : ' + '5km x 20km';
      this.AVAILABLESENSORS[indx].minimumArea = row?.value;
    } else {
      this.AVAILABLESENSORS[indx].tooltipValue = row?.minTooltip;
      this.AVAILABLESENSORS[indx].minimumArea = row?.value;
    }
  }

  onMouseOut() {
    this.tooltipDisabled = true;
    this.detailsText = '';
  }

  clickHandler() {
    this.childEvent.emit();
    this.selectedItems = [];
    let selectedSensors:any = [];
    this.AVAILABLESENSORS.forEach((sensor, index) => {
      if (sensor.checked) {
        if (sensor?.key != OPERATORS.HEAD) {
          this.selectedItems.push(sensor?.name);
          selectedSensors.push({key: sensor?.name, obfuscateName: sensor?.obfuscateName})
        } else {
          this.selectedItems.push(sensor?.key);
          selectedSensors.push({key: sensor?.key, obfuscateName: sensor?.obfuscateName})
        }
      }
    });

    let sensorData = {};
    const currentDate: any = new Date();
    if(this.target.geoJSON) sensorData['target'] = JSON.stringify(geoParse(this.target.geoJSON));
    sensorData['sensorName'] = this.selectedItems;
    sensorData['sensors'] = selectedSensors;
    sensorData['openWindow'] = moment(this.target.openWindow)
      .utcOffset(0, true)
      .format();
    sensorData['closeWindow'] = moment(this.target.closeWindow)
      .utcOffset(0, true)
      .format();

    const sensorObj = { sensorData: sensorData };
    if (this.selectedItems?.length > 0) {
      this.apiService.getSatelliteData(sensorObj).subscribe((res: any) => {
        this.childButtonEvent.emit(res);
      });
    }
  }

  OpenPopup(row: any) {
    let sensorName = row?.displayName + ' ' + row.sensor;
    let resolution: any, name: any;
    if (row?.resolution) {
      if (row?.resolution[0]?.previewvalue) {
        resolution = row?.resolution[0]?.previewvalue;
      } else resolution = row?.resolution[0]?.value;
      name = row?.imageryname;
    }
    let mode: any;
    if (row?.collection_mode) {
      if (row?.collection_mode[0]?.previewmode) {
        mode = row?.collection_mode[0].previewmode;
      } else if (row?.collection_mode[0]?.tooltip) {
        mode = row?.collection_mode[0].tooltip;
      } else mode = row?.collection_mode[0].captureMode;
    }
    let height: any, width: any;
    if (row.sensor === 'Video') {
      height = '75%';
      width = '65%';
    }
    else {
      height = '85%';
      width = '60%';
    }

    this.dialog.open(SensorpreviewComponent, {
      width: width,
      height: height,
      data: {
        sensor: sensorName,
        resolution: resolution,
        imageName: name,
        mode: mode,
        operatorKey: row.key,
        sensorType: row.sensor
      },
      backdropClass: 'blurred',
      disableClose: true,
    });
  }

  OpenAdvance(row: any, index: any) {
    let imageResolution: any;

    const dialog = this.dialog.open(AdvanceSettingsComponent, {
      minWidth: '50%',
      backdropClass: 'blurred',
      disableClose: true,
      data: { selectedRow: row, type: 'advance' },
    });
    dialog.afterClosed().subscribe((result) => {
      if (result != 'close') {
        this.AVAILABLESENSORS[index].advanceOptions = true;
        this.AVAILABLESENSORS[index].orbitalPlane = result?.orbitalPlane;
        this.AVAILABLESENSORS[index].orbitalState = result?.orbitalState;
        this.AVAILABLESENSORS[index].observationDirection =
          result?.observationDirection;
        this.AVAILABLESENSORS[index].polarization = result?.polarization;
        this.AVAILABLESENSORS[index].imageLength = result?.imageLength;
        this.AVAILABLESENSORS[index].numOfLooks = result?.numOfLooks;
        this.AVAILABLESENSORS[index].selectedAnciallary =
          result?.productAnciallary;
        this.AVAILABLESENSORS[index].selectedOrbitDirection =
          result?.sarOrbitDirection;
        this.AVAILABLESENSORS[index].selectedPolarization =
          result?.sarPolarization;
        this.AVAILABLESENSORS[index].selectedAzimuthAngleMin =
          result?.targetAzimuthAngleMin;
        this.AVAILABLESENSORS[index].selectedAzimuthAngleMax =
          result?.targetAzimuthAngleMax;
          this.AVAILABLESENSORS[index].selectedOrbitType =
          result?.sarOrbitType;
        if (result?.lookAngle) {
          this.AVAILABLESENSORS[index].selectedONAOption = result?.lookAngle;
        }

        if (this.AVAILABLESENSORS[index].imageCost > 0) {
          this.AVAILABLESENSORS[index].selectedCost =
            this.AVAILABLESENSORS[index].selectedCost /
            this.AVAILABLESENSORS[index].imageCost;
          this.AVAILABLESENSORS[index].unitcost =
            this.AVAILABLESENSORS[index].unitcost /
            this.AVAILABLESENSORS[index].imageCost;
        }
      } else {
        this.AVAILABLESENSORS[index].advanceOptions = false;
      }
    });
  }

  onSensorTypeChange(
    selectedObservationSensorTypes: any,
    rowIndex: number
  ): void {
    const index = this.AVAILABLESENSORS.findIndex(
      (operator, currentIndex) =>
        operator.selectedTnqSensorType ==
          selectedObservationSensorTypes.value && currentIndex !== rowIndex
    );
    if (index !== -1) {
      this.AVAILABLESENSORS[index].selectedTnqSensorType = '';
      this.AVAILABLESENSORS[index].tnqSensorDisabled = true;
      this.AVAILABLESENSORS[index].checked = false;
      let countIndex = this.selectedSensors.findIndex(
        (item) =>
          item.name === this.AVAILABLESENSORS[index].name &&
          item.resolution[0].value ===
            this.AVAILABLESENSORS[index].resolution[0].value
      );
      if (countIndex !== -1) {
        this.selectedSensors.splice(countIndex, 1);
      }
    }
    this.AVAILABLESENSORS[rowIndex].selectedTnqSensorType =
      selectedObservationSensorTypes.value;
    this.tipAndQSelectedRows = this.AVAILABLESENSORS.filter(
      (operator) => operator.checked && operator.selectedTnqSensorType == ''
    );
    this.tipAndQSelected = this.tipAndQSelectedRows.length === 0 ? false : true;
  }

  shouldDisableTier(elem: any, row: any) {
    const dailyRecurrentCapella = elem.sensor.includes('Capella') && this.target.selectedOption === 1 && elem.key != 'recurrent';
    const capellaNonRecurrent = elem.sensor.includes('Capella') && this.target.selectedOption != 1 && (elem.key === 'Recurrent' || elem.key === 'recurrent');
    const subDailyRecurrent = this.capellaEnable && row.sensor != 'SAR';
    const subdailyEnable = this.satCapeEnable && row.sensor != 'SAR' && row?.key != this.OPERATOR.SATELLOGIC && row?.key != this.OPERATOR.STE && row?.key != this.OPERATOR.USL && row?.key != this.OPERATOR.BLACKSKY;
    const spesificDate = (row?.key === this.OPERATOR.STE && this.target.selectedOption != 0 && elem.key === 'priority') || 
          (row?.key === this.OPERATOR.GOKTURK && this.target.selectedOption != 0 && elem.key === 'rush') || 
          (row?.key === this.OPERATOR.GOKTURK && this.target.selectedOption === 0 && elem.key != 'rush');
    const openWaterRestriction = row?.key === OPERATORS.SATELLOGIC && this.target?.openWater && elem.key != 'RR';
   return elem.cost == 0.00 || dailyRecurrentCapella || capellaNonRecurrent || (row.prevent && row?.key != OPERATORS.AT21) || subDailyRecurrent || subdailyEnable || spesificDate || openWaterRestriction ;
  }

  shouldDisableResolution(item: any, row: any, index: number) {
    const disableAT21 = item.disable && row?.key === OPERATORS.AT21;
    const disableSTE =
      item.disable &&
      row?.key === OPERATORS.STE &&
      row.resolution?.length >= 1 &&
      row?.sensor === 'SAR';
    const umbra = row?.key === OPERATORS.UMBRA && item.disabled;
    const disableSynspective = item.disable && row?.key === OPERATORS.SYNSPECTIVE;
    const isi = row?.key === OPERATORS.ISI && item.disable && row.resolution?.length > 1;
    const capella = row?.key === OPERATORS.CAPELLA && item.disable && row.resolution?.length > 1;
    const head = row?.key === OPERATORS.HEAD && item.disable && row.resolution?.length > 1;
    const sensor = (row?.key === OPERATORS.GOKTURK || row?.key === OPERATORS.GHG || row?.key === OPERATORS.ECURS) && item.disable && row.resolution?.length > 1;
    
    return row.prevent || disableAT21 || disableSTE || umbra || disableSynspective || isi || capella || head || sensor;
  }

  resetSorting() {
    if (this.sort) {
      this.sort.sort({ id: '', start: 'asc', disableClear: false });
    }
  }

  openEula(task: any, operator: any) {
    this.operatorsEula = {
      name: operator.displayName,
      operatorKey: operator?.key,
      resolution: operator?.resolution,
      startDate: task?.openWindow,
      endDate: task?.closeWindow,
      purpose: task?.purposeOption,
    };

    const dialogRef = this.dialog.open(AgreeComponent, {
      width: '65%',
      backdropClass: 'blurred',
      disableClose: true,
      data: {
        operator: this.operatorsEula,
        type: 'save',
        user: this.currentUser,
      },
    });
  }

  checkEndDate(start, end) {
    const endDate = new Date(end);
    const startDate = new Date(start);
    const oneYearFromNow = new Date(
      startDate.setFullYear(startDate.getFullYear() + 1)
    );
    return endDate > oneYearFromNow;
  }

  selections(row: any) {
    this.tooltipDisabled = false;
    if (row.key === OPERATORS.SPIRE) {
      this.detailsText = 'No cancelation is permitted once refresh is ordered';
    } else if (row.key === OPERATORS.CLYDE) {
      this.detailsText = "The sensor can be canceled anytime after two months with one month's" + '\n' +  "notice on the minimum subscription of three months. Similarly," + '\n' + "all subscriptions require one month's notice.";
    } else {
      for (var i = 0; i < row.cancelPolicy.length; i++) {
        this.detailsText +=
          'Cancelation ' +
          row.cancelPolicy[i].key +
          ' : ' +
          row.cancelPolicy[i].value +
          '\n';
      }
    }
  }

  getAxelPrice(size, pricing) {
    if (size < 100) {
      return pricing[0].unitPrice;
    }
    // else if (size >= 900) {
    //   return pricing[1].unitPrice;
    // }

    else {
      for (const range of pricing) {
        if (size >= range.minArea && (range.maxArea === undefined || size <= range.maxArea)) {
          return range.unitPrice;
        }
      }
    }
    return null;
  }


  convertCostToPoints(cost: any, includeLabel: boolean = true, row: any = {}): string | number {
    cost = parseFloat(cost);
    
    if (!this.currentUser.pointsEligible) {
        if (this.dem_calculation) {
            cost += parseFloat(row?.demTotalPrice ?? 0);
        }
        if (this.enable_sr_calculation && row?.super_resolution) {
            cost += parseFloat(row?.super_resolution[0]?.cost ?? 0);
        }
        if(this.enable_indices_calculation && row?.indices)
        {
          cost += parseFloat(row?.TotalIndicesPrice ?? 0);
        }
        return `${this.currencySign} ${this.roundNumber(cost, 2)}`;
    }

    const conversionFactor = this.getConversionFactor();
    let points = Math.round(cost * conversionFactor);

    if (this.dem_calculation) {
        points += parseFloat(row?.demTotalPoint ?? 0);
    }
    if(this.enable_indices_calculation && row?.indices)
    {
      cost += parseFloat(row?.TotalIndicesPoint ?? 0);
    }
    if (this.enable_sr_calculation && row?.TotalSRPoint) {
        points += parseFloat(row?.TotalSRPoint ?? 0);
    }
    return includeLabel ? `${points} Points` : points;
  }

  private getConversionFactor(): number {
      const parts = this.currentUser.sensorConversionFactor.split(':');
      return parseFloat(parts[1]) / parseFloat(parts[0]);
  }

  roundNumber(value: number, decimals: number): string {
    if (Number.isInteger(value)) {
      return value.toString();
    }
    return value.toFixed(decimals).replace(/\.?0+$/, '');
  }

  calculateDemTotal(row: any[], idx: any) {
    if (!row || !row.length) {
      this.AVAILABLESENSORS[idx].demTotalPrice = 0;
      this.AVAILABLESENSORS[idx].demTotalPoint = 0;
      return;
    }
  
    // Calculate total price and points for the entire row
    const demTotalPrice = row.reduce((total, curr) => total + Number(curr.cost), 0);
    const demTotalPoint = this.currentUser.pointsEligible ?
      row.reduce((total, curr) => total + Number(curr.point), 0) : 0;
  
    // Push totals into this.AVAILABLESENSORS[idx]
    this.AVAILABLESENSORS[idx].demTotalPrice = demTotalPrice;
    this.AVAILABLESENSORS[idx].demTotalPoint = demTotalPoint;
  }
  formatGroupedDem(val: any[]) {
    if ((val || []).length > 0) {
      const uniqueProviders = new Set<string>(
        val
          .filter(item => item && typeof item === 'object')
          .map(item => item.actionProvider)
      );
  
      let result = Array.from(uniqueProviders).join(', ');
      if (result.length > 10) {
        result = result.slice(0, 10) + '...';
      }
      
      return  result || undefined;
    }
    return;
  }
  
  formatGroupedDemTooltip = (val: any[]): any => {
    if (val?.length) {
      return val
        .filter(item => typeof item === 'object' && item !== null)
        .map(item => {
          return item.valueAddedDataProduct === 'indices' 
            ? `${item.actionProvider}` 
            : `${item.actionProvider} ${item.providerResolution}`;
        })
        .join(', ');
    }
    return '';
  };

  formatVAR(arr: any[]) {
    if((arr || []).length === 0)
      return {};

    const groupedDemOptions = arr.reduce((acc, curr) => {
      // Check if group already exists, if not, initialize it as an empty array
      if (!acc[curr.actionProvider]) {
        acc[curr.actionProvider] = [];
      }
      // Push current item into the corresponding group array
      acc[curr.actionProvider].push(curr);
      return acc;
    }, {});
    return groupedDemOptions;
  }

  formatvalueAddedOption(sensor:any, mode: any, resolution: any)
  {
    if(sensor.valueAddedOption)
    {
      const DxM = sensor.valueAddedOption.filter((i) => this.actionProviders.includes(i.actionProvider));
      DxM.sort((a, b) => {
        const actionA = a.actionProvider || '';
        const actionB = b.actionProvider || '';
        return actionA.localeCompare(actionB);
      });
      
      const options = DxM.filter((i) => i.sensorResolution === resolution.resolution_value || i.sensorResolution === resolution.value);
      return this.toggleValueAddedOptions(options)
    }
    return [];
  }
  updatevalueAddedOption(newValue: any[], row: any, idx: number) {
    const updatedValue = (newValue || []).filter((item, index, arr) => {
      const existingIndex = arr.findIndex(existingItem => 
        existingItem.actionProvider === item.actionProvider &&
        existingItem.ProviderName === item.ProviderName &&
        existingItem.sensorResolution === item.sensorResolution
      );
      return existingIndex === index;
    });
  
    row.valueAddedOption = updatedValue;
    this.calculateDemTotal(row.valueAddedOption, idx);
  }
  selectDxMOption(dem_item: any, row: any, group: any, idx: number): void {
    if(row.key === 'ISI' && row.selectedModes[0].key === 'Pan-sharpened')
    {
      this.AVAILABLESENSORS[idx].selectedMode = row?.collection_mode[0].key;
      this.AVAILABLESENSORS[idx].selectedModes = [];
      this.AVAILABLESENSORS[idx].modeSelected = [];
      this.AVAILABLESENSORS[idx].modeResetValue = [];
      
      this.AVAILABLESENSORS[idx].selectedModes.push(row?.collection_mode[0]);
      this.AVAILABLESENSORS[idx].modeSelected.push(row?.collection_mode[0]);
    }
    if (!this.AVAILABLESENSORS[idx].valueAddedOption) {
      this.AVAILABLESENSORS[idx].valueAddedOption = [];
    }

    // Check if the dem_item is already selected
    const isSelected = row.valueAddedOption.some(item => 
      item.actionProvider === dem_item.actionProvider &&
      item.ProviderName === dem_item.ProviderName &&
      item.sensorResolution === dem_item.sensorResolution && 
      item.providerResolution === dem_item.providerResolution
    );

    if (isSelected) {
      // If already selected, unselect it by removing from valueAddedOption
      row.valueAddedOption = row.valueAddedOption.filter((item: any) => item !== dem_item);
    } else {
      // If not selected, remove any previously selected item from the same group
      row.valueAddedOption = row.valueAddedOption.filter((item: any) => 
        item.actionProvider !== group.actionProvider
      );

      // Add the newly selected item
      row.valueAddedOption.push(dem_item);
    }

    // Update the AVAILABLESENSORS with the new selection
    this.AVAILABLESENSORS[idx].valueAddedOption = row.valueAddedOption;

    // Reset VADP and update the valueAddedOption
    this.resetVADP(row.valueAddedOption, idx);
    this.updatevalueAddedOption(row.valueAddedOption, row, idx);
  }

  filterValueAddedData(sensor:any)
  {
    if(sensor?.valAddedArray)
    {
      const options = (sensor?.valAddedArray || []).filter((i) => i.sensorResolution === sensor.selectedResolutionValue || i.sensorResolution === sensor.selectedResolution.value)
      return this. toggleValueAddedOptions(options);
    }
    return [];
  }
  toggleValueAddedOptions(data:any)
  {
    let resultMap:{ actionProvider: string, items: { providerName:string, actionProvider:string, providerResolution: string, cost: string , point: string, deliveryTime: string, sensorResolution: string }[] }[] = [];
    
    data.forEach(item => {
      let actionProvider = item.actionProvider;
      item.point = this.convertCostToPoints(item.cost, false);
      
      let existingactionProvider = resultMap.find(entry => (entry || []).actionProvider === actionProvider);
      if (existingactionProvider) {
        existingactionProvider.items.push(item);
      } else {
        resultMap.push({
          actionProvider: actionProvider,
          items: [item]
        });
      }
    });
    return resultMap;
  }
  resetValueAddedDropdown(indx, row)
  {
    if(row.sensor === 'Stereo' || row.sensor === 'Tristereo') {
      this.AVAILABLESENSORS[indx].demTotalPrice = 0;
      this.AVAILABLESENSORS[indx].demTotalPoint = 0;
      this.AVAILABLESENSORS[indx].valueAddedOption = '';
      this.AVAILABLESENSORS[indx].vadp = [];
      this.AVAILABLESENSORS[indx].valueAddedAction = this.filterValueAddedData(this.AVAILABLESENSORS[indx]);
    }
  }

  async looksChange(look: any, indx: number, row: any) {
    this.AVAILABLESENSORS[indx].numberOfLooks = look;
    if (row.key === OPERATORS.UMBRA) {
      let sceneSize = this.AVAILABLESENSORS[indx].selectedModes[0].value;
      let looks = this.AVAILABLESENSORS[indx].numberOfLooks ? this.AVAILABLESENSORS[indx].numberOfLooks :
                  this.AVAILABLESENSORS[indx].selectedResolution.numberOfLooks[0];

      row.latency[0].cost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      row.latency[0].unitcost =
        this.AVAILABLESENSORS[indx].selectedResolution?.sceneSizePricing[sceneSize][looks];
      this.AVAILABLESENSORS[indx].latency = row.latency;
      this.taskingOption(row.latency[0].name, indx, row, 1);
    }

  }
  setSuperResolutions(sensor)
  {
    let options = [];
    if(sensor.valueAddedOption) {
      options = sensor.valueAddedOption.filter((i) => i.actionProvider === 'Super Resolution');
    }
    return options;
  }
  onDxMCheckboxChange(row: any, idx:number, type:any): void {
    if (!row.isDxMSelected) {
      if(!this.AVAILABLESENSORS[idx].vadp) this.AVAILABLESENSORS[idx].vadp = [];
      this.AVAILABLESENSORS[idx].vadp.push(type);
      this.AVAILABLESENSORS[idx].isDxMSelected = true;
    } else {
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => item !== type);
      this.AVAILABLESENSORS[idx].isDxMSelected = false;
    }
  }
  
  onSRCheckboxChange(row: any, idx:number, type:any): void {
    if (!row.isSRSelected) {
      if(!this.AVAILABLESENSORS[idx].vadp) this.AVAILABLESENSORS[idx].vadp = [];
      this.AVAILABLESENSORS[idx].vadp.push(type);
      this.AVAILABLESENSORS[idx].isSRSelected = true;
    } else {
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => item !== type);
      this.AVAILABLESENSORS[idx].isSRSelected = false;
    }
  }
  resetVADP(row, idx) {
    // Remove items with actionProvider in the specified list
    this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => 
      !this.actionProviders.includes(item.actionProvider)
    );

    row.forEach((i) => {
      this.AVAILABLESENSORS[idx].vadp.push(i)
    })
  }
  selectSROption(resolution: any, row: any, idx: number): void {
    if (!row.super_resolution) {
        row.super_resolution = [];
    }

    // Check if the resolution is already selected
    const isSelected = row.super_resolution.some(item => item === resolution);

    if (isSelected) {
        // If the resolution is already selected, unselect it by removing it from super_resolution
        row.super_resolution = row.super_resolution.filter(item => item !== resolution);
        resolution.isSR = false;

        // Remove it from AVAILABLESENSORS[idx].vadp as well
        this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => item !== resolution);
    } else {
        // If the resolution is not selected, clear any previous selections and select the new one
        row.super_resolution = [];
        row.super_resolution.push(resolution);
        resolution.isSR = true;

        // Remove all items with isSR === true from AVAILABLESENSORS[idx].vadp
        this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => !item.isSR);
        this.AVAILABLESENSORS[idx].vadp.push(resolution);
    }
    this.calculateSRTotal(row.super_resolution, idx);
  }
  calculateSRTotal(row: any[], idx: any) {
    if (!row || !row.length) {
      this.AVAILABLESENSORS[idx].TotalSRPrice = 0;
      this.AVAILABLESENSORS[idx].TotalSRPoint = 0;
      return;
    }
  
    // Calculate total price and points for the entire row
    const TotalSRPrice = row.reduce((total, curr) => total + Number(curr.cost), 0);
    const TotalSRPoint = this.currentUser.pointsEligible ?
      row.reduce((total, curr) => total + Number(curr.point), 0) : 0;
  
    // Push totals into this.AVAILABLESENSORS[idx]
    this.AVAILABLESENSORS[idx].TotalSRPrice = TotalSRPrice;
    this.AVAILABLESENSORS[idx].TotalSRPoint = TotalSRPoint;
  }
  // Get selected values for a specific row
  getSelectedVadp(row: any): any[] {
    if (!row.vadp) {
      return [];
    }
  
    // Initialize arrays for special and regular values
    const specialValue = "SR";
    const vadpValue = "DxM";
    const indicesValue = "Indices";
    const filteredVadp = row.vadp.filter(item => item === specialValue);
    const regularItems = row.vadp.filter(item => item !== specialValue && (item || {}).actionProvider === 'Super Resolution');
    const DxMItems = row.vadp.filter(item => this.actionProviders.includes((item || {}).actionProvider));
    const IndicesItems = row.vadp.filter((item) => item.valueAddedDataProduct === 'indices');
    
    // Combine special value and regular items
    const updatedVadp = [specialValue, ...regularItems, vadpValue, ...DxMItems, indicesValue, ...IndicesItems];
    
    return updatedVadp;
  }
  
  // Function to handle collection duration change
  async collectionChange(data: any, idx: number, item: any, row: any) {
    // Update the collection duration of the selected signal
    if (data?.value?.value) {
      // Update the collection duration of the specific signal in the row
      const selectedSignal = row.signal_of_interest.find(signal => signal.value === item.value);
      if (selectedSignal) {
        selectedSignal.collectionDuration = data.value.value;
      }
  
      // Update the collection duration in AVAILABLESENSORS array
      const availableSignal = this.AVAILABLESENSORS[idx]?.signalsOfInterest;
      if (availableSignal && availableSignal.length > 0) {
        const signal = availableSignal.find(signal => signal.value === item.value);
        if (signal) {
          signal.collectionDuration = data.value.value;
        }
  
        // Update selected signals display text
        const signalsSelected = availableSignal.filter(sensor => {
          return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
        });
        this.AVAILABLESENSORS[idx].signalOfInterest = signalsSelected;
        this.AVAILABLESENSORS[idx].selectedSignals = signalsSelected.map(signal => signal.value).join(', ');
      }
    }
  }

  // Function to handle collection duration change
  async audioChange(event: MatCheckboxChange, item: any, idx: number, row: any) {
    if(this.AVAILABLESENSORS[idx].selectedSignals) this.AVAILABLESENSORS[idx].selectedSignals = '';
    // Update the collection duration of the selected signal
    const selectedSignal = row.signal_of_interest.find(signal => signal.value === item.value);
    if (selectedSignal) {
      selectedSignal.isAudio = event.checked;
      if(event.checked) {
        selectedSignal.collectionDuration = 60;
      } else selectedSignal.collectionDuration = selectedSignal.collection_duration[0].value;
    }

    const availableSignal = this.AVAILABLESENSORS[idx]?.signalsOfInterest;
    if (availableSignal && availableSignal.length > 0) {
      const signal = availableSignal.find(signal => signal.value === item.value);
      if (signal) {
        signal.isAudio = event.checked;
        if(event.checked) {
          signal.collectionDuration = 60;
        } else signal.collectionDuration = signal.collection_duration[0].value;
      }

      // Update selected signals display text
      const signalsSelected = availableSignal.filter(sensor => {
        return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
      });
      this.AVAILABLESENSORS[idx].signalOfInterest = signalsSelected;
      this.AVAILABLESENSORS[idx].selectedSignals = signalsSelected.map(signal => signal.value).join(', ');
    }
  }

  async signalChange(event: any, idx: number, mySelect?: MatSelect) {
    event.value = event.value.filter(item => item !== undefined);

    let selectedSignals = event.value;
    this.rfSignals = true;
  
    // If selected signals array is empty, reset and exit
    if (selectedSignals.length === 0) {
      this.AVAILABLESENSORS[idx].signalOfInterest = [];
      this.AVAILABLESENSORS[idx].signalsOfInterest = [];
      this.rfSignals = false;
      return;
    }
  
    // Keep the existing `signalOfInterest` values without resetting
    if (!this.AVAILABLESENSORS[idx].signalOfInterest || !Array.isArray(this.AVAILABLESENSORS[idx].signalOfInterest)) {
      this.AVAILABLESENSORS[idx].signalOfInterest = [];
      this.AVAILABLESENSORS[idx].signalsOfInterest = [];
    }
  
    const previousSelectedSignals = [...this.AVAILABLESENSORS[idx].signalsOfInterest];
  
    // Check selection validity
    if (this.checkSelection(selectedSignals, idx)) {      
      selectedSignals = this.processSignals(previousSelectedSignals, selectedSignals);      
      this.AVAILABLESENSORS[idx].signalsOfInterest = selectedSignals;  
   
    } else {
      // Invalid selection: remove the newly selected item and call signalChange again
      selectedSignals = previousSelectedSignals;
      this.AVAILABLESENSORS[idx].signalsOfInterest = selectedSignals;
  
      // Set a timeout to refresh UI
      setTimeout(() => {
        if (mySelect) {
          mySelect.value = selectedSignals;
          this.signalChange({ value: selectedSignals }, idx, mySelect);
        }
      }, 0);
      return;
    }
  
    // Update selected signals for display    
    const signalsSelected =  this.AVAILABLESENSORS[idx].signalsOfInterest.filter(sensor => {
      return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
    });
    this.AVAILABLESENSORS[idx].signalOfInterest = signalsSelected;
    this.AVAILABLESENSORS[idx].selectedSignals = signalsSelected.map(signal => signal.value).join(', ');
  }

  processSignals(previousSignals: any[], newSignals: any[]): any[] {
    return newSignals.map(newSignal => {
      const matchingPrevSignal = previousSignals.find(prevSignal => prevSignal.value === newSignal.value);
      
      // Preserve the collectionDuration from the previous selection if it exists
      if (matchingPrevSignal && matchingPrevSignal.collectionDuration) {
        newSignal.collectionDuration = matchingPrevSignal.collectionDuration;
      } else if (newSignal.collection_duration && newSignal.collection_duration.length > 0 && !newSignal.collectionDuration) {
        // If no previous duration exists, use the default
        newSignal.collectionDuration = newSignal.collection_duration[0].value;
      }
  
      newSignal.checked = true;
      return newSignal;
    });
  }

checkSelection(selectedItems: any[], index: number): boolean {
   let selectedSignals = selectedItems.filter(sensor => {
    return Array.isArray(sensor.collection_duration) && sensor.collection_duration.length > 0;
  });
    // Define categories
    const gps = ['GPS L1', 'GPS L2', 'GPS L5'];
    const vhf = ['VHF'];
    const uhf = ['UHF'];
    const lBand = ['L-band (ADS-B)', 'L-band (GNSS)', 'L-band (SATCOM)'];
  
    // Separate signals into categories
    const selectedGPS = selectedSignals.filter(s => gps.includes(s.value));
    const selectedVHF = selectedSignals.filter(s => vhf.includes(s.value));
    const selectedUHF = selectedSignals.filter(s => uhf.includes(s.value));
    const selectedLBand = selectedSignals.filter(s => lBand.includes(s.value));
  
    // Rule: Only one GPS signal can be selected at a time
    if (selectedGPS.length > 1) {
      const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
      this.popupService.openPopup(WarningPopup, message, '', '50%');
      return false;
    }
  
    // Check for invalid combinations
    if (selectedGPS.length > 0) {
        if (selectedSignals.length > selectedGPS.length) {
          const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
          this.popupService.openPopup(WarningPopup, message, '', '50%');
          return false;
        }
    } else {
        // Validate non-GPS selections
        if (selectedVHF.length + selectedUHF.length + selectedLBand.length > 3) {
          const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
          this.popupService.openPopup(WarningPopup, message, '', '50%');
          return false;
        }
        if (selectedVHF.length > 1 || selectedUHF.length > 1 || selectedLBand.length > 1) {
          const message = `You can request a maximum of three signals when combining UHF, VHF, and
                      L-Band, charged individually, for the same price in a single task. You can
                      request all the other signals individually.`;
          this.popupService.openPopup(WarningPopup, message, '', '50%');
          return false;
        }
    }
  
    return true;
}

  onSelectOpened(index: number) {
    if(this.AVAILABLESENSORS[index].signalOfInterest)
    this.initialSelection = [...this.AVAILABLESENSORS[index].signalOfInterest];
  }

  onSelectClosed(index: number) {
    const currentSelection = this.AVAILABLESENSORS[index].signalOfInterest;
  
    const isSelectionChanged = JSON.stringify(this.initialSelection) !== JSON.stringify(currentSelection);
  
    if (isSelectionChanged) {
    this.AVAILABLESENSORS[index].isAudio = false;
    const isVHFUHF = this.AVAILABLESENSORS[index].signalOfInterest?.some(item => (item.value === 'VHF'));
    
    if (isVHFUHF) {
      const dialogRef = this.dialog.open(ConfirmPopupComponent, {
        width: '50%',
        backdropClass: 'blurred',
        disableClose: true,
        data: {message: OPERATORS.LBAND}
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === 'yes') {
          this.AVAILABLESENSORS[index].collectionDuration = 60;
          this.AVAILABLESENSORS[index].isAudio = true;
        } else {
          this.AVAILABLESENSORS[index].collectionDuration = this.AVAILABLESENSORS[index].collection_duration[0].value;
          this.AVAILABLESENSORS[index].isAudio = false;
        }
      });
    }
  }
  }
  setIndices(sensor:any) {

    let options = [];
    if(sensor.valueAddedOption) {
      options = sensor.valueAddedOption.filter((i) => i.valueAddedDataProduct === 'indices');
    }
    return options;
  }
  onIndicesCheckboxChange(row: any, idx:number, type:any): void {
    if (!row.isIndicesSelected) {
      if(!this.AVAILABLESENSORS[idx].vadp) this.AVAILABLESENSORS[idx].vadp = [];
      this.AVAILABLESENSORS[idx].vadp.push(type);
      this.AVAILABLESENSORS[idx].isIndicesSelected = true;
    } else {
      this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter((item: any) => item !== type);
      this.AVAILABLESENSORS[idx].isIndicesSelected = false;
    }
  }
  selectIndicesOption(obj: any, row: any, idx: number): void {
    if (!row.indices) {
        row.indices = [];
    }

    // Check if the actionProvider is already selected
    const isSelected = row.indices.some(item => item === obj);
    
    if (isSelected) {
        // If the resolution is already selected, unselect it by removing it from indices
        row.indices = row.indices.filter(item => item !== obj);
        obj.isIndices = false;

        // Remove it from AVAILABLESENSORS[idx].vadp as well
        this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => item !== obj);
    } else {
        // If the resolution is not selected, clear any previous selections and select the new one
        row.indices = [];
        row.indices.push(obj);
        obj.isIndices = true;

        // Remove all items with isIndices === true from AVAILABLESENSORS[idx].vadp
        this.AVAILABLESENSORS[idx].vadp = this.AVAILABLESENSORS[idx].vadp.filter(item => !item.isIndices);
        this.AVAILABLESENSORS[idx].vadp.push(obj);
    }
    this.calculateIndicesTotal(row.indices, idx);
  }
  calculateIndicesTotal(row: any[], idx: any) {
    if (!row || !row.length) {
      this.AVAILABLESENSORS[idx].TotalIndicesPrice = 0;
      this.AVAILABLESENSORS[idx].TotalIndicesPoint = 0;
      return;
    }
  
    // Calculate total price and points for the entire row
    const TotalIndicesPrice = row.reduce((total, curr) => total + Number(curr.cost), 0);
    const TotalIndicesPoint = this.currentUser.pointsEligible ?
      row.reduce((total, curr) => total + Number(curr.point), 0) : 0;
  
    // Push totals into this.AVAILABLESENSORS[idx]
    this.AVAILABLESENSORS[idx].TotalIndicesPrice = TotalIndicesPrice;
    this.AVAILABLESENSORS[idx].TotalIndicesPoint = TotalIndicesPoint;
  }

  calculateRepeats () {
    let no_of_repeats = 1;
    let startdate = moment(this.target.openWindow).utcOffset(0, true).format();
    let enddate = moment(this.target.closeWindow).utcOffset(0, true).format();
    let time_difference =
      new Date(enddate).getTime() - new Date(startdate).getTime();
    let days_difference = Math.ceil(time_difference / (1000 * 60 * 60 * 24));
    if (this.target.selectedOption === 1) {
      if (this.target.freqOption === 'Weekly') {
        no_of_repeats = Math.ceil(days_difference / 7);
      } else if (this.target.freqOption === 'Monthly') {
        no_of_repeats = Math.ceil(days_difference / 30);
      } else if (this.target.freqOption === 'Daily') {
        no_of_repeats = days_difference;
      } else if (this.target.subDailyCount) {
        no_of_repeats = days_difference * this.target.subDailyCount;
      }
      if (no_of_repeats === 0) {
        no_of_repeats = 1;
      }
    } else {
      no_of_repeats = 1;
    }
    return no_of_repeats;
  }

  costToPoints(cost: number): string {
    if (this.currentUser.pointsEligible) {
      const [part1, part2] = this.currentUser.sensorConversionFactor.split(':').map(parseFloat);
      const conversionFactor = part2 / part1;
      const points = Math.round(cost * conversionFactor);
      return `${points} Points`;
    } else {
      return `$${cost}`;
    }
  }
  displayVadpSelectTooltip(row:any)
  {
    if((row?.valueAddedOption && row?.valueAddedOption.length > 0) || row?.valueAddedAction.length > 0) {
      return '';
    }
    if((row.sensor === 'Stereo' || row.sensor === 'Tristereo') && (row.key == '21AT' || (row.key == 'KOMPSAT' &&  row.name != 'KOMPSAT-5'))) {
      if(row?.selectedResolution?.value == 'Bundle:0.5m PAN/2m MS' && row?.valueAddedAction.length == 0)
        return '';
      return 'Please select either panchromatic or bundle data products for value-added products.';
    }
    else
    return '';
  }

  closeSelectDropdown(select: MatSelect) {
    select.close();
  }

  isOperatorWithSensor(row: any): boolean {
    const validOperators = [OPERATORS.CAPELLA, OPERATORS.UMBRA, OPERATORS.KOMPSAT, OPERATORS.STE, OPERATORS.ECURS];
    return validOperators.includes(row.key) && (row.sensor === 'SAR' || row.sensor === 'InSAR');
  }
  
  getTooltipText(key: string): string {
    switch (key) {
      case OPERATORS.CAPELLA:
        return 'Look Angle'+ '\n' + 'Default polarisation is ' + polarization[key];
      case OPERATORS.UMBRA:
        return 'Grazing Angle' + '\n' + 'Default polarisation is ' + polarization[key];
      default:
        return 'Default polarisation is ' + polarization[key];
    }
  }

  groupPointsByOverlap(points: any[]): any[][] {
    const radius = RF_RADIUS * 1000; // Convert to meters
    const standalonePoints: any[] = [];
    const overlappingGroups: any[][] = [];
    const visited = new Set<number>();
    
    // Convert all points to Cartesian3
    const cartesianPoints = points.map(point => ({
      type: point.type,
      coordinates: this.toCartesian(point.coordinates),
      originalCoordinates: point.coordinates
    }));

    for (let i = 0; i < cartesianPoints.length; i++) {
      if (visited.has(i)) continue; // Skip points already grouped

      const overlappingGroup = [cartesianPoints[i]];

      for (let j = i + 1; j < cartesianPoints.length; j++) {
        if (visited.has(j)) continue;

        // Calculate the distance between two Cartesian points
        const distance = Cesium.Cartesian3.distance(cartesianPoints[i].coordinates, cartesianPoints[j].coordinates);

        if (distance <= 2 * radius) {
          overlappingGroup.push(cartesianPoints[j]);
          visited.add(j); // Mark as visited
        }
      }

      // Group points into standalone or overlapping arrays
      if (overlappingGroup.length > 1) {
        overlappingGroups.push(overlappingGroup.map(point => ({
          type: point.type,
          coordinates: point.originalCoordinates
        })));
      } else {
        standalonePoints.push({
          type: cartesianPoints[i].type,
          coordinates: cartesianPoints[i].originalCoordinates
        });
      }
    }

    return [standalonePoints, ...overlappingGroups];
  }

  // Convert degrees to Cesium Cartesian3 coordinates
  toCartesian(coords: number[]): Cesium.Cartesian3 {
    const [longitude, latitude] = coords;
    return Cesium.Cartesian3.fromDegrees(longitude, latitude);
  }
  
  feasibleAIS() {
    return this.AVAILABLESENSORS.some(item => item.checked && item.sensor === 'AIS' && item.feasibility);
  }

  calculateUnitcost (indx,latencyIndx) {
    if (this.AVAILABLESENSORS[indx].ONAFactor == undefined) this.AVAILABLESENSORS[indx].ONAFactor = 1;
    if (this.AVAILABLESENSORS[indx].holdbackFactor == undefined) this.AVAILABLESENSORS[indx].holdbackFactor = 1;
    if (this.AVAILABLESENSORS[indx].cloudFactor == undefined) this.AVAILABLESENSORS[indx].cloudFactor = 1;

    const holdbackFactor = this.AVAILABLESENSORS[indx].holdbackFactor - 1;
    const cloudFactor = this.AVAILABLESENSORS[indx].cloudFactor - 1;
    const ONAFactor = this.AVAILABLESENSORS[indx].ONAFactor - 1;
    const resolutionFactor = this.AVAILABLESENSORS[indx].selectedResolution?.multiplier ?? 1;
    
    const baseCost = this.AVAILABLESENSORS[indx].latency[latencyIndx].unitcost * resolutionFactor;
    const holdbackValue = baseCost * holdbackFactor;
    const ccValue = baseCost * cloudFactor;
    const onaValue = baseCost * ONAFactor;

    this.AVAILABLESENSORS[indx].selectedCost = +baseCost + +holdbackValue + +ccValue + +onaValue;
    this.AVAILABLESENSORS[indx].unitcost = this.AVAILABLESENSORS[indx].selectedCost;

    if (
      this.AVAILABLESENSORS[indx].userCost > 0 &&
      this.AVAILABLESENSORS[indx].selectedCost > 0
    ) {
      this.AVAILABLESENSORS[indx].selectedCost =
        this.AVAILABLESENSORS[indx].selectedCost *
        this.AVAILABLESENSORS[indx].userCost;
    }
  }

  getProductTooltip(row: any) {
    if (row.selectedModes && row.selectedModes[0]) {
      const captureMode = row.selectedModes[0].captureMode;
      const productLevel = row.selectedModes[0].productLevel;
      const isProductLevel = row.selectedModes[0].isProductLevel;
      const isCaptureMode = row.selectedModes[0].isCaptureMode;
  
      const tooltipText = isProductLevel && isCaptureMode
        ? `${captureMode} (${productLevel})` :
        isProductLevel && !isCaptureMode ? `${productLevel}`
        : captureMode;
  
      // Show tooltip only if the text length is greater than 10
      return tooltipText;
    }
  }
  setSignalOfInterest(signalOfInterest) {
    
    if(this.currentUser.pointsEligible) {
      signalOfInterest = signalOfInterest?.map((signal: any) => ({
        ...signal,
        point: this.convertCostToPoints(signal.cost, false),
        ...(signal.audioCost && { audioPoint: this.convertCostToPoints(signal.audioCost, false) }),
      }));
    }
    return signalOfInterest;
  }

  goNext(mySelect: MatSelect, event: Event,i,row): void {
    this.restoreMatSelectClose();
    event.stopPropagation(); // Prevent propagation
    mySelect.close(); // Close the dropdown
    this.openPopup(i,row);
  }
  
  close(mySelect: MatSelect, event: Event): void {
    this.restoreMatSelectClose();
    event.stopPropagation(); // Prevent propagation
    mySelect.close(); // Close the dropdown
  }

  openPopup(i,row) {
    let frequency = this.target.selectedOption;
      this.singleCircle = null;
      let numberOfRepeats = this.calculateRepeats(); 
      const startDate: any = new Date(
        moment(this.target.openWindow).utc().format()
      );
      const endDate: any = new Date(
        moment(this.target.closeWindow).utc().format()
      );
      const differenceInTime: number = endDate.getTime() - startDate.getTime();
      const differenceInDays: number = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      );

    const dialog = this.dialog.open(OperatorPopupComponent, {
      minWidth: '50%',
      backdropClass: 'blurred',
      disableClose: true,
      data: {
        selectedRow: row,
        target:
          this.target?.multiTarget?.length > 0
            ? this.target?.multiTarget
            : [this.target.geoJSON],
        operatorKey: row?.key,
        frequency: frequency,
        repeatFrequency: this.target?.freqOption,
        differenceInDays: differenceInDays,
        numberOfRepeats: numberOfRepeats
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result != 'close') {
        this.AVAILABLESENSORS[i].siteData = result.siteData;
        this.AVAILABLESENSORS[i].noOfCollections = result?.numberOfCollections;

        if ((this.AVAILABLESENSORS[i].overlapPercentage > 50)) {
          const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            width: '50%',
            backdropClass: 'blurred',
            disableClose: true,
            data: {message: 'overlapSignal'}
          });
    
          dialogRef.afterClosed().subscribe(async (result) => {
            if (result === 'yes') {
             this.singleCircle = true;
             this.resetSensor();
            } else if (result === 'both') {
              this.singleCircle = false;
              this.resetSensor();
            } else {
              this.singleCircle = null;
              this.AVAILABLESENSORS.forEach((operator, idx) => {
                if (idx === i) {
                  this.AVAILABLESENSORS[idx].checked = false;
                  this.AVAILABLESENSORS[idx].signalOfInterest = [];
                  this.AVAILABLESENSORS[idx].signalsOfInterest = [];
                  this.rfSignals = false;
                  this.AVAILABLESENSORS[i].siteData = null;
                  this.AVAILABLESENSORS[i].noOfCollections = null;
                  this.resetRFData.emit();
                }
              });
              this.resetSensor();
            }
          });
        } else {
          this.singleCircle = false;
          this.resetSensor();
        }
      } else {
        this.AVAILABLESENSORS.forEach((operator, idx) => {
          if (idx === i) {
            this.AVAILABLESENSORS[idx].checked = false;
            this.AVAILABLESENSORS[idx].signalOfInterest = [];
            this.AVAILABLESENSORS[idx].signalsOfInterest = [];
            this.rfSignals = false;
            this.AVAILABLESENSORS[i].siteData = null;
            this.AVAILABLESENSORS[i].noOfCollections = null;
            this.resetRFData.emit();
          }
        });
        this.resetSensor();
      }
    });
  }

  resetSensor() {
    this.selectedSensors = [];
    this.AVAILABLESENSORS.forEach((operator) => {
      if (operator.checked) this.selectedSensors.push(operator);
    });
    for (const sensors of this.selectedSensors) {
      if (!sensors.feasibility) {
        this.feasible = true;
      }
    }
    this.clickHandler();
  }

  onOpenedChange(isOpen: boolean) {
    if (isOpen) {
      this.preventMatSelectClose();
    }
  }

  // Override the close behavior of MatSelect to prevent closing
  private preventMatSelectClose() {
    if (this.mySelects && !this.originalClose) {
      // Save the original close method
      this.originalClose = this.mySelects.close;

      // Override the close method
      this.mySelects.close = () => {
      };
    }
  }

  // Restore the original close behavior
  private restoreMatSelectClose() {
    if (this.mySelects && this.originalClose) {
      this.mySelects.close = this.originalClose;
      this.originalClose = null;
    }
  }

  shouldDisableModes(row) {
    let capella = (row.prevent && row?.key != OPERATORS.CAPELLA) || (this.capellaEnable && row.sensor != 'SAR');

    return capella;
  }

  checkTargetLocation(): Promise<'water' | 'land' | 'both'> {
    if (this.target.area > 0) {      
      if (this.target.openWater) {
        return Promise.resolve('water');
      } else {
        return Promise.resolve('land');
      }
    }

    const pointsArray = this.target.geoJSON.split(',').map(point => point.trim());

    const pointPromises = pointsArray.map(point => {
      const coords = this.utilsService.extractCoordinates(point);
      const location = { longitude: coords.lng, latitude: coords.lat };

      return this.apiService.checkIsWater(location).toPromise()
        .then((res) => {
          const waterCheckResponse = res as WaterCheckResponse;
          if (!waterCheckResponse) {
            throw new Error('API response is undefined');
          }
          return { water: waterCheckResponse.water, latitude: waterCheckResponse.latitude, longitude: waterCheckResponse.longitude };
        })
        .catch(error => {
          return null;
        });
    });

    return Promise.all(pointPromises)
      .then(results => {
        const validResults = results.filter(res => res !== null) as WaterCheckResponse[];

        if (validResults.length === 0) {
          return 'both'; // Default case when no valid responses
        }

        const allWater = validResults.every(res => res.water);
        const allLand = validResults.every(res => !res.water);

        if (allWater) return 'water';
        if (allLand) return 'land';
        return 'both';
      })
      .catch(error => {
        return 'both'; // Default case on error
      });
  }

  getBandInfo(operator: any) {
    this.openMenu();  
    let bandInfo = SENSOR_BAND_DATA[operator] || [];
    this.bandInfoTooltip = bandInfo;
    this.selectedOperator = operator;   
  }

  openMenu() {
    // Add a custom class to the menu panel when it opens
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.classList.add('custom-menu-overlay');
    }
  }

  onMenuClosed(): void {    
    const overlayContainer = document.querySelector('.cdk-overlay-container');
    if (overlayContainer) {
      overlayContainer.classList.remove('custom-menu-overlay');
    }
  }


}
