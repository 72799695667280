<h4 mat-dialog-title style="text-align: center"
  *ngIf="!successMsg && !data.taskaction.includes('status') && data.type === 'task'">
  Cancel Task</h4>
<h4 mat-dialog-title style="text-align: center" *ngIf="data.type === 'sensor'">Cancel Sensor</h4>
<hr />
<div class="scrollbar" id="scrollcustom">
<div *ngIf="!doNotCancel">
  <mat-dialog-content class="mat-typography">
    <div *ngIf="operators.length > 0 && !order">
      <p>
        <span *ngIf="!successMsg && !data.taskaction.includes('status')">Are you sure you want to cancel the following
          Operator</span>
        <span *ngIf="!successMsg && data.taskaction.includes('status')">Fetching the {{ title }} of the following
          Sensor</span>
        <span *ngIf="successMsg">The latest {{ title }} of the following Sensor</span>
        <span *ngIf="operators.length > 1">s</span> from Task<strong style="color: #1059a9"
        class="emails">
          '{{ data.task.taskname }}'
        </strong>
        <span *ngIf="successMsg && !data.taskaction.includes('status')"><span *ngIf="operators.length > 1">
            are</span><span *ngIf="operators.length === 1"> is</span> canceled</span>:
        <!-- <span *ngIf="successMsg && (data.taskaction !== 'taskstatus' || data.taskaction !== 'orderstatus')"><span *ngIf='operators.length>1'> are</span><span *ngIf='operators.length===1'> is</span> canceled</span>: -->
      </p>
      <div *ngFor="let operator of operators; let i = index">
        <!-- <li>{{operator.operator}} {{operator.sensor}} ({{operator.resolution}}m) - {{operator.taskid}}</li> -->
        <li style="
          font-weight: 400;
          opacity: 0.5;
          margin: 8px 0 0 0;
          list-style: disc;
        ">
         {{ (currentUser?.obfuscated && operator.obfuscateName) ? operator.obfuscateName : operator.name }} {{ operator.sensor }} <span *ngIf="operator.resolution"> ({{
          operator.resolution
          }}) </span> - Sensor Id: {{ operator.uuid }}
          <div *ngIf="successMsg && data.taskaction.includes('status')">
            <!-- <div *ngFor='let res of newRes.taskResponse'> -->
            <b style="padding-left: 20px">{{ newRes.taskResponse[i] | json }}</b>
            <!-- </div> -->
            <!-- {{successMsg}} -->
          </div>
        </li>
      </div>

      <p *ngIf="cancelBtn === 'Ok'">
        <span *ngIf="!successMsg">
          <p style="text-align: center">
            <img src="assets/images/load-indicator.gif" />
          </p>
        </span>
      </p>
    </div>

    <div *ngIf="order && !this.noQueue">
      <p>
        <span *ngIf="!successMsg && !data.taskaction.includes('status')">Are you sure to cancel your task </span><strong
          style="color: #1059a9" class="emails">
          "{{ data.task.taskname }}"
        </strong> ?
        <span>Please read the below cancelation terms before you cancel the task:</span>

      </p>
      <div *ngFor="let operator of operators; let i = index">
        <div class="sensor-details" style="
          font-weight: 400;margin: 10px 0 0 0;">
          <b>{{ (currentUser?.obfuscated && operator.obfuscateName) ? operator.obfuscateName : operator.name }} {{ operator.sensor }}  <span *ngIf="operator.resolution"> ({{
            operator.resolution
            }}) </span></b>
          <div *ngFor="let policys of operator?.cancel_policy; let i = index">
            <div class="sensor" [ngClass]="{ 'sensor-cost': policys.isPenaltyOption===true }">
              <span *ngIf="operator.operatorKey === this.OPERATOR.SPIRE || operator.operatorKey === this.OPERATOR.CLYDE">{{ policys.key }}</span>
              <span *ngIf="operator.operatorKey != this.OPERATOR.SPIRE && operator.operatorKey != this.OPERATOR.CLYDE">Cancelation {{ policys.key }}</span>
              <span *ngIf="operator.operatorKey != this.OPERATOR.CLYDE" style="width: 50%;"> : {{ policys.value }}</span>
            </div>
          </div>
          <div *ngIf="successMsg && data.taskaction.includes('status')">
            <b style="padding-left: 20px">{{ newRes.taskResponse[i] | json }}</b>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px;" *ngIf="!currentUser?.allocated">
        <p style="font-weight: 500;" *ngIf="sensorRefundAmount > 0 && !sensorRefundPoint">
          Sensor Refund Amount : $ {{ sensorRefundAmount | customNumberFormat }}
        </p>
        <p style="font-weight: 500;" *ngIf="sensorRefundAmount > 0 && sensorRefundPoint">
          Sensor Refund Amount : {{ sensorRefundPoint | customNumberFormat }} points
        </p>
        <p style="font-weight: 500;" *ngIf="ISRefundAmount > 0 && !ISRefundPoint">
          <span *ngIf="data.task?.taskStrategy != 'Tip-and-Cue'">
            Information Feeds Refund Amount : $ {{ ISRefundAmount | customNumberFormat }}
          </span>
          <span *ngIf="data.task?.taskStrategy === 'Tip-and-Cue'">
            Tip-and-Cue (Observation) Refund Amount : $ {{ ISRefundAmount | customNumberFormat }}
          </span>
        </p>
        <p style="font-weight: 500;" *ngIf="ISRefundAmount > 0 && ISRefundPoint">
          <span *ngIf="data.task?.taskStrategy != 'Tip-and-Cue'">
          Information Feeds Refund Amount : {{ ISRefundPoint | customNumberFormat }} points
          </span>
          <span *ngIf="data.task?.taskStrategy === 'Tip-and-Cue'">
            Tip-and-Cue (Observation) Refund Amount : {{ ISRefundPoint | customNumberFormat }} points
          </span>
        </p>

        <p style="font-weight: 500;" *ngIf="tippedSensorCost > 0">
          Tipped Sensor Refund Amount : $ {{ tippedSensorCost | customNumberFormat }}
        </p>
        <!-- Value added option -->
        <p style="font-weight: 500;" *ngIf="totalVaoRefund > 0 && !totalVaoPoint">
          Value Added Data Products Refund Amount : $ {{ totalVaoRefund | customNumberFormat }}
        </p>
        <p style="font-weight: 500;" *ngIf="totalVaoRefund > 0 && totalVaoPoint">
          Value Added Data Products Refund Amount : {{ totalVaoPoint }} points
        </p>
      </div>
      <div>
        
        <b *ngIf="!currentUser?.pointsEligible && currentUser?.allocated">
          Total Refund Task : {{ totalRefundTaskNumber }}
        </b>     
        <b *ngIf="!currentUser?.pointsEligible && !currentUser?.allocated">
          Total Refund Amount : $ {{ refundAmount | customNumberFormat }}
        </b>
        <b *ngIf="currentUser?.pointsEligible">
          Total Refund Amount : {{ refundPoint | customNumberFormat }} points
        </b>
      </div>
      <p *ngIf="cancelBtn === 'Ok'">
        <span *ngIf="!successMsg">
          <p style="text-align: center">
            <img src="assets/images/load-indicator.gif" />
          </p>
        </span>
      </p>
    </div>

    <div *ngIf="order && this.noQueue">
      <p>
        <span *ngIf="!successMsg && !data.taskaction.includes('status')">Are you sure you want to cancel the following
          Operator</span>
        <span *ngIf="!successMsg && data.taskaction.includes('status')">Fetching the {{ title }} of the following
          Sensor</span>
        <span *ngIf="successMsg">The latest {{ title }} of the following Sensor</span>
        <span *ngIf="operators.length > 1">s</span> from Task<strong style="color: #1059a9"
        class="emails">
          '{{ data.task.taskname }}'
        </strong>
        <span *ngIf="successMsg && !data.taskaction.includes('status')"><span *ngIf="operators.length > 1">
            are</span><span *ngIf="operators.length === 1"> is</span> canceled</span>:
      </p>
      <div *ngFor="let operator of operators; let i = index">
        <li style="
          font-weight: 400;
          opacity: 0.5;
          margin: 8px 0 0 0;
          list-style: disc;
        ">
          {{ (currentUser?.obfuscated && operator.obfuscateName) ? operator.obfuscateName : operator.operator }} {{ operator.sensor }} <span *ngIf="operator.resolution"> ({{
            operator.resolution
            }}) </span> - Sensor Id: {{ operator.uuid }}
          <div *ngIf="successMsg && data.taskaction.includes('status')">
            <b style="padding-left: 20px">{{ newRes.taskResponse[i] | json }}</b>
          </div>
        </li>
      </div>
      <p *ngIf="cancelBtn === 'Ok'">
        <span *ngIf="!successMsg">
          <p style="text-align: center">
            <img src="assets/images/load-indicator.gif" />
          </p>
        </span>
      </p>
      <div>
        <b>
          Total Refund Amount : $ {{ refundAmount | customNumberFormat }}
        </b>
      </div>
    </div>

  </mat-dialog-content>
  <br /><br />
  <div class="d-flex justify-content-center">
    <span *ngIf="operators.length > 0; else okBtn">
      <button *ngIf="cancelBtn !== 'Ok'" class="custom-button"
        style="margin-left: 12px; background-color: transparent!important" (keydown)="onKeyPress($event, 'NO')"
        (click)="onNoClick('NO')">
        No, Thanks
      </button>
      <button *ngIf="data.type === 'task'" mat-flat-button color="primary" class="btn"
        style="margin-left: 12px; background-color: #1059a9; color: #fff;height: 42px !important; margin-top: 11px;
        padding-top: 4px;
        padding-bottom: 40px;" (keydown)="onKeyPress($event, btnParams)"
        (click)="onNoClick(btnParams)" [disabled]="!btnEnabled || clicked || demoView || !isCancelEnabled">
        {{ cancelBtn }}
      </button>
      <button *ngIf="data.type === 'sensor'" mat-flat-button color="primary" class="btn"
        style="margin-left: 12px; background-color: #1059a9; color: #fff; height: 44px !important;" (keydown)="onPress($event, btnParams)"
        (click)="cancelSensor(btnParams)" [disabled]="!btnEnabled || clicked || demoView || !isCancelEnabled">
        {{ cancelBtn }}
      </button>
    </span>
    <ng-template #okBtn>
      <button *ngIf="cancelBtn === 'Ok'" mat-button class="btn" style="margin-left: 12px"
        (keydown)="onKeyPress($event, 'NO')" (click)="onNoClick('NO')" [disabled]="false">
        Close
      </button>
    </ng-template>
  </div>

  <div *ngIf="title === 'Preview'" style="display: none">
    <div>
      <span *ngIf="!successMsg"><app-cesium></app-cesium></span>
    </div>

    <div class="d-flex justify-content-center">
      <button mat-button class="btn" style="margin-top: 10px" (keydown)="onKeyPress($event, 'NO')"
        (click)="onNoClick('NO')">
        Close
      </button>
    </div>
  </div>
</div>


<div *ngIf="doNotCancel" style="text-align: center;">
  <p *ngIf="!USL && !clyde">
    <span>Your task is already in progress. You can't cancel task</span>
    <strong style="color: #1059a9" class="emails">
      "{{ data.task.taskname }}"
    </strong>
  </p>

  <p *ngIf="USL">
    <span>Please cancel </span>
    <strong style="color: #1059a9" class="emails">
      "{{ USLname }}"
    </strong>
    <span>separately as the imaging sensor is already in 
      progress and cannot be cancelled.</span>
  </p>

  <p *ngIf="clyde">
    <span>The minimum subscription period is two months.</span>
  </p>

  <div class="d-flex justify-content-center">
    <button mat-button mat-stroked-button class="btn" style="margin-top: 10px" (keydown)="onKeyPress($event, 'NO')"
      (click)="onNoClick('NO')" style="background-color: #1059a9; color: #fff;">
      Ok, Thanks
    </button>
  </div>
</div>
</div>