import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import { DesktopVersionComponent } from '../my-task/desktop-version/desktop-version.component';
import { AdvanceSettingsComponent } from '../taskcost/advance-settings/advance-settings.component';
import { UserService } from 'src/app/services/user.service';
import { ConfirmPopupComponent } from '../my-task/confirm-popup/confirm-popup.component';
import { WarningPopupComponent } from '../collections/warning-popup/warning-popup.component';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  currentUser: any = undefined;
  displayedColumns: string[] = ['name', 'displayName', 'department','email','edit','delete'];
  dataSource = new MatTableDataSource<any>();
  tableDataLength: number;
  userid: any;
  darkTheme: boolean;
  deletedImagePath: string;
  isInIframe: boolean;

  constructor(private authService: AuthService,
    private router: Router,
    private localStorageService: DataService,
    private themeService: ThemeService,
    private matdialog: MatDialog,
    private userService: UserService,
    private popupService: PopupService
  ) {
      this.localStorageService.darkTheme.subscribe((newValue) => {
        this.darkTheme = newValue;
        this.deletedImagePath = this.darkTheme
        ? '../../../assets/images/deleted-dark.png'
        : '../../../assets/images/deleted.png';
      });
      this.isInIframe = this.themeService.isInIframe();    
  }

  ngOnInit(): void {
    const castUser = this.authService.castCurrentUser.subscribe(async (res: any) => {
      this.currentUser = await res;

      if (!this.currentUser?.verified) {
        this.router.navigate(['login']);
      }
      else {
        this.authService.user.subscribe(user => this.currentUser = user);
        this.userid = this.currentUser.id;
        this.getUsers(this.userid,this.currentUser.organisationId);
      }
      castUser.unsubscribe();
    });
  }

  desktopVersion() {
    this.matdialog.open(DesktopVersionComponent, {
      maxWidth: '80%',
      backdropClass: 'blurred',
      disableClose: true,
    });
  }

  getUsers(userId: any,organisationId: any) {
    this.userService.getUser(userId,organisationId).subscribe(
      (result: any) => {
        
        this.tableDataLength = result?.users?.length > 0 ? result?.users?.length: 0;

        if (result?.users?.length > 0) {
          this.dataSource = new MatTableDataSource(result?.users);
        }
      },
      (err) => {
        return false;
      }
    );
  }

  handleButtonClick(event: Event) {
    event.stopPropagation();
  }

  addUser() {
      const dialog = this.matdialog.open(AdvanceSettingsComponent, {
        minWidth: '45%',
        backdropClass: 'blurred',
        disableClose: true,
        data: {type: 'userManagement', user: this.currentUser}
      });
  
      dialog.afterClosed().subscribe((result) => {
        this.getUsers(this.userid,this.currentUser.organisationId);
      });
    }


  updateUser(row) {
    const dialog = this.matdialog.open(AdvanceSettingsComponent, {
      minWidth: '45%',
      backdropClass: 'blurred',
      disableClose: true,
      data: {type: 'userManagement', user: this.currentUser, selectedUser: row}
    });

    dialog.afterClosed().subscribe((result) => {
      this.getUsers(this.userid,this.currentUser.organisationId);
    });
  }

  deleteUser(row) {
    let userObj = {
     userId: this.userid,
     subUserId: row._id,
     action: 'delete'
    }

    const dialogRef = this.matdialog.open(ConfirmPopupComponent, {
      width: '35%',
      disableClose: true,
      backdropClass: 'blurred',
      data: { message: 'userDelete' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.userService.updateUser(userObj).subscribe((res: any) => {
          if (res) {
            if (res.msg === 'There is an ongoing task for this user') {
              const message = 'There is an ongoing tasking by this user.';
              const type = 'warning'
              this.popupService.openPopup(WarningPopupComponent, message, type, '40%');
            } else this.getUsers(this.userid,this.currentUser.organisationId);      
          }   
         });
      }
    });
  }

}
