<div class="full-width">
  <div class="content">
    <div class="select-head" style="display: flex;flex-direction: column;">
      <h6>Select Sensors</h6>
      <div class="table-check">
        <div>
          <span *ngFor="let sensor of SensorsTypes; let i = index">
            <span>
              <mat-checkbox [(ngModel)]="sensor.selected" [checked]="sensor.selected"
                (ngModelChange)="resetSorting();updateAllComplete(sensor, i)" [disabled]="isNightSensor || isTipAndCueRule || (this.currentUser.pointsEligible && sensor.value === 'AIS')">
                <span style="
                          font-size: 16px;
                          padding-right: 20px;
                          color: #026fc2;
                          font-weight: 300;
                        ">{{ sensor.name }} <img alt=""
                        [src]="tooltipImagePath"
                    style="margin: 0 0 4px 5px;" matTooltip={{sensor.description}}></span>
              </mat-checkbox>
            </span>
          </span>
        </div>
        <div>
          <mat-slide-toggle [(ngModel)]="isNightSensor" [disabled]="isTipAndCue" (click)=" !isTipAndCue ? toggleNightSensors() : undefined"
            matTooltip="Shows all night vision sensors">Night Imagery</mat-slide-toggle>

          <mat-slide-toggle [(ngModel)]="isAllSensors" (click)="resetSorting();toggleAll()"
            matTooltip="Shows all sensors including available in near future"
            [disabled]="!selectedSensorTypes || isNightSensor || isTipAndCue">Show Upcoming
            Sensors</mat-slide-toggle>
        </div>
      </div>
      <span class="toggle">'x' is the base cost</span>
    </div>
  </div>
</div>
<div class="tab-panels scrollbar" id="scrollcustom">
<div class="rows table-checkbox">
  <table class="tasking-cost-table" *ngIf="selectedSensorTypes" mat-table [dataSource]="dataSource" matSort
  (matSortChange)="sortData($event)" class="mat-elevation-z8">
    <ng-container matColumnDef="operator">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header sortActionDescription="Sort by operator">
        <span matTooltip="Sensor name with the sensor type">Sensor Name</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let i = index">
        <span>
          <mat-checkbox  (change)="getTotalCost($event.checked, i, row)"
          [(ngModel)]="row.checked"
            [disabled]="!row.enabled || row.prevent ||
            (this.capellaEnable && row.sensor != 'SAR') || (this.satCapeEnable && row.sensor != 'SAR' && row?.key != OPERATOR.SATELLOGIC && row?.key != OPERATOR.STE && row?.key != OPERATOR.USL && row?.key != OPERATOR.BLACKSKY
            && row?.key != OPERATOR.LBAND)">
            <div style="display: flex">
              <div>
                <span [matTooltip]="row?.sensorDesc">
                  {{ (currentUser?.obfuscated && row.obfuscateName)? row.obfuscateName : row.name }} {{ row.sensor }}</span>
              </div>
              <!-- <span style="margin-left: 5px;" *ngIf="row?.sensorTooltip">
                <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
                  [matTooltip]="row?.sensorTooltip" [matTooltipClass]="'custom-tooltip'" />
              </span> -->

              <span style="margin-left: 5px;" *ngIf="row?.cancelPolicy">
                <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
                  [matTooltip]="this.detailsText" matTooltipClass="tooltips"
                  (mouseover)="selections(row)"
                  (mouseout)="onMouseOut()"
                  [matTooltipDisabled]="tooltipDisabled" />
              </span>
            </div>
          </mat-checkbox>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="observationSensorType">
      <th mat-header-cell  class="table-header-cell" *matHeaderCellDef><span matTooltip="Tip-and-Cue Sensor Type">Sensor Type</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let i = index" >
        <span>
          <mat-select [(ngModel)]="row.selectedTnqSensorType" [disableOptionCentering]="true" placeholder="Type" (selectionChange)="onSensorTypeChange($event, i)" [disabled]="row.tnqSensorDisabled || row.prevent" style="width:65px">
            <mat-select-trigger>{{row?.selectedTnqSensorType}}</mat-select-trigger>
            <mat-option class="triggers" *ngFor="let option of observationSensorTypeOption; let j = index"  [selected]="row.selectedTnqSensorType === option.name" [value]="option.name"
            [disabled]="this.target.rule === 'rule-3' && ((row.sensortype === 'MSI' && j === 1) || (row.sensortype === 'SAR' && j === 0))">
              <div class="d-flex justify-content-between" style="font-size: 14px;">
                <div style="font-weight: bold; display: flex;">
                  <div class="d-flex justify-content-end">
                    {{ option.name }}
                  </div>
                  <img style="margin-left: 8px" width="15px" [src]="tooltipImagePath"
                  [matTooltip]="option.description" matTooltipPosition="right"/>
                </div>
              </div>
            </mat-option>
          </mat-select>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="band">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span *ngIf="row?.sensorTooltip">
          <mat-icon class="material-icons-outlined map-active" style="cursor: default" 
          [matTooltip]="row?.sensorTooltip" [matTooltipClass]="'custom-tooltip'">
              graphic_eq
          </mat-icon>

          <mat-icon *ngIf="row.sensortype === 'HSI'"  class="material-icons-outlined map-active"
           style="cursor: pointer;margin-left: 19px;font-size: 17px;" 
           (click)="getBandInfo(row.key)" matTooltip="Band Informations"
          [matMenuTriggerFor]="tooltipMenu">
          description
         </mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="preview">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span *ngIf="row.enabled" (click)="OpenPopup(row)">
          <mat-icon class="material-icons-outlined map-active" style="cursor: pointer" matTooltip="Sample">
            image
          </mat-icon>
        </span>
        <span *ngIf="!row.enabled">
          <mat-icon class="material-icons-outlined map-active" style="color: gray;">
            image
          </mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    
  <ng-container matColumnDef="mode">
    <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
      <span matTooltip="Sensor imaging mode/ Image product">Mode/Product</span>
    </th>
    <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index"
      style="text-align: left; padding-right: 5px;">
      <span>
        <span *ngIf="row.collection_mode?.length === 1 && row?.key === OPERATOR.USL" style="width: 90px; padding-left: 3px; padding-right: 5px;">
          <ng-container *ngFor="let detail of row.selectedModes[0]?.key.split(','); let last = last">
            <span
              class="truncate-text"
              [matTooltip]="row.selectedModes[0]?.key"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
              >{{ detail.trim() }}
            </span>
            <ng-container *ngIf="!last">,</ng-container>
            <br *ngIf="!last">
          </ng-container>
          <span style="margin-left: 5px;" *ngIf="row.selectedModes[0]?.tooltip">
            <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
              [matTooltip]="row.selectedModes[0]?.tooltip"/>
          </span>
        </span>
        <span *ngIf="row.collection_mode?.length === 1 && row?.key != OPERATOR.USL" style="width: 90px; padding-left: 3px; padding-right: 5px;">
          <span
            class="truncate-text"
            [matTooltip]="getProductTooltip(row)?.length > 8 ? getProductTooltip(row) : '' "
            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; display: inline-block;"
            [ngStyle]="{
              'max-width': (getTextLength(row.selectedModes[0]?.captureMode, row.selectedModes[0]?.productLevel) > 12) ? '90px' : 'auto'
            }"
          >
            <ng-container *ngIf="row.selectedModes[0]?.isCaptureMode && !row.selectedModes[0]?.isProductLevel">
              {{ row.selectedModes[0].captureMode }}
            </ng-container>
            <ng-container *ngIf="row.selectedModes[0]?.isProductLevel && !row.selectedModes[0]?.isCaptureMode">
              {{ row.selectedModes[0].productLevel }}
              <img *ngIf="row.selectedModes[0]?.productDescription" style="margin-left: 8px;margin-top: -2px;" width="15px" [src]="tooltipImagePath"
              [matTooltip]="row.selectedModes[0]?.productDescription" />
            </ng-container>
            <ng-container *ngIf="row.selectedModes[0]?.isProductLevel && row.selectedModes[0]?.isCaptureMode">
              {{ row.selectedModes[0].captureMode }} ({{ row.selectedModes[0].productLevel }})
            </ng-container>
          </span>
          <span style="margin-left: 5px;" *ngIf="row.selectedModes[0]?.tooltip">
            <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
              [matTooltip]="row.selectedModes[0]?.tooltip"/>
          </span>
          <mat-icon *ngIf="row.selectedModes[0]?.description"  class="material-icons-outlined map-active" style="cursor: default;margin-left: 9px;font-size: 17px;" 
                    [matTooltip]="row.selectedModes[0]?.description">
                    description
          </mat-icon>
        </span>
        

        <mat-select *ngIf="row.collection_mode?.length > 1"
          [disableOptionCentering]="true" class="mode-selector"
          [panelClass]="row?.collection_mode?.length > 4 ? 'matOptionClass' : row?.collection_mode?.length > 3 ? 'matOptionStyle' : row?.collection_mode?.length > 2 ? 'matOpenmodethreeTable' : row?.collection_mode?.length > 1 ? 'matOpenmodetwoTable' : 'matOpenmodeoneTable'"
          style="width: 60px; padding-left: 1px;" [placeholder]="getPlaceHolder(row, idx)"
          (selectionChange)="modeOption($event.value, idx, row)" [multiple]="row?.modeType" [(ngModel)]="row.modeResetValue"
          [matTooltip]="(row?.selectedMode && row?.selectedMode?.length > 5) ? row?.selectedMode : 
          (getPlaceHolder(row, idx).length > 9) ? getPlaceHolder(row, idx) :''">
          <mat-select-trigger>{{row.selectedMode}}</mat-select-trigger>
          <mat-option class="triggers" *ngFor="let item of row.collection_mode; let i = index" [ngValue]="item.key"
            [value]="item"
            [disabled]="(row.prevent && (row?.key != OPERATOR.CAPELLA && row?.key != OPERATOR.GHG)) || (capellaEnable && row.sensor != 'SAR') || (satCapeEnable && row.sensor != 'SAR' && row?.key != OPERATOR.SATELLOGIC && row?.key != OPERATOR.STE && row?.key != OPERATOR.USL && row?.key != OPERATOR.BLACKSKY)
              || (spot && row?.key === OPERATOR.STE && i > 1) || (strip && row?.key === OPERATOR.STE && i < 2)
              || (noOfMonths < 6 && row?.key === OPERATOR.GHG && item.captureMode.includes('annual')) || 
              (noOfWeeks < 2 && row?.key === OPERATOR.GHG && item.captureMode.includes('biweekly'))
              || (target.area === 0 && i > 2 && row?.sensor === 'MSI' && row?.key === OPERATOR.KOMPSAT)
              || (target.area > 0 && i < 3 && row?.sensor === 'MSI' && row?.key === OPERATOR.KOMPSAT) || (row?.key === OPERATOR.GHG && (target?.multiTarget?.length < 3 || (target?.multiTarget?.length >= 3 && multiPointsCoveredArea > 144)) && item.captureMode.includes('Complex'))
              || (row?.key === OPERATOR.GHG && (target?.multiTarget?.length > 2 && multiPointsCoveredArea <= 144) && !item.captureMode.includes('Complex')) ||
              (row?.key === OPERATOR.SATELLOGIC && ((target.area === 0 && item?.captureMode === 'Area') ||
              (target.area > 0 && item?.captureMode != 'Area')||
              (this.target.area > 0 && item?.captureMode != 'Area'))) || (row?.key === OPERATOR.ISI && row?.sensor === 'Stereo' && item.key === 'Pan-sharpened' && row?.valueAddedOption?.length > 0)">
            <!-- Content remains unchanged for the dropdown -->
            <div class="d-flex justify-content-between" style="font-size: 14px;font-weight: bold;">
                    
              <span *ngIf="item?.isCaptureMode && !item?.isProductLevel">{{ item.captureMode }}
              <img *ngIf="item?.tooltip" style="margin-left: 15px;" width="15px" [src]="tooltipImagePath"
              [matTooltip]="item?.tooltip" />
              <mat-icon *ngIf="item?.description"  class="material-icons-outlined map-active" style="cursor: default;margin-left: 9px;font-size: 17px;" 
              [matTooltip]="item?.description">
              description
              </mat-icon>
            </span>

            <div *ngIf="item?.isProductLevel && !item?.isCaptureMode" 
            style="display: flex;
            width: 50px;
            justify-content: space-between;">
              <span>{{ item.productLevel }}
              </span>
              <img *ngIf="item?.productDescription" style="margin-left: 15px;" width="15px" [src]="tooltipImagePath"
              [matTooltip]="item?.productDescription" />
            </div>



            <div>
              <span *ngIf="item?.isProductLevel && item?.isCaptureMode">{{ item.captureMode }}
                <img *ngIf="item?.tooltip" style="margin-left: 2px;" width="15px" [src]="tooltipImagePath"
                  [matTooltip]="item?.tooltip" />
              </span>
              <span *ngIf="item?.isProductLevel && item?.isCaptureMode" style="padding-left: 2px;">
                ({{ item.productLevel }}
                <img *ngIf="item?.productDescription" style="margin-left: 2px;" width="15px" [src]="tooltipImagePath"
                  [matTooltip]="item?.productDescription" /> )
              </span>
              <span style="margin-left: 5px;" *ngIf="row?.key === OPERATOR.UMBRA && (item?.value === '10km x 10km' || item?.value === '10km x 10km(CPHD)')">
                <img class="mt-10"  alt ="" [src]="qualitySticker"
                />
              </span>
            </div>
      </div>
          </mat-option>
        </mat-select>
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>


   <ng-container matColumnDef="resolution">
      <th mat-header-cell class="table-header-cell border-class" *matHeaderCellDef mat-sort-header sortActionDescription="Sort by resolution" style="width: auto; white-space: nowrap; margin-left: 10px">
        <span [matTooltip]="resolutionColumnTooltip" [ngStyle]="{'width': aisOnly ? '90px' : '182px'}">{{columnResolution}}</span>
      </th>
      <td mat-cell class="cell-Main-Table border-style" *matCellDef="let row; let idx = index" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-left: 10px">
        <span *ngIf="row.updateFrequency">
          {{row.updateFrequency}}
        </span>

        <span *ngIf="row?.resolution?.length === 1">{{ row?.resolution[0].value }}
          <img *ngIf="row?.resolution[0]?.mode_value" style="margin-left: 5px;padding-top: 1px;" width="15px" [src]="tooltipImagePath"
          [matTooltip]="row?.resolution[0]?.mode_value"/>
        </span>

        <mat-select [disableOptionCentering]="true" 
        [matTooltip]="row?.selectedResolution.value?.length > 10 ? row?.selectedResolution.value : ''"
        [panelClass]="row?.resolution?.length > 3 ? 'matOpenResFourTable' : row?.resolution?.length > 2 ? 'matOpenResThreeTable' : 'matOpenResTwoTable'"
        *ngIf="row.resolution?.length > 1"
          style="width: 90px; white-space: nowrap;" (selectionChange)="resolutionChange($event.value, idx, row)" placeholder="{{row?.selectedResolutionValue ? row?.selectedResolutionValue : row?.selectedResolution ? row?.selectedResolution.value : row.resolution[0].value}}"
          [(ngModel)]="row.selectedResolutionData">
          <mat-select-trigger style="font-size: 14px; white-space: nowrap;">{{row?.selectedResolutionValue}}</mat-select-trigger>
          <mat-option class="triggers" *ngFor="let item of row.resolution" [ngValue]="item.value"
            [selected]="row.resolution === item.value" [value]="item"
            [disabled]="shouldDisableResolution(item,row,idx)">
            <div class="d-flex justify-content-between"  style="white-space: nowrap;">
              <div style=" font-weight: bold;font-size: 14px;">
                <div class="d-flex justify-content-end">
                  {{ item.value }}
                  <span>
                    <img *ngIf="item?.mode_value" 
                         style="margin-left: 10px; padding-top: 18px;" 
                         width="15px" 
                         [src]="tooltipImagePath"
                         [matTooltip]="item?.mode_value"/>
                  </span>
                </div>
              </div>
              <div style="text-align: right;" *ngIf="row?.key === OPERATOR.AXELSPACE || 
              row?.key === OPERATOR.AT21">
                {{ item?.multiplier }}x
              </div>
            </div>
          </mat-option>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="looks" *ngIf="showLooks">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
        <span>Looks</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index">
        <mat-select [disableOptionCentering]="true" *ngIf="row?.selectedResolution?.numberOfLooks"
        [panelClass]="'mat-select-panel-up'"
        [(ngModel)]="row.selectedLooks"
          style="width: 90px" (selectionChange)="looksChange($event.value, idx, row)" placeholder="{{row?.selectedLooksValue ? row.selectedLooksValue : row.selectedResolution.numberOfLooks[0]}}">
          <mat-option class="triggers" *ngFor="let item of row.selectedResolution.numberOfLooks" [ngValue]="item"
            [value]="item" [disabled]="(row?.selectedResolutionValue === '1m' && row.selectedModes[0].value === 'SAR Video' && item != 'x5')">
            <div class="d-flex justify-content-between">
              <div style=" font-weight: bold;font-size: 14px;">
                <div class="d-flex justify-content-end">
                  {{ item }}
                </div>
              </div>
            </div>
          </mat-option>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="signalOfInterest" *ngIf="lband">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
        <span style="width: 115px;" matTooltip="Which RF product?">Signal of Interest</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index">
        <mat-select *ngIf="row?.signal_of_interest" multiple [disableOptionCentering]="true" #mySelects
          [panelClass]="'matOpenDatafourTable'"
          (selectionChange)="signalChange($event, idx,mySelects)"
          [placeholder]="row?.signalOfInterest?.length > 0 ? row?.selectedSignals : 'Select Signals'"
          [ngClass]="row?.signalOfInterest?.length > 0 ? 'placeholder-color-active' : ''"
          [value]="row?.signalOfInterest"
          (openedChange)="onOpenedChange($event)">
          <mat-select-trigger>{{
            row?.selectedSignals ? row?.selectedSignals : 'Select Signals'
            }}</mat-select-trigger>
          <mat-optgroup>
            <!-- Header row with column names -->
            <div class="mat-optgroup-header">
              <span class="column signal-column">Signals</span>
              <span class="column duration-column">Collection Duration</span>
              <span class="column audio-column">Audio</span>
            </div>
        
            <!-- Data rows -->
            <mat-option *ngFor="let item of row.signal_of_interest; let i = index" [value]="item"
              style="width: 100%;" [disabled]="row.prevent">
              <div class="tasking-list-menu" style="display: flex; justify-content: space-between; align-items: center;"
              (click)="$event.stopPropagation()">
        
                <!-- Signal Column -->
                <span class="column signal-column">
                  {{ item.value }}
                </span>
        
                <!-- Collection Duration Column -->
                <mat-select *ngIf="item.collection_duration && !item.isAudio && item.collectionDuration != 60" placeholder="{{item.collectionDuration ? item.collectionDuration : item.collection_duration[0].value}} secs"
                  class="column duration-column" (click)="isDurationChanging = true" [disabled]="row.prevent"
                  (selectionChange)="collectionChange($event, i, item, row)">
                  <mat-option *ngFor="let duration of item.collection_duration" [value]="duration"
                  [ngClass]="{'highlighted-option': duration.value === item.collectionDuration}"
                  >{{ duration.value }} secs
                  </mat-option>
                </mat-select>

                <div *ngIf="item.isAudio || item.collectionDuration === 60" class="single-value duration-column">{{ item.collectionDuration }} secs</div>
        
                <!-- Audio Column with checkbox -->
                <div class="column audio-column" style="display: flex; align-items: center;">
                  <mat-checkbox *ngIf="item?.audioCost" (change)="audioChange($event, item, idx,row)" [disabled]="row.prevent"
                  [checked]="item.collectionDuration === 60"></mat-checkbox>
                  <img *ngIf="item?.audioCost" [src]="tooltipImagePath"
                    [matTooltip]="'Cost of audio recording = ' + convertCostToPoints(item.audioCost) + '. The amount will be refunded to your account if no audio is processed.'"
                    style="cursor: pointer; margin-left: 8px;" width="15px" />
                </div>
        
              </div>
            </mat-option>

            <!-- close Button -->
          <mat-option class="no-checkbox vadp-dropdown-option sticky-option" 
          *ngIf="row?.signal_of_interest?.length > 0">
          <div class="option-content">
            <button  mat-stroked-button 
              class="dropdown-button small-button"
              [ngClass]="{'dropdown-ok-button': row?.signalOfInterest?.length > 0}"
              (click)="row?.signalOfInterest?.length > 0 ? goNext(mySelects,$event, idx, row) : close(mySelects,$event);"
            >
              <ng-container *ngIf="row?.signalOfInterest?.length > 0; else dropDownButton">
                <span>Ok</span>
              </ng-container>
              
              <ng-template #dropDownButton>
                <span>Close</span>
              </ng-template>
            </button>
          </div>
        </mat-option>
          </mat-optgroup>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef style="color: red">
        Unit
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let i = index">
        {{ row.unit }}
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <ng-container matColumnDef="unitcost">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
        <span matTooltip="Tasking priorities—fastest to the slowest">Tasking Tier</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index">
        <div *ngIf="row.enabled && row.latency" id="costOption" aria-label="Select an option" style="max-width: 110px;">
          <mat-select [disableOptionCentering]="true" style="text-transform: capitalize;" #mySelect
          [panelClass]="row.latency.length > 4 ? 'matOpenDatafourTable' : row.latency.length > 3 ? 'matOpenDataFiveTable' : row.latency.length > 2 ? 'matOpenDatathreeTable' : 'matOpenDataoneTable'"
            (selectionChange)="taskingOption($event.value, idx, row,0)" [placeholder]="row.selectedLatency">
            <mat-select-trigger>{{
              row.selectedLatency | titlecase
              }}</mat-select-trigger>
              <mat-optgroup>
                <div class="mat-optgroup-cell">
                  <span>Tasking tier</span>
                  <span>Reaction time 
                    <img width="15px" [src]="tooltipImagePath"
                    matTooltip="The latest time to schedule a task request before the overpass" />
                  </span>
                  <span>Response time 
                    <img width="15px" [src]="tooltipImagePath"
                    matTooltip=" Time from collection by satellite(s) to delivery" />
                  </span>
                  <span style="padding-left: 15px;" [hidden]="cpcAndAllocated">Cost</span>
                </div>
            <mat-option *ngFor="let elem of row.latency; let i = index" [ngValue]="elem.name"
              [selected]="elem.cost === row.taskcost" [value]="elem.name" style="width: 100%;"
              [disabled]="shouldDisableTier(elem,row)"
              >
              <div class="tasking-list-menu">
                <span class="standards">{{ elem.name | titlecase }}
                    <img *ngIf="row?.key === OPERATOR.SATELLOGIC" style="margin-left: 2px;padding-top: 3px;" width="15px" [src]="tooltipImagePath"
                    [matTooltip]="elem?.key === 'RR' ? 'Use over open waters and land' : 'Use over land only'" />
                    <img *ngIf="row?.key === OPERATOR.SATVU && elem?.key === 'monitoring'" style="margin-left: 2px;padding-top: 3px;" width="15px" [src]="tooltipImagePath"
                    [matTooltip]="'This is 5 scenes at 650/scene'" />
                </span>
                <span class="latency">{{elem?.taskingwindow}}</span>
                <span class="latency" *ngIf="elem.cost != 0.00">
                  <span *ngIf="elem?.latency"> {{elem?.mode }}</span>
                   {{ elem?.latency }} </span>
                <span class="latency" *ngIf="elem.cost == 0.00"> Not Offered </span>
                <span *ngIf="!currentUser.pointsEligible" class="currency" style="padding-left: 52px;font-size: 14px !important;" [hidden]="cpcAndAllocated">${{ elem.cost }}</span>
                <span *ngIf="currentUser.pointsEligible" class="currency" style="padding-left: 52px;font-size: 14px !important;" [hidden]="cpcAndAllocated">{{ convertCostToPoints(elem.cost) }}</span>
              </div>
            </mat-option>
            </mat-optgroup>
          </mat-select>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="holdback" *ngIf="!lbandOnly">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
        <span
          matTooltip="The time a satellite operator takes to include a tasked image in the archive for access to other customers">Hold
          Back</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index"
        style="text-align: left; padding-right: 5px">
        <mat-select [disableOptionCentering]="true"
          [panelClass]="row.holdback.length > 3 ? 'matOpenholefourTable' : row.holdback.length > 2 ? 'matOpenholethreeTable' :'matOpenholetwoTable'" style="overflow: inherit;"
          *ngIf="row.holdback?.length > 0" style="width: 74px" placeholder="{{row.selectedHoldBack}}"
          (selectionChange)="holdBackOption($event.value, idx, row)" [(ngModel)]="row.holdBackValue">
          <mat-select-trigger [matTooltip]="row?.selectedHoldBack">{{ row.selectedHoldBack }}</mat-select-trigger>
          <mat-option class="triggers" *ngFor="let item of row.holdback; let i = index" [ngValue]="item.key"
            [selected]="row.holdback === item.key" [value]="item"
            [disabled]="(currentUser.allocated && row.selectedHoldBack !== item.key) || (i > 0 && row?.key === OPERATOR.SATELLOGIC) || row.prevent || (this.capellaEnable && row.sensor != 'SAR') || (this.satCapeEnable && row.sensor != 'SAR' && row?.key != OPERATOR.SATELLOGIC && row?.key != OPERATOR.STE 
            && row?.key != OPERATOR.USL && row?.key != OPERATOR.BLACKSKY) || (row?.key === OPERATOR.WYVERN && item.type != row.selectedLatencyKey.key)">
            <div class="d-flex justify-content-between">
              <div style=" font-weight: bold;">
                <div class="d-flex justify-content-end">
                  {{ item.key }}
                </div>
              </div>
              <div style=" text-align: right;">
                {{ item.value }}x
              </div>

            </div>
          </mat-option>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="area_calculated">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef mat-sort-header sortActionDescription="Sort by area_calculated">
        <span matTooltip="Minimum collection area payable.           The first number in the scene size indicates the swath width."
        matTooltipClass="custom-header-tooltip">Min. Collection</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index">
        <div class="flex" style="width: 130px;">
          <span *ngIf="row.enabled && row?.minarea">
            <span *ngIf="(row?.sensor != 'Video' && row?.sensor != 'InSAR') && !(row?.sensor === 'Stereo' && row.name === 'KOMPSAT-5')">{{ row.minarea }} {{ row?.collectionunit ? row.collectionunit : row.unit }}</span>
            <span *ngIf="row?.sensor === 'Video' || row?.sensor === 'InSAR' || (row?.sensor === 'Stereo' && row.name === 'KOMPSAT-5')">{{ row.unit }}</span>
            <span *ngIf="row?.tooltipValue">
              <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
                [matTooltip]="row.tooltipValue"
                matTooltipPosition="below" /></span>
          </span>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="dem">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
        <span matTooltip="Value Added Data Products" style="width: 166px;">Value Added Data Products</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index">
        <mat-select
          #vadpselect 
          class="custom-checkbox" 
          multiple 
          *ngIf="(((row.sensor === 'Stereo' || row.sensor === 'Tristereo') && target.selectedOption !== 1) || (row.sensor === 'MSI')) && 
          row?.valAddedArray?.length > 0"
          placeholder="Select Products" 
          [ngClass]="{
            'reduced-opacity': row?.valueAddedAction.length === 0 &&
            (row?.superResolutions?.length === 0 || (row?.superResolutions?.length > 0 && target.selectedOption === 1)) && 
            row?.indicesArray?.length === 0
          }" 
          [value]="getSelectedVadp(row)"
          [matTooltip]="displayVadpSelectTooltip(row)"
          panelClass="vadp-mat-select-position-top"
        >
          <mat-select-trigger style="font-weight: 300;" [matTooltip]="formatGroupedDemTooltip(row.vadp)">
            <span [ngClass]="formatGroupedDem(row.vadp) ? '' : 'format-vadp'" >{{ formatGroupedDem(row.vadp) || 'Select Products'}}</span>
          </mat-select-trigger>

          <!-- DxM Checkbox Option -->
          <mat-option value="DxM" *ngIf="row?.valueAddedAction?.length > 0 && target.selectedOption !== 1" (click)="onDxMCheckboxChange(row, idx, 'DxM')" class="value-added-option no-checkbox">
            <div class="option-content">
              <span class="value-added-caption">Elevation Model</span>
              <mat-icon class="arrow-icon" *ngIf="!row.isDxMSelected">keyboard_arrow_down</mat-icon>
              <mat-icon class="arrow-icon" *ngIf="row.isDxMSelected">keyboard_arrow_up</mat-icon>
            </div>
          </mat-option>
          <mat-option disabled class="heading-option sticky-header" *ngIf="row?.valueAddedAction?.length > 0 && row.isDxMSelected && target.selectedOption !== 1">
            <div class="option-row header head-flex justify-content-between">
              <div class="header-item resolution">Resolution</div>
              <div class="header-item delivery-time">Delivery Time 
                <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                matTooltip="The time between the delivery of satellite data and the value-added product."/>
              </div>
              <div class="header-item cost-points">
                <span *ngIf="currentUser?.pointsEligible">Points
                  <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                matTooltip="The cost per sqkm will be reflected in the unit cost."/>
                </span>
                <span *ngIf="!currentUser?.pointsEligible">Cost
                  <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                matTooltip="The cost per sqkm will be reflected in the unit cost."/>
                </span>
              </div>
            </div>
          </mat-option> 
          <ng-container *ngIf="row?.valueAddedAction?.length > 0 && row.isDxMSelected && target.selectedOption !== 1">
            <ng-container *ngFor="let group of row?.valueAddedAction; let i = index">
              <!-- Option groups -->
              <mat-optgroup class="custom-option-group" [label]="group.actionProvider">
                <mat-option class="no-active" *ngFor="let dem_item of group.items;let k = index" 
                            [value]="dem_item" 
                            (click)="selectDxMOption(dem_item, row, group, idx)" 
                            [disabled]="!row.isDxMSelected || row.prevent">
                  <div class="option-row head-flex justify-content-between">
                    <div class="item-content resolution">
                      <span class="custom-standards">{{ dem_item.providerResolution }}</span>
                    </div>
                    <div class="item-content delivery-time" style="padding-left: 0">
                      <span class="custom-currency">{{ dem_item?.deliveryTime }}hrs</span>
                    </div>
                    <div class="item-content cost-points">
                      <span class="custom-currency" *ngIf="currentUser?.pointsEligible">
                        {{ dem_item?.point || 0 }} points
                      </span>
                      <span class="custom-currency" *ngIf="!currentUser?.pointsEligible">
                        ${{ dem_item.cost | number }}
                      </span>
                    </div>
                  </div>
                </mat-option>
              </mat-optgroup>
            </ng-container>
          </ng-container>
          
          <!-- SR Checkbox and Option Group -->
          <mat-option value="SR" *ngIf="row?.superResolutions?.length > 0 && target.selectedOption !== 1" (click)="onSRCheckboxChange(row, idx, 'SR')" class="value-added-option no-checkbox">
            <div class="option-content">
              <span class="value-added-caption">Super Resolution</span>
              <mat-icon class="arrow-icon" *ngIf="!row.isSRSelected">keyboard_arrow_down</mat-icon>
              <mat-icon class="arrow-icon" *ngIf="row.isSRSelected">keyboard_arrow_up</mat-icon>
            </div>
          </mat-option>
          <mat-option disabled class="heading-option sticky-header" *ngIf="row?.superResolutions?.length > 0 && row.isSRSelected">
            <div class="option-row header head-flex justify-content-between">
              <div class="header-item resolution">Resolution</div>
              <div class="header-item delivery-time">Delivery Time
                <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                matTooltip="The time between the delivery of satellite data and the value-added product."/>
              </div>
              <div class="header-item cost-points">
                <span *ngIf="currentUser?.pointsEligible">Points
                  <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                matTooltip="The cost per sqkm will be reflected in the unit cost."/>
                </span>
                <span *ngIf="!currentUser?.pointsEligible">Cost
                  <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                matTooltip="The cost per sqkm will be reflected in the unit cost."/>
                </span>
              </div>
            </div>
          </mat-option> 
          <ng-container *ngIf="row?.superResolutions?.length > 0 && row.isSRSelected">
            <mat-option *ngFor="let resolution of row?.superResolutions; let j = index" class="no-active" 
                        [value]="resolution" 
                        [disabled]="!row.isSRSelected || row.prevent"
                        (click)="selectSROption(resolution, row, idx)">
              <div class="option-row head-flex justify-content-between">
                <div class="item-content resolution">
                  <span class="custom-standards">{{ resolution.providerResolution }}</span>
                </div>
                <div class="item-content delivery-time" style="padding-left: 0">
                  <span class="custom-currency">{{ resolution?.deliveryTime }}hrs</span>
                </div>
                <div class="item-content cost-points">
                  <span class="custom-currency" *ngIf="currentUser?.pointsEligible">
                    {{ resolution?.point || 0 }}
                  </span>
                  <span class="custom-currency" *ngIf="!currentUser?.pointsEligible">
                    ${{ resolution.cost | number }}
                  </span>
                </div>
              </div>
            </mat-option>
          </ng-container>

          <!-- Indices Checkbox and Option Group -->
          <mat-option value="Indices" *ngIf="row?.indicesArray?.length > 0" (click)="onIndicesCheckboxChange(row, idx, 'Indices')" class="value-added-option no-checkbox">
            <div class="option-content">
              <span class="value-added-caption">
                Indices
                <img
                class="beta-icon-indices"
                src="../../../assets/images/beta-version.png">
              </span>
              <mat-icon class="arrow-icon" *ngIf="!row.isIndicesSelected">keyboard_arrow_down</mat-icon>
              <mat-icon class="arrow-icon" *ngIf="row.isIndicesSelected">keyboard_arrow_up</mat-icon>
            </div>
          </mat-option>
          <ng-container *ngIf="row?.indicesArray.length > 0 && row.isIndicesSelected">
            <mat-option disabled class="heading-option sticky-header">
              <div class="option-row header head-flex justify-content-between">
                <div class="header-item resolution">Product</div>
                <div class="header-item delivery-time"></div>
                <div class="header-item cost-points">
                  <span *ngIf="currentUser?.pointsEligible">Points
                    <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                    matTooltip="The cost is for all the scenes in a single task and will be added when finalising payment."/>
                  </span>
                  <span *ngIf="!currentUser?.pointsEligible">Cost
                    <img style="margin-left: 7px" width="15px" [src]="tooltipImagePath"
                    matTooltip="The cost is for all the scenes in a single task and will be added when finalising payment."/>
                  </span>
                </div>
              </div>
            </mat-option>
            <mat-option *ngFor="let indices of row?.indicesArray; let j = index" class="no-active" 
                        [value]="indices" 
                        [disabled]="!row.isIndicesSelected || row.prevent"
                        (click)="selectIndicesOption(indices, row, idx)">
              <div class="option-row head-flex justify-content-between">
                <div class="item-content resolution" [matTooltip]="indices.actionProvider" class="truncated-option">
                  <span class="custom-standards">
                    {{ indices.actionProvider.length > 10 ? (indices.actionProvider | slice:0:10) + '...' : indices.actionProvider }}
                  </span>
                </div>
                <div class="item-content delivery-time">
                  <!-- <span class="custom-currency" style="padding-left: 10px;">{{ indices?.deliveryTime }}hrs</span> -->
                </div>
                <div class="item-content cost-points">
                  <span class="custom-currency" *ngIf="currentUser?.pointsEligible">
                    {{ indices?.point || 0 }}
                  </span>
                  <span class="custom-currency" *ngIf="!currentUser?.pointsEligible">
                    ${{ indices.cost | number }}
                  </span>
                </div>
              </div>
            </mat-option>
          </ng-container>
          
          <!-- close Button -->
          <mat-option value="DxM" class="no-checkbox vadp-dropdown-option sticky-option" 
            *ngIf="row?.valueAddedAction?.length > 0 || row?.indicesArray?.length > 0 || (row?.superResolutions?.length > 0 && target.selectedOption !== 1)">
            <div class="option-content">
              <button  mat-stroked-button 
                class="dropdown-button small-button" 
                (click)="closeSelectDropdown(vadpselect)"
                [ngClass]="{'dropdown-ok-button': row?.indices?.length > 0 || row?.valueAddedOption?.length > 0 || row?.super_resolution?.length > 0}" 
              >
                <ng-container *ngIf="row?.indices?.length > 0 || row?.valueAddedOption?.length > 0 || row?.super_resolution?.length > 0 ; else dropDownButton">
                  <span>Ok</span>
                </ng-container>
                
                <ng-template #dropDownButton>
                  <span>Close</span>
                </ng-template>
              </button>
            </div>
          </mat-option>
        </mat-select>
      </td>
    </ng-container>

    <ng-container matColumnDef="cloudcovers" *ngIf="showColumn && !lbandOnly" >
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef style="width: 110px;">
        <span matTooltip="Amount of accepted cloud cover in a scene">Cloud Cover</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index"
        style="text-align: left; padding-right: 5px">
        <span *ngIf="(row.cloudcovers && row?.rushCloudCover) || (row.key === 'STE' && row?.rushCloudCover && row.sensor != 'SAR') || (target.rule === 'rule-1' && row.key === OPERATOR.SATELLOGIC)" style="width: 80px; padding-left: 1px; font-size: 13px;">
          No Guarantee
        </span>
        <mat-select [disableOptionCentering]="true"
          [panelClass]="row.cloudcovers.length > 5 ? 'matOpenCCsixTable' :row.cloudcovers.length > 4 ? 'matOpenCCfiveTable' : row.cloudcovers.length > 3 ? 'matOpenCCfourTable' : row.cloudcovers.length > 2 ? 'matOpenCCthreeTable' :row.cloudcovers.length > 1 ? 'matOpenCCtwoTable' : 'matOpenCConeTable'"
          *ngIf="(row.cloudcovers && !row?.rushCloudCover) && (target.rule != 'rule-1')" style="width: 70px;display: block;"
          (selectionChange)="ccChange($event.value, idx, row)" placeholder="{{row.selectedCC ? row.selectedCC : row.cloudcovers[0].key}}"
          [(ngModel)]="row.cloudValue">
          <mat-select-trigger>{{ row.selectedCC }}</mat-select-trigger>
          <mat-option class="triggers" *ngFor="let item of row.cloudcovers; let i = index" [ngValue]="item.key"
            [selected]="row.cloudcover === item.key" [value]="item.key"
            [disabled]="(currentUser.allocated && row.cloudcover !== item.key) || row.prevent || (this.capellaEnable && row.sensor != 'SAR') || (this.satCapeEnable && row.sensor != 'SAR' && row?.key != OPERATOR.SATELLOGIC && row?.key != OPERATOR.STE && row?.key != OPERATOR.USL && row?.key != OPERATOR.BLACKSKY)
            || (row?.key === OPERATOR.BLACKSKY && i > 0 && row?.selectedLatencyKey?.key === 'priority')
            || ((row?.key === OPERATOR.HEAD || row?.key === OPERATOR.STE || row?.key === OPERATOR.AXELSPACE || row?.key === OPERATOR.WYVERN)
            && i < 4 && row?.selectedLatencyKey?.key != 'standard') 
            || ((row?.key === OPERATOR.KOMPSAT || row?.key === OPERATOR.ISI || row?.key === OPERATOR.GOKTURK)
            && i < 5 && row?.selectedLatencyKey?.key != 'standard')">
            <div class="d-flex justify-content-between">
              <div style=" font-weight: bold">
                <div class="d-flex justify-content-end">
                  {{ item.key }}
                </div>
              </div>
              <div style=" text-align: right;">
                {{ item.value }}x
              </div>
            </div>
          </mat-option>
        </mat-select>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>


    <ng-container matColumnDef="ONA" *ngIf="!lbandOnly">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef>
        <span style="width: 151px;" [matTooltip]="onaTooltip">
          {{nameONA}}</span>
      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row; let idx = index">
        <span style="display: flex;">
        <mat-select [disableOptionCentering]="true"
        [panelClass]="row.ONA.length > 4 ? 'matOpenONAFiveTable' : row.ONA.length > 3 ? 'matOpenONAfourTable' : row.ONA.length > 2 ? 'matOpenONAthreeTable' : row.ONA.length > 1 ? 'matOpenONAtwoTable' : 'matOpenONAoneTable'" *ngIf="row.ONA?.length > 0"
          style="width: 70px" placeholder="{{ row.selectedONA}}" (selectionChange)="ONAOption($event.value, idx, row,1)"
          [(ngModel)]="row.selectedItem"
          [matTooltip]="row?.selectedONA?.length > 7 ? row?.selectedONA : ''">
          <mat-select-trigger [matTooltip]="row?.selectedONA?.length > 5 ? row?.selectedONA : ''">{{ row.selectedONA }}</mat-select-trigger>
          <mat-option class="triggers" *ngFor="let item of row.ONA;  let i = index" [ngValue]="item.key"
            [selected]="row.ONA === item.ona || row.ONA === item.key" [value]="item"
            [disabled]="(currentUser.allocated && row.selectedONA !== item.key) || row.prevent|| (this.capellaEnable && row.sensor != 'SAR') || (this.satCapeEnable && row.sensor != 'SAR' && row?.key != OPERATOR.SATELLOGIC && row?.key != OPERATOR.STE && row?.key != OPERATOR.USL && row?.key != OPERATOR.BLACKSKY) ||
            (row?.key === OPERATOR.UMBRA && item.disabled)">
            <div class="d-flex justify-content-between">
              <div class="standard" *ngIf="item?.category">
                {{ item?.category }}
              </div>
              <div style=" font-weight: bold; font-size: 14px;">
                <div class="d-flex justify-content-end">
                  {{ item.ona ? item.ona :item.key }}
                </div>
              </div>
              <div class="name-value">
                {{ item.onaUplift? item.onaUplift :item.value  }}x
              </div>
            </div>
          </mat-option>
        </mat-select>
        <span *ngIf="isOperatorWithSensor(row)">
          <img style="margin-left: 3px" width="15px" [src]="tooltipImagePath"
            [matTooltip]="getTooltipText(row.key)"
            matTooltipPosition="below" />
      </span>
    </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="advance">
      <th *matHeaderCellDef mat-header-cell>
      </th>

      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span (click)="OpenAdvance(row,i)" *ngIf="((row?.key === OPERATOR.CAPELLA || row?.key === OPERATOR.KOMPSAT || row?.key === OPERATOR.UMBRA || row?.key === OPERATOR.ECURS) && (row?.sensor === 'SAR' || row?.sensor === 'InSAR')) 
        || ((row?.key === OPERATOR.KOMPSAT && row?.sensor === 'MSI'))">
          <img style="margin-top: 3px;cursor: pointer;width: 18px;" [src]="advanceSettingsImage" matTooltip="Advanced Settings" />
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="cost">
      <th mat-header-cell class="table-header-cell" *matHeaderCellDef style="text-align: left" [hidden]="cpcAndAllocated">
        <span matTooltip="The unit economics of the sensor in sqkm, scene or seconds">Unit Cost</span>

      </th>
      <td mat-cell class="cell-Main-Table" *matCellDef="let row" style="text-align: left; padding-right: 5px" [hidden]="cpcAndAllocated">
        <span style="width: 130px;">
          <!-- <span *ngIf="row.checked">{{ currencySign }}{{ row.selectedCost | number }}/{{
            row.unit 
            }}</span>
          <span *ngIf="!row.checked && row.enabled && row?.key !== OPERATOR.CLYDE ">{{ currencySign }}{{ row.selectedCost | number }}/{{
            row.unit 
            }}</span> -->
            <span *ngIf="row.checked">{{ convertCostToPoints(row.selectedCost, true, row) }}/{{
                row.unit 
                }}</span>
            <span *ngIf="!row.checked && row.enabled">{{ convertCostToPoints(row.selectedCost, true, row) }}/{{
                row.unit 
                }}</span>    
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="opportunity">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span *ngIf="row?.feasibility">
          <img class="globe" [src]="orbitImage"
          matTooltip="Opportunity tasking available"/>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="footprint">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span *ngIf="(row?.key === OPERATOR.CAPELLA)" style="text-align: center !important;">
          <mat-icon class="material-icons-outlined map-active" style="cursor: default" 
          matTooltip="Scene footprint available">
            map
          </mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="clientInfo">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span *ngIf="row?.key != OPERATOR.KOMPSAT && row?.key != OPERATOR.STE && row?.key != OPERATOR.USL && row?.key != OPERATOR.ISI && row?.key != OPERATOR.GHG && row?.key != OPERATOR.WYVERN 
        && row?.key != OPERATOR.GOKTURK && row?.key != OPERATOR.LBAND">
          <img class="mask" [src]="maskImage"
          matTooltip="Obfuscated"/>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="eula">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let row; let i = index" style="padding-left: 0px !important; padding-right: 5px">
        <span style="text-align: center !important;" (click)="openEula(target,row)">
          <mat-icon class="material-icons-outlined map-active" style="cursor: pointer" 
          matTooltip="End-user license agreement (EULA)">
          handshake
          </mat-icon>
        </span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" style="width: 100%; border: none; background: #026fc2"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{'highlighted': row.checked && !darkTheme, 'highlighted-dark': row.checked && darkTheme}"></tr>
    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4" style="color: red">
        No Sensors Selected
      </td>
    </tr>
  </table>
</div>
</div>

<div *ngIf="selectedSensors.length > 0" class="text mat-footer-cell-Cur">
    <span style="font-weight: normal; padding-right: 10px">Total Selected Sensor<span *ngIf=" selectedSensors.length > 1">s</span>: </span>{{ selectedSensors.length }}
</div>
<div *ngIf="(target.taskStrategy !== 'Tip-and-Cue' && selectedSensors.length > 0 && target.taskStrategy !== 'AIS Included') || (target.taskStrategy === 'Tip-and-Cue' && selectedSensors.length >= 2) || (selectedSensors.length > 1 && target.taskStrategy === 'AIS Included' && isAISSensor) || (selectedSensors.length > 0 && target.taskStrategy === 'AIS Included' && !isAISSensor)">
  <div class="submitbtn" >
    <button mat-flat-button color="primary" type="submit" (click)="submit(true)" [disabled]="isTipAndCue || enable || demoView || feasible || feasibleAIS()" class="taskSubmit">
    Opportunity tasking
    </button>
  </div>
  <div class="oprtybtn">
    <button mat-flat-button color="primary" type="submit" (click)="submit(false)" [disabled]="tipAndQSelected || enable || demoView" class="submitAuto">
      Automated tasking
    </button>
  </div>
</div>

<mat-menu #tooltipMenu="matMenu"  class="band-menu-style" xPosition="after" yPosition="above"
(closed)="onMenuClosed()">
 <app-custom-tooltip [metadata]="bandInfoTooltip" product="band" [operator]="selectedOperator"></app-custom-tooltip>
</mat-menu>