import { Injectable } from '@angular/core';

var geoParse = require('wellknown');
import * as turf from '@turf/turf';
import { Cartesian3, EllipsoidGeodesic, Ellipsoid, Math as CesiumMath } from 'cesium';
import * as Cesium from 'cesium';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  private operatorEula: { [itemName: string]: string } = {
    'Head': '/assets/files/EULA-HEAD-CG.pdf',
    'Capella': 'https://www.capellaspace.com/customers/product-documentation/data-licensing/',
    'Satellogic': 'https://satellogic.com/license-agreement/',
    '21AT': '/assets/files/EULA-21AT.pdf',
    'Umbra': 'https://umbra.space/terms-of-use/',
    'STE': '/assets/files/STE-EULA.pdf',
    'KOMPSAT': '/assets/files/EULA-KOMSAT.pdf',
    'USL': "USL",
    'ISI' : '/assets/files/EULA-ISI.pdf',
    'AxelSpace' : '/assets/files/EULA-AXEL.pdf',
    'GHG' : '/assets/files/GHG.pdf',
    'Ororatech': '/assets/files/OroraTech.pdf',
    'SatVu': '/assets/files/SATVU-EULA.pdf',
    'Blacksky': '/assets/files/EULA-BlackSky-ING.pdf',
    'BlackSky-Commercial' : '/assets/files/EULA-BlackSky-COMMERCIAL.pdf',
    'Wyvern': '/assets/files/Wyvern-EULA.pdf',
    'Spire': '/assets/files/Spire-EULA.pdf',
    'AxelSpace-Academic': '/assets/files/EULA-Axel-Academic.pdf',
    'AxelSpace-Media': '/assets/files/EULA-Axel-Media.pdf',
    'AxelSpace-Common': '/assets/files/EULA-Axel-Common.pdf',
    'ClydeSpace': '/assets/files/EULA-Clyde.pdf',
    'Gokturk': '/assets/files/Gokturk-EULA.pdf',
    'ECURS': '/assets/files/ECURS-EULA.pdf',
  };
  htmlContent: string;
  aoiData: any;
    
  constructor(private apiService: ApiService) {}

  // Function to check if a given latitude is within the range
  isLatitudeInRange(target: any, minValue: number, maxValue: number): boolean {
    let geoJSON = geoParse(target.geoJSON);
    if (target?.multiTarget?.length > 0) {
      for (const targets of target.multiTarget) {
        const geoJson = geoParse(targets);
        const latitude = geoJson.coordinates[1];
        if (latitude >= minValue && latitude <= maxValue) {
          return true; // If any latitude is within the range, return true
        }
      }
    } else if (geoJSON.type === 'Point') {
      const latitude = geoJSON.coordinates[1];
      return latitude >= minValue && latitude <= maxValue;
    } else if (geoJSON.type === 'Polygon') {
      const coordinates = geoJSON.coordinates[0];
      for (const coordinate of coordinates) {
        const latitude = coordinate[1];
        if (latitude >= minValue && latitude <= maxValue) {
          return true; // If any latitude is within the range, return true
        }
      }
    }
    return false;
  }

  // Function to check if a point is inside a polygon
  pointInPolygon(point, polygon) {
    var x = point[0], y = point[1];
    var inside = false;
    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        var xi = polygon[i][0], yi = polygon[i][1];
        var xj = polygon[j][0], yj = polygon[j][1];
        
        var intersect = ((yi > y) != (yj > y)) &&
            (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    return inside;
  }

  // Function to check if one polygon is inside another
  polygonInsidePolygon(polygon1, polygon2) {
    for (var i = 0; i < polygon1.length; i++) {
        if (!this.pointInPolygon(polygon1[i], polygon2)) {
            return false;
        }
    }
    return true;
  }

  // Function to check if two polygons are the same
  polygonsAreSame(polygon1, polygon2) {
    let tolerance = 1e-6; //maximum allowed difference between corresponding coordinates.
    if (polygon1.length !== polygon2.length) {
        return false;
    }

    for (var i = 0; i < polygon1.length; i++) {
      const dx = Math.abs(polygon1[i][0] - polygon2[i][0]);
      const dy = Math.abs(polygon1[i][1] - polygon2[i][1]);
      
      if (dx > tolerance || dy > tolerance) {
          return false;
      }
  }
    
    return true;
  }

  // Function to check if one polygon is inside another or if they are the same
  comparePolygons(polygon1, polygon2) {
    if (this.polygonInsidePolygon(polygon1, polygon2)) {
        return true;
    } else if (this.polygonsAreSame(polygon1, polygon2)) {
        return true;
    } else {
        return false;
    }
  }

  getPdfUrl(operatorKey: string): string {
    return this.operatorEula[operatorKey] || '';
  }

  getContent(): string {
    return this.htmlContent;
  }

  setContent(newContent: string): void {
    this.htmlContent = newContent;
  }
  
  calculateMinSwathWidth(aoi: any) {
    this.aoiData = geoParse(aoi)    
    const coordinates = this.aoiData.coordinates[0];
    let minDistance = Number.MAX_VALUE;

    // Calculate distances between consecutive points
    for (let i = 0; i < coordinates.length - 1; i++) {
      const coord1 = coordinates[i];
      const coord2 = coordinates[i + 1];
      const distance = this.calculateDistance(coord1[1], coord1[0], coord2[1], coord2[0]); // Reversed lat-long order
      if (distance < minDistance) {
        minDistance = distance;
      }
    }

    // Check if minimum width meets the requirement
    let minSwathWidth = minDistance;
    return minDistance >= 5;
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    const earthRadius = 6371; // Earth's radius in kilometers
    const dLat = this.degreesToRadians(lat2 - lat1);
    const dLon = this.degreesToRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.degreesToRadians(lat1)) * Math.cos(this.degreesToRadians(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadius * c;
    return distance;
  }

  degreesToRadians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  calculateMaxWidth(aoi: any) {
    this.aoiData = geoParse(aoi)
    const coordinates = this.aoiData.coordinates[0];
    let maxDistance = 0;

    // Calculate distances between consecutive points
    for (let i = 0; i < coordinates.length - 1; i++) {
      const coord1 = coordinates[i];
      const coord2 = coordinates[i + 1];
      const distance = this.calculateDistance(coord1[1], coord1[0], coord2[1], coord2[0]); // Reversed lat-long order
      if (distance > maxDistance) {
        maxDistance = distance;
      }
    }

    return maxDistance > 5;
  }

  calculateArea(pointArray: string[]): number {  
    const coordinates = pointArray.map(point => this.parsePoint(point));
    
    // Sort coordinates in counter-clockwise order
    const orderedCoordinates = this.sortPointsCounterClockwise(coordinates);
  
    let totalArea = this.calculateAreaUsingTurf(orderedCoordinates);
    totalArea = totalArea / 1e6;
    return totalArea;
  }
  
  parsePoint(point: string): [number, number] {
    const coords = point.replace('POINT (', '').replace(')', '').split(' ');
    return [parseFloat(coords[0]), parseFloat(coords[1])];
  }
  
  sortPointsCounterClockwise(points: [number, number][]): [number, number][] {
    // Calculate the centroid
    const centroid = turf.centroid(turf.multiPoint(points)).geometry.coordinates;
  
    // Sort points by angle with respect to the centroid
    return points.sort((a, b) => {
      const angleA = Math.atan2(a[1] - centroid[1], a[0] - centroid[0]);
      const angleB = Math.atan2(b[1] - centroid[1], b[0] - centroid[0]);
      return angleA - angleB;
    });
  }
  
  calculateAreaUsingTurf(points: [number, number][]): number {
    const coordinatesWithClosure = [...points, points[0]]; // Close the polygon by adding the first point at the end
    const polygon = turf.polygon([coordinatesWithClosure]);
    return turf.area(polygon);
  }
  
  convertCostToPoints(cost:any,sensorConversionFactor) {
    const parts = sensorConversionFactor.split(':');      
    const conversionFactor =  parseFloat(parts[1]) / parseFloat(parts[0]);
    let points =  Math.round(cost * conversionFactor);
    return points;
}

  // Extracts longitude and latitude from the 'POINT (lng lat)' format
  extractCoordinates(point: string) {
    const coords = point.match(/POINT \(([^ ]+) ([^ ]+)\)/);
    if (coords && coords.length === 3) {
      return {
        lng: parseFloat(coords[1]),
        lat: parseFloat(coords[2])
      };
    }
    throw new Error('Invalid point format');
  }

  getCirclePolygon(center, radius: number, numPoints: number = 64) {
    const positions: any = [];
    const geodesic = new EllipsoidGeodesic();

    // Convert center to Cartographic
    const centerCartographic = Cesium.Cartographic.fromDegrees(center.lng, center.lat);

    for (let i = 0; i < numPoints; i++) {
      const angle = CesiumMath.toRadians((360 / numPoints) * i); // Calculate angle for each point

      // Compute the point on the perimeter of the circle
      const perimeterPointCartographic = Ellipsoid.WGS84.cartesianToCartographic(
        Cartesian3.fromRadians(
          centerCartographic.longitude + radius / Ellipsoid.WGS84.maximumRadius * Math.cos(angle),
          centerCartographic.latitude + radius / Ellipsoid.WGS84.maximumRadius * Math.sin(angle)
        )
      );

      const longitude = CesiumMath.toDegrees(perimeterPointCartographic.longitude);
      const latitude = CesiumMath.toDegrees(perimeterPointCartographic.latitude);
      positions.push(`${longitude} ${latitude}`);
    }

    // Close the polygon by repeating the first point at the end
    positions.push(positions[0]);

    // Format the points into the POLYGON string
    const polygon = `POLYGON ((${positions.join(', ')}))`;
    return polygon;
  }

// Calculate the overlap percentage between two circles
calculateOverlapPercentage(distance: number, radius: number): number {
  const r = radius;
  const d = distance;

  // Handle cases where circles are too far apart or identical
  if (d >= 2 * r) return 0; // No overlap
  if (d === 0) return 100; // Identical circles

  // Area of intersection between two circles formula
  const part1 = r * r * Math.acos((d * d + r * r - r * r) / (2 * d * r));
  const part2 = r * r * Math.acos((d * d + r * r - r * r) / (2 * d * r));
  const part3 = 0.5 * Math.sqrt((-d + r + r) * (d + r - r) * (d - r + r) * (d + r + r));

  const overlapArea = part1 + part2 - part3;
  const circleArea = Math.PI * r * r;

  // Calculate the percentage of overlap relative to one circle's area
  return (overlapArea / circleArea) * 100;
}

checkIsItWater(locations) {
  this.apiService.checkIsWater(locations).subscribe((res: any) => {
    return res;
  });
}

sortPurposeOptions(purposeOption: any[]) {
  purposeOption = purposeOption.sort((a, b) => {
    if (!a.purpose) return 1; // If `a.purpose` is undefined, move it to the end
    if (!b.purpose) return -1; // If `b.purpose` is undefined, move it to the end

    if (a.purpose === 'Others') return 1; // Keep "Others" at the end
    if (b.purpose === 'Others') return -1; // Keep "Others" at the end

    return a.purpose.localeCompare(b.purpose); // Sort alphabetically by purpose
  });
  
  return purposeOption; // return sorted array if needed
}
}
