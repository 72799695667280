<div>
    <div class="btns" style="cursor: pointer;">
        <a fxHide.lt-md (click)="addUser()" class="add">
            <span class="material-icons-outlined"> add </span>New
        </a>
        <a fxHide fxShow.lt-md class="add" (click)="desktopVersion()">
            <span class="material-icons-outlined"> add </span>New
        </a>
    </div>
    <div id="scrollcustom" class="scrollbar">
        <div *ngIf="tableDataLength === 0" class="container no-task">
            <img *ngIf="!darkTheme && !isInIframe" src="../../assets/images/notask.svg">
            <img *ngIf="darkTheme && !isInIframe" src="../../assets/images/whitelogo.png" width="80px">
            <h3>Add your first user by clicking on the New button.</h3>
        </div>
        <mat-card *ngIf="tableDataLength > 0" class="card mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" matSort class="table-class">

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Full Name</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">{{ row?.name }} {{ row?.lastName }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="displayName">
                    <th mat-header-cell *matHeaderCellDef>Display Name</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">{{row?.displayname}}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef>Department</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">
                            {{ row.department }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width" style="width: 185px !important;">
                            {{ row.email }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="edit">
                    <th mat-header-cell *matHeaderCellDef class="align-text">Edit</th>
                    <td mat-cell *matCellDef="let row" class="align-text" style="padding-left: 15px;">
                        <img [src]="darkTheme ? '../../../assets/images/edit-dark.svg' : '../../../assets/images/edit.svg'"
                            style="cursor: pointer;width: 15px;" (click)="updateUser(row)" />
                    </td>
                </ng-container>


                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef class="align-text">Delete</th>
                    <td mat-cell *matCellDef="let row" class="align-text" style="padding-left: 17px;">
                        <img [src]="deletedImagePath" alt="" style="cursor: pointer;"
                        (click)="deleteUser(row)">
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
    </div>
</div>