<mat-accordion [formGroup]="formGroup">
  <mat-expansion-panel [expanded]="isFirst">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Personal Details
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="request">
      <div class="set">
        <mat-label>Name<span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input type="text" matInput placeholder="Your name" formControlName="nameField" (keydown)="Space($event)" />
          <mat-error *ngIf="nameField.invalid">{{
            getErrorMessage(nameField)
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="set">
        <mat-label>Email <span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Your email" formControlName="emailField" (keydown)="Space($event)" />
          <mat-error *ngIf="emailField.invalid">{{
            getErrorMessage(emailField)
            }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-label>Organisation Name <span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Your organisation name" formControlName="companyField" />
          <mat-error *ngIf="companyField.invalid">{{
            getErrorMessage(companyField)
            }}</mat-error>
        </mat-form-field>
      </div>
      <div class="phonenumber">
        <mat-label>Phone Number</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="number" placeholder="Your phone number" formControlName="phoneField" />
          <mat-error *ngIf="phoneField.invalid">{{ getErrorMessage(phoneField) }}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Inquiries
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="request" *ngIf="!nameField.invalid && !emailField.invalid && !companyField.invalid">
      <div class="set">
        <mat-label>Select an option</mat-label>
        <mat-form-field appearance="outline">
          <mat-select [disableOptionCentering]="true" panelClass="matopeninquiries" [(value)]="selected"
            placeholder="Options" (selectionChange)="subjectSelection($event.value)" formControlName="buildingType">
            <mat-option value="Feedback">Feedback</mat-option>
            <mat-option value="Requirement">Requirement</mat-option>
            <mat-option value="Suggestions">Suggestion</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="set textarea-box">
        <mat-label>Message</mat-label>
        <mat-form-field class="example-full-width" appearance="outline">
          <textarea matInput placeholder="Your message" rows="2" cols="20" formControlName="contentField"></textarea>
          <mat-error *ngIf="contentField.invalid">{{
            getErrorMessage(contentField)
            }}</mat-error>
        </mat-form-field>
        <div class="character">
          <div>
            <span class="light-text">Max 500 characters</span>
          </div>
          <div>
            <span class="light-text">{{contentField.value?.length}}/500</span>
          </div>
        </div>
      </div>
      <div class="btns">
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit()">Submit</button>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>