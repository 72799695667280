import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { RegistersuccessComponent } from '../userregister/registersuccess/registersuccess.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';
import { ThemeService } from 'src/app/services/theme.service';
import { WarningPopupComponent } from '../collections/warning-popup/warning-popup.component';
import { PopupService } from 'src/app/services/popup.service';
import { PAYPAL_CHARGE, STRIPE_CHARGE } from 'src/app/services/constants';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  amount: any = 0;
  currentUser: any;
  error: boolean = false;
  amountAdded: boolean = true;
  methodSelected: boolean = true;
  selectedPaymentOption: string;
  rechargeAmount: number = 0;
  subscriptionForm: any = FormGroup;
  isInIframe: any;
  subscriptionAmount = 5000;
  onlineFees: number;
  isData: boolean = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private dataService: DataService,
    private apiService: ApiService,
    private themeService: ThemeService,
    private popupService: PopupService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.authService.getCurrentUser();
    const castUser = this.authService.castCurrentUser.subscribe(
      async (res: any) => {
        this.currentUser = await res;
        castUser.unsubscribe();
        if (this.currentUser?.id) {
          this.userService.personalInfo(this.currentUser.id).subscribe({
            next: (res: any) => {
              this.currentUser = res;
              this.isData = true;
              if (this.currentUser?.subscribe) {
                this.router.navigate(['/task']);
              }},
            error: (error) => {
            }
          });     
        }
    }
    );

    this.dataService.clearData();

    this.subscriptionForm = this.formBuilder.group({
      subscriptionFee: [{ value: this.subscriptionAmount, disabled: true }],
      topUpAmount: ['', Validators.pattern(/^\d+(\.\d{1,2})?$/)],
      currency: [{ value: 'USD', disabled: true }],
    });

    this.isInIframe = this.themeService.isInIframe();
  }

  onNoClick() {
    this.subscriptionForm.get('topUpAmount').reset();
    this.selectedPaymentOption = '';
    this.methodSelected = true;
  }

  deposit(): void {
    let data: any, top_up: any, subscription: any;
    this.dataService.clearData();
    this.methodSelected = true;    
    top_up  = this.rechargeAmount;
    subscription = this.subscriptionAmount;
    this.amount = this.subscriptionAmount + this.rechargeAmount;
 
    let storageObj = {
      amount: this.amount,
      currentUser: this.currentUser,
      selectedPaymentOption: this.selectedPaymentOption,
      top_up: top_up,
      subscription: subscription
    }
    this.dataService.setPayment(storageObj);

    if (this.selectedPaymentOption != 'Bank Transfer') {
      this.authService
        .addMoneyToWallet(
          this.amount,
          this.currentUser,
          this.selectedPaymentOption,
          top_up,
          subscription,
          top_up
        )
        .subscribe((response) => {
          data = response;
          if (data?.['paypalUrl']) {
            window.location.href = data?.['paypalUrl'];
          } else if (data?.['stripeUrl']) {
            window.open(data.stripeUrl, '_self');
          }
          localStorage.setItem('paymentMethod', this.selectedPaymentOption);
          localStorage.setItem('keyType', 'subscription');
          localStorage.setItem('stripeId', data?.stripeId);
          this.amount = 0;
        });
    } else {
      const dialogRef = this.dialog.open(RegistersuccessComponent, {
        data: { user: this.currentUser, type: 'bank'},
        backdropClass: 'blurred',
        disableClose: true,
        width: '60%',
      });

      dialogRef.afterClosed().subscribe(async (result) => {
        this.methodSelected = true;
        if (result === 'yes') {
          this.authService
          .addMoneyToWallet(
            this.amount,
            this.currentUser,
            this.selectedPaymentOption,
            top_up,
            subscription,
            top_up
          )
          .subscribe((response) => {
            this.router.navigate(['/deposits']);
            // if (top_up > 0 && !this.currentUser?.pointsEligible) {
            //   localStorage.setItem('keyType', 'subscription');
            //   this.router.navigate(['/success']);
            // } else this.router.navigate(['/deposits']);
          });
        } else {
          this.selectedPaymentOption = '';
        }
      });
    }
  }

  select() {
    this.methodSelected = false;
    if (this.selectedPaymentOption === 'PayPal') this.onlineFees = PAYPAL_CHARGE;
    else if (this.selectedPaymentOption === 'Stripe') this.onlineFees = STRIPE_CHARGE;

    let additionalCharge = ((this.subscriptionAmount * (this.onlineFees / 100)) + 
                           (this.rechargeAmount * (this.onlineFees / 100)));

    if (this.selectedPaymentOption != 'Bank Transfer') {
      const additionalChargeFormatted = `<span style="color: #1059a9; font-weight: 500">$${additionalCharge.toFixed(2)}</span>`;
      const message = `The ${this.selectedPaymentOption} charge for this transaction is ${additionalChargeFormatted}.`;
      const type = 'pleaseNote'
      this.popupService.openPopup(WarningPopupComponent, message, type, '40%');      
    }
  }

  somethingChanged(event: any): void {
    this.selectedPaymentOption = '';
    this.methodSelected = true;
    this.amount = event.target.value;
    this.amountAdded = true;
    const pattern = /^(\d+(\.\d{0,2})?)$/;
    if (this.amount) {
      if (!pattern.test(this.amount)) {
        this.error = true;
      } else this.error = false;
    } else {
      this.amountAdded = true;
      this.error = false;
    }

    if (this.amount > 0) {
      this.amountAdded = false;
      this.rechargeAmount = parseFloat(
        JSON.parse(this.amount.replace(/^0+/, ''))
      );
    } else {
      this.amountAdded = true;
      this.rechargeAmount = 0;
    }
  }
}
