<div class="align">
    <div>
        <h4 class="task-head">Sample</h4>
        <div class="preview-details" fxLayout="row">
            <span fxFlex="33.33" style="color: gray; text-align: start;" class="light-text">Sensor Name: <span class="preview-content"> {{data.sensor}}</span></span>
            <span fxFlex="33.33" style="color: gray; text-align: center;" class="light-text">
            <span *ngIf="data?.mode" style="color: gray"  class="light-text">Mode:
                <span class="preview-content">
                <ng-container *ngFor="let detail of data?.mode?.split(','); let last = last">
                {{ detail.trim() }}
                <ng-container *ngIf="!last">,</ng-container>
                <br *ngIf="!last">
              </ng-container>
            </span>
            </span>
            </span>
            <span *ngIf="data.resolution" fxFlex="33.33" style="color: gray; text-align: end;"  class="light-text">Resolution: <span class="preview-content"> <span *ngIf="data.resolution" class="dark-text"> ({{
                data.resolution
                }}) </span></span></span>
        </div>
    </div>
     <div *ngIf="!video" style="text-align: center;" id="scrollableDiv" class="scrollable-div">
        <img src="../../../assets/sensor-images/{{this.sensorName}}.png" class="img-size"  (error)="handleImageError()" *ngIf="this.sensorImage"/>
        <div class="message" *ngIf="!this.sensorImage">No preview</div>
    </div>
    <div *ngIf="video" style="text-align: center;" id="scrollableDiv" class="scrollable-div">
        <!-- Check if the videoLink is an MP4 -->
        <video *ngIf="isMp4(videoLink)" class="video" controls>
          <source [src]="videoLink" type="video/mp4">
        </video>
      
        <!-- Check if the videoLink is a GIF -->
        <img *ngIf="isGif(videoLink)" [src]="videoLink" alt="GIF Preview" width="100%"/>
      </div>
    <div style="margin-top: 12px;">
        <button mat-button class="btn" (keydown)="onKeyPress($event)" (click)="onNoClick()">
            Close
        </button>
    </div>
</div>