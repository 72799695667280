<div class="update-features">
  <div class="updates">
    What's New at <span style="padding-left: 4px;"
    [ngStyle]="{'color': darkTheme ? '#3f9aff' : '#1059A9'}">Eartheye</span>
  </div>
  <div class="change-log-description">
    Latest platform features and sensors
  </div>
</div>

<div #timelineContainer id="scrollcustom" style="padding-top: 25px;height: 76%; overflow-y: auto;"
  class="container-alignment">
  <div class="timeline-container">
    <div *ngFor="let feature of filteredFeatures; let i = index" class="update-item">
      <div class="change-log-date-list">
        <span class="date-display">{{feature.date | date: 'MMMM d, yyyy'}}</span>
        <div class="timeline">
          <div class="circle" [class.active]="i <= activeIndex"></div>
          <div *ngIf="i !== filteredFeatures.length - 1" class="line"></div>
        </div>
      </div>
      <div class="change-log-details" id="{{ feature.id }}">
        <div class="change-log-container" *ngIf="feature.category != 'sensors'">
          <div class="change-log-title">{{ feature.title }}</div>
          <p class="change-log-description">{{ feature.description }}</p>
          <!-- Read more arrow and content -->
          <div class="more-arrow" (click)="toggleExpand(i)">
            <div class="btns">
              <button mat-flat-button color="primary" class="align hover-button" *ngIf="!feature.expanded">
                <span>More</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <button mat-flat-button color="primary" class="align hover-button" *ngIf="feature.expanded">
                <span>Less</span>
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
            </div>
          </div>

          <!-- Expanded content -->
          <div *ngIf="feature.expanded" class="expanded-content" @expandCollapse>
            <div class="change-log-description" style="line-height: 2;">
              <p><span class="log-description">What: </span> {{ feature.what }}</p>
              <p><span class="log-description">Why: </span> {{ feature.why }}</p>
              <p><span class="log-description">How: </span> {{ feature.how }}</p>
            </div>
          </div>
          <div class="imageSection">
            <div *ngFor="let item of feature.imageLinks; let i = index">
              <img alt="" [src]="item.link" width="75%" [routerLink]="item.route"
              style="cursor: pointer;">
            </div>
          </div>
        </div>

        <div class="change-log-container" *ngIf="feature.category === 'sensors'">
          <div class="change-log-title">{{ feature.title }}</div>
          <p class="change-log-description">{{ feature.description }}</p>

          <div class="change-log-description" style="line-height: 2;" *ngFor="let sensor of feature.sensorDetails; let idx = index">
            <p><strong>Sensor {{ idx + 1 }}</strong></p>

               <!-- Read more arrow and content -->
          <div class="more-arrow" (click)="toggleSensor(i,idx)">
            <div class="btns">
              <button mat-flat-button color="primary" class="align hover-button" *ngIf="!sensor.expanded">
                <span>More</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
              <button mat-flat-button color="primary" class="align hover-button" *ngIf="sensor.expanded">
                <span>Less</span>
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="sensor.expanded" style="line-height: 2;" class="expanded-content" @expandCollapse>
            <p><span class="log-description">Sensor Type: </span> {{ sensor.sensorType }}</p>
            <p><span class="log-description">Resolution: </span> {{ sensor.resolution }}</p>
            <p *ngIf="sensor.numberOfBands"><span class="log-description">Number of Bands: </span> {{ sensor.numberOfBands }}</p>
            <p *ngIf="sensor.availableBands"><span class="log-description">Available Bands: </span> {{ sensor.availableBands.join(', ') }}</p>
            <p *ngIf="sensor.numberOfModes"><span class="log-description">Number of Modes: </span> {{ sensor.numberOfModes }}</p>
            <p *ngIf="sensor.modes"><span class="log-description">Modes: </span> {{ sensor.modes.join(', ') }}</p>
            <p *ngIf="sensor.frequency"><span class="log-description">Frequency: </span> {{ sensor.frequency }}</p>
            <p *ngIf="sensor.polarisation"><span class="log-description">Polarisation: </span> {{ sensor.polarisation }}</p>
            <p *ngIf="sensor.taskingTiers"><span class="log-description">Tasking Tiers: </span> {{ sensor.taskingTiers.length }} ({{ sensor.taskingTiers.join(', ') }})</p>
          </div>           
            <div class="imageSection">
              <img alt="" [src]="sensor.screenshot" width="75%" [routerLink]="sensor.route"
              style="cursor: pointer;">
            </div>
            <br> 
          </div>
        </div>
        <div></div>
      </div>
    </div>

    <div *ngIf="filteredFeatures?.length === 0">
      <div class="container no-task">
        <img *ngIf="!darkTheme" src="../../assets/images/notask.svg">
        <img *ngIf="darkTheme" src="../../assets/images/whitelogo.png" width="80px">
        <h3>No recent updates</h3>
    </div>
    
    </div>
  </div>

  <div style="padding-right: 35px;
    padding-top: 19px;">
    <div class="category-list">
      <ul>
        <li *ngFor="let category of categories; let i = index" [ngClass]="{ 'active': i === selectedIndex }"
          (click)="selectCategory(i,category)">
          {{ category.featureName }}
        </li>
      </ul>
    </div>
  </div>
</div>