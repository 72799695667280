<div class="container" *ngIf="data.type === 'return'">
    <div class="card">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <div class="icon">
            <p class="para">
                Please enter the reason for refund request.
            </p>
        </div>
    </div>
    <div class="popup-container">
        <form class="form-vertical" fxLayout="column" fxLayoutGap="10px" [formGroup]="formGroup">
            <div class="formDetails" style="margin: 0 0 20px 0; width: 100%;">
                <div fxLayout="row" fxLayoutGap="11px">
                    <mat-form-field appearance="outline">
                        <textarea matInput class="txt-full-width custom-vertical-scrollbar" placeholder="Your message" rows="5" cols="20"
                            formControlName="contentField"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="11px" class="character">
                    <div>
                        <span class="light-text">Max 500 characters </span>
                    </div>
                    <div>
                        <span class="light-text">{{contentField.value?.length}}/500</span>
                    </div>
                </div>
                <div fxLayout="row">
                    <mat-error *ngIf="contentField.invalid && contentField.touched">{{
                        getErrorMessage(contentField)
                        }}</mat-error>
                </div>
            </div>
        </form>
    </div>
    <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onCancel()">Cancel</button>
        <button mat-stroked-button class="submit" [disabled]="formGroup.invalid || disabled || demoView" (click)="onSubmit()">Submit</button>
    </div>
</div>

<div class="container" *ngIf="data.type === 'restore'">
    <div class="card">
        <div class="task-head">
            <h4>Required!</h4>
        </div>
        <div class="icon">
            <p class="para">
                Please enter the reason for restore request.
            </p>
        </div>
    </div>
    <div class="popup-container">
        <form class="form-vertical" fxLayout="column" fxLayoutGap="10px" [formGroup]="archiveFormGroup">
            <div class="formDetails" style="margin: 0 0 20px 0; width: 100%;">
                <div fxLayout="row" fxLayoutGap="11px">
                    <mat-form-field  appearance="outline">
                        <textarea matInput class="txt-full-width" placeholder="Your message" rows="5" cols="20"
                            formControlName="restoreReason"></textarea>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="11px" class="character">
                    <div>
                        <span>Max 250 characters </span>
                    </div>
                    <div>
                        <span>{{restoreReason.value?.length}}/250</span>
                    </div>
                </div>

                <div fxLayout="row">
                    <mat-error *ngIf="restoreReason.invalid && restoreReason.touched" class="error-class">{{
                        getErrorMessage(restoreReason)
                        }}</mat-error>
                </div>

                <div fxLayoutGap="11px" class="storage" style="text-align: center;">
                    <mat-radio-group formControlName="restoreOption">
                        <mat-radio-button value="view" (click)="onChange('view')" style="padding-right: 25px;">Restore for
                            view</mat-radio-button>
                        <mat-radio-button value="both" (click)="onChange('both')">Restore for view and download</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </form>
    </div>
    <div class="btns" fxLayoutAlign="center center" fxLyout="row">
        <button class="custom-button" (click)="onCancel()">Cancel</button>
        <button mat-stroked-button class="submit" [disabled]="archiveFormGroup.invalid || storage || demoView"  (click)="onSave()">Submit</button>
    </div>
</div>