<div>
    <div class="btns">
        <a fxHide.lt-md routerLink="/newbudget" class="add">
            <span class="material-icons-outlined"> add </span>New
        </a>
        <a fxHide fxShow.lt-md class="add" (click)="desktopVersion()">
            <span class="material-icons-outlined"> add </span>New
        </a>
    </div>
    <div id="scrollcustom" class="scrollbar">
        <div *ngIf="filteredData?.length === 0" class="container no-task">
            <img *ngIf="!darkTheme && !isInIframe" src="../../assets/images/notask.svg">
            <img *ngIf="darkTheme && !isInIframe" src="../../assets/images/whitelogo.png" width="80px">
            <h3>You have no budgets</h3>
        </div>
        <mat-card *ngIf="tableDataLength > 0" class="card mat-elevation-z0">
            <table mat-table [dataSource]="dataSource" matSort class="table-class">

                <ng-container matColumnDef="budgetName">
                    <th mat-header-cell *matHeaderCellDef>Budget Name</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">{{row?.budgetName}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef>Submission Date (UTC)
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">{{(row?.createdAt) | date : "dd/MM/yyyy - hh.mm a" : "GMT"}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef>Start Date (UTC)</th>
                    <td mat-cell *matCellDef="let row">
                        <span class="cell-width">{{row?.openWindow | date : "dd/MM/yyyy - hh.mm a" : "GMT"}}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef>End Date (UTC)</th>
                    <td mat-cell *matCellDef="let row"> <span class="cell-width">{{row?.closeWindow | date : "dd/MM/yyyy - hh.mm a" : "GMT"}}
                    </span></td>
                </ng-container>
                

                <ng-container matColumnDef="taskcost">
                    <th mat-header-cell *matHeaderCellDef style="padding-left: 9px !important;">Cost</th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.isBudgetEqual && !pointUser" style="display: block;"
                        [ngStyle]="{'width': pointUser ? '200px' : '150px'}">
                        $ {{ row.budget[0]?.minRange | customNumberFormat }}
                        </span>
                        <span *ngIf="!row.isBudgetEqual && !pointUser" style="display: block;"
                        [ngStyle]="{'width': pointUser ? '200px' : '150px'}">
                            $ {{ row.budget[0].minRange | customNumberFormat }} - $ {{ row.budget[0].maxRange | customNumberFormat }}
                        </span>

                        <span *ngIf="row.isBudgetEqual && pointUser" style="display: block;"
                        [ngStyle]="{'width': pointUser ? '200px' : '150px'}">
                            {{ row.budgetPoints[0]?.minPointRange | customNumberFormat }} points
                        </span>
                        <span *ngIf="!row.isBudgetEqual && pointUser" style="display: block;"
                        [ngStyle]="{'width': pointUser ? '200px' : '150px'}">
                            {{ row.budgetPoints[0].minPointRange | customNumberFormat }} points - {{ row.budgetPoints[0].maxPointRange | customNumberFormat }} points
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="frequency">
                    <th mat-header-cell *matHeaderCellDef>Frequency</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 130px; display: block;">
                        <span *ngIf="row.frequency === 0;">Specific Date</span>
                        <span *ngIf="row.frequency === 1;">Recurrent</span>
                        <span *ngIf="row.frequency === 2;">Period</span>
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="sensorType">
                    <th mat-header-cell *matHeaderCellDef>Sensor Type</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 100px; display: block;">
                            {{ row.sensorType }}
                        </span></td>
                </ng-container>
                <ng-container matColumnDef="resolution">
                    <th mat-header-cell *matHeaderCellDef>Resolution</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 150px; display: block;">
                            {{ row.resolution?.name }}
                        </span></td>
                </ng-container>
                <ng-container matColumnDef="taskingTier">
                    <th mat-header-cell *matHeaderCellDef>Tasking Tier</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 100px; display: block;">
                            {{capitalizeFirstLetter(row?.taskingTier?.name)  }}
                        </span></td>
                </ng-container>
                <ng-container matColumnDef="cloudCover">
                    <th mat-header-cell *matHeaderCellDef>Cloud Cover</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 100px; display: block;">
                            {{ row.cloudCover}}
                        </span></td>
                </ng-container>
                <ng-container matColumnDef="holdBack">
                    <th mat-header-cell *matHeaderCellDef>Hold back</th>
                    <td mat-cell *matCellDef="let row">
                        <span style="width: 100px; display: block;">
                            {{ row.holdBack}}
                        </span></td>
                </ng-container>
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let row">
                        <span>
                            <button (click)="handleButtonClick($event)" [disabled]="row.isTasked || isDisabled(row) || taskCliked" mat-icon-button [matMenuTriggerFor]="menu"
                              aria-label="Toggle menu"  [matTooltip]="row.isTasked ? 'Task already created' : isDisabled(row) ? 'Time of interest (TOI) has already commenced or ended.' : ''" 
                              >
                              <mat-icon><span class="material-icons"
                                [ngClass]="(!row.isTasked && !isDisabled(row) && !taskCliked)  ? 'menuIcon' : 'more-button'">
                                  more_vert
                                </span></mat-icon>
                            </button>
                          </span>
                          <mat-menu class="action-card" #menu="matMenu" xPosition="before">
                            <div class="button-align">
                              <button class="btn btn-gray retask-button"  (click)="getRetask(row)" [disabled]="row.isTasked || isDisabled(row) || taskCliked"
                              style="border: none;" [class.disabledButton]="row.isTasked || isDisabled(row) || taskCliked">
                                Task
                              </button>
                            </div>
                          </mat-menu>
                    </td>
                </ng-container>
             
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
    </div>
</div>