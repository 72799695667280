<div class="container">
    <div class="card">
        <div class="task-head">
            <h4 *ngIf="!data.satellogic">Warning!</h4>
            <h4 *ngIf="data.satellogic">Confirmation Request</h4>
        </div>

        <p *ngIf="!data.satellogic">You do not have a credit term approved. Please use the Contact Us form to request
        </p>

        <p *ngIf="data.satellogic">Would you accept partial delivery of your order? You will only be charged for the
            collected imagery within your time of interest (TOI).</p>

        <div class="btns" *ngIf="!data.satellogic">
            <button mat-flat-button (click)="onNoClick('no')">Ok, Thanks</button>
        </div>
        <div class="btns" *ngIf="data.satellogic">
            <button class="custom-button" (click)="onNoClick('Close')">Close</button>
            <button class="custom-button" (click)="onNoClick('no')"> No</button>
            <button mat-flat-button (click)="onNoClick('yes')">Yes</button>
        </div>
    </div>
</div>