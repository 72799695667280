<div class="container">
  <div class="card">
    <div class="task-head">
      <h4>Preview</h4>
    </div>
    <div class="preview-details">
      <div>
        <span style="color: gray" class="light-text">Task Name:</span><span class="preview-content"> {{ taskname }}</span>
      </div>
      <div>
        <span style="color: gray" class="light-text">Task ID:</span><span class="preview-content"> {{ taskid }}</span>
      </div>
    </div>

    <div style="min-width: 200px; position: relative">
      <div id="cesiumContainer" class="cesiumContainer" style="height: 400px"></div>
      <div style="
          position: relative;
          background: black;
          color: white;
          z-index: 1;
          top: -35px;
          padding: 3px 8px;
        ">
        <label style="padding-bottom: 5px">AREA OF INTEREST</label>
        <div style="font-size: small">
          <p style="margin-bottom: 5px;">
            <label style="color: lightgray">Geometry:</label>
            {{ taskTarget.type }}
          </p>
          <p>
            <label style="color: lightgray">Coordinates (Lng, Lat):</label><span>
              [{{ coordinates | json | slice: 1:35 }}...]
              <span [matTooltipClass]="'target-tooltip'" [matTooltip]="coordinates | json"
              matTooltipPosition="left">
              <img style="
                 margin: 3px;
    height: 20px;
    position: absolute;
    top: 0;
    right: -32px;
    width: 20px;
                " src="../../../assets/images/mark.png"/></span>
                </span>

          </p>
        </div>
      </div>
      
      <div class="d-flex justify-content-center" style="margin-top: -15px">
        <button mat-button class="btn" (keydown)="onKeyPress($event, 'NO')" (click)="onNoClick('NO')">
          Close
        </button>
      </div>
    </div>
  </div>
</div>