<mat-accordion [formGroup]="formGroup">
  <mat-expansion-panel [expanded]="isFirst">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Personal Details
      </mat-panel-title>

    </mat-expansion-panel-header>
    <div class="request">
      <div class="set">
        <mat-label>Name<span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Your name" formControlName="name" />
          <mat-error *ngIf="fields.name.errors">
            <mat-error *ngIf="fields.name.errors.required"> Cannot be empty</mat-error>
            <mat-error *ngIf="fields.name.hasError('minlength')"> Must be at least 3 characters</mat-error>
            <mat-error *ngIf="fields.name.hasError('maxlength')"> Must be no more than 50 characters</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="set">
        <mat-label>Email <span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Your email" formControlName="email" />
          <mat-error *ngIf="fields.email.errors">
            <mat-error *ngIf="fields.email.errors.required">Cannot be empty</mat-error>
            <mat-error *ngIf="fields.email.errors.pattern"> Please enter a valid email</mat-error>
          </mat-error>
        </mat-form-field>


      </div>

      <div>
        <mat-label>Organisation Name<span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput placeholder="Your organisation name" formControlName="company" />
          <mat-error *ngIf="fields.company.errors">
            <mat-error *ngIf="fields.company.errors.required">Cannot be empty</mat-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="phonenumber">
        <mat-label>Phone Number</mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="number" placeholder="Your phone number" formControlName="phone" />
          <mat-error *ngIf="fields.phone.errors">
            <mat-error *ngIf="fields.phone.hasError('pattern')"> Must be a valid phone number</mat-error>
            <mat-error *ngIf="fields.phone.hasError('maxlength')"> Must be no more than 10 characters</mat-error>
            <!-- <mat-error *ngIf="fields.phone.hasError('minlength')"> Must be a valid phone number</mat-error> -->
          </mat-error>
        </mat-form-field>
      </div>

    </div>
  </mat-expansion-panel>


  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Demo Preference
      </mat-panel-title>

    </mat-expansion-panel-header>
    <div class="request" *ngIf="!fields.name.errors && !fields.email.errors && !fields.company.errors">
      <div class="set">
        <mat-label>Desired Date and Time<span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [min]="todayDate"
            formControlName="requested_at">
          <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="false" [stepHour]="1" [stepMinute]="1"
            [stepSecond]="1" [touchUi]="false" [color]="color" [enableMeridian]="true" [disableMinute]="false"
            [hideTime]="false">
          </ngx-mat-datetime-picker>
          <mat-error *ngIf="fields.requested_at.errors">
            <mat-error *ngIf="fields.requested_at.errors.required">Desired date cannot be empty</mat-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="set textarea-box">
        <mat-label>Message</mat-label>
        <mat-form-field class="example-full-width" appearance="outline">

          <textarea matInput placeholder="Your message" rows="2" cols="20" formControlName="messages"></textarea>
          <mat-error *ngIf="fields.messages.errors">
            <mat-error *ngIf="fields.messages.errors.required">Message cannot be empty</mat-error>
            <mat-error *ngIf="fields.messages.hasError('maxlength')"> Must be no more than 500 characters</mat-error>
          </mat-error>
        </mat-form-field>
        <div class="character">
          <div>
            <span class="light-text">Max 500 characters</span>
          </div>
          <div>
            <span class="light-text">{{fields.messages.value?.length}}/500</span>
          </div>
        </div>
      </div>
      <div class="btns">
        <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="onSubmit()">Submit</button>
      </div>
    </div>

  </mat-expansion-panel>
</mat-accordion>