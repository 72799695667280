<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

<div *ngIf="verifyUser" class="container hero-wrap">
  <img *ngIf="!isInIframe" src="../../../assets/images/logo.png" alt="logo" style="width: 36%" />
  <h4>Discover Earth with Us</h4>
  <p>Welcome to Eartheye Space Tasking and Information Feeds</p>

  <!------------------------------ Form-section-Start -------------------------------------------------->

  <form class="form-vertical" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <!-------------------------------- email ---------------------------------------------------->



    <div class="login-container">
      <div class="form-group">
        <mat-label>Email<span>*</span></mat-label>
        <mat-form-field appearance="outline">

          <input matInput autocomplete="off" type="text" placeholder="Your email" formControlName="email"
            [ngClass]="{ 'is-invalid': f.email.errors }" id="inputEmail4" (keyup)="keyPress($event)" />

          <mat-error *ngIf="f.email.touched && f.email.errors" class="alert-danger">
            <mat-error *ngIf="f.email.errors.required">Please enter a valid email</mat-error>
            <mat-error *ngIf="f.email.errors.pattern"> Please use an official email address</mat-error>
          </mat-error>
          <div *ngIf="!submitted && warnEmail && !(f.email.errors)">
            <div class="warn">{{warnMsgEmail}}</div>
          </div>
          <mat-icon *ngIf="f.email.touched && !f.email.errors" matSuffix><img
              src="../../../assets/images/done.svg"></mat-icon>
        </mat-form-field>
      </div>

      <div class="form-group password-group">
        <mat-label>Password<span>*</span></mat-label>
        <mat-form-field appearance="outline">
          <input matInput type="password" [type]="showPassword ? 'password' : 'text'" id="floatingPassword"
            placeholder="Your password" formControlName="password" [ngClass]="{ 'is-invalid': f.password.errors }"
            id="inputPassword4" (keyup)="keyPressPassword($event)" />
          <mat-icon matSuffix (click)="togglePasswordVisibility()">
            {{ showPassword ? "visibility_off" : "visibility" }}
          </mat-icon>
          <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
          <div *ngIf="!submitted && warnMsg && !(f.password.errors)">
            <div class="warn">{{warnMsg}}</div>
          </div>
        </mat-form-field>
      </div>

    </div>
    <!-------------------------------- remember-me forgot-password---------------------------------------------------->
    <div class="container-row">
      <div class="container-row-first">
        <mat-checkbox class="example-margin" [(ngModel)]="rememberMe" (change)="setremember()"
          [checked]="check">Remember Email</mat-checkbox>
      </div>
      <div class="container-row-second">
        <a href="#" class="forgot" routerLink="/forgot">Forgot password?</a>
      </div>
    </div>
    <!-------------------------------- remember-me forgot-password---------------------------------------------------->

    <!-------------------------------- button---------------------------------------------------->

    <div>
      <button mat-raised-button color="primary" type="submit" [disabled]="registerForm.invalid || this.clicked"
        id="button">SIGN IN</button>
    </div>
    <div *ngIf="verify" style="font-size: 14px;color: red;">{{verifyMsg}}</div>
    <div *ngIf="verifyEmail" style="font-size: 14px;color: red;">{{verifyMsg}}<a [href]="'mailto:' + supportEmail">{{supportEmail}}</a></div>
    <div>
    </div>
    <div class="regist">
      <span>Register as new user.
        <a href="#" routerLink="/register">Sign Up</a></span>
    </div>
    <!-------------------------------- button---------------------------------------------------->
  </form>
  <!------------------------------ Form-section-end -------------------------------------------------->
</div>


<div *ngIf="!verifyUser" class="cardContainer">
  <img *ngIf="!isInIframe" src="../../../assets/images/logo.png" alt="logo" style="width: 40%;" />
  <h4>Verification</h4>
  <p *ngIf="!firstLogin" style="font-weight: normal; color: rgb(102, 102, 102);font-size: 1em;">
    Enter the verification code from your email to verify this account.
  </p>
  <p *ngIf="firstLogin" style="font-weight: normal; color: rgb(102, 102, 102);font-size: 1em;">
    Enter the verification code from your email to sign-on to this account.
  </p>
  <form class="form-vertical2">
    <div>
      <label style="font-weight: 600; padding: 0 0 10px 0;">Verification Code</label>

      <div class="otp-validation-form">
        <ngx-otp-input [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)">
        </ngx-otp-input>
        <div *ngIf="otpMsg" style="font-size: 14px;color: red; text-align: left !important;">{{otpMsg}}
        </div>
      </div>
      <button mat-raised-button color="primary" (click)="onNext()" [disabled]="!otpVerification">NEXT</button>
    </div>
    <!-- <div class="otp">
      {{display}}

    </div> -->

  </form>
</div>